const axios = require('axios');

export async function addNewClient(formData, token) {
  console.log(`addNewClient: ${formData}`);
  console.log(`addNewClient: ${token}`);
  const request = {
    url: '/api/clients',
    method: 'post',
    data: formData,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  console.log(JSON.stringify(request, null, 2));

  const clientAdded = await axios(request);
  return clientAdded.data.status === 'OK';
}
