/**
 * C L I E N T   D I C T I O N A R I E S
 * Functions for creating useful client dictionaries.
 */

/**
 * Create dictionary for the following:
 * ClientCode -> Client row object
 * 
 * @param {[object]} clients Rows from the Project Revenus spreadsheet's Clients sheet
 * @returns Dictionary object
 */
export function getClientCodeToClientRow(clients) {
  return clients.reduce((acc, client) => {
    const clientCode = client['ClientCode'];

    if (clientCode === '') {
      return acc;
    } else {
      return { ...acc, [clientCode]: client };
    }
  }, {});
}

/**
 * Create dictionary for the following:
 * ClientCode -> ProjectId
 * 
 * @param {[object]} projects Rows from the Project Revenue spreadsheet's Projects sheet
 * @param {boolean} onlyActiveProjects Whether only active projects should be included
 * @returns Dictionary object
 */
export function getClientCodeToProjectId(projects, onlyActiveProjects = false) {
  return projects.reduce((acc, project) => {
    const projectId = project['ProjectId'];
    const projectStatus = project['Status'];

    // If only active projects should be included
    if (onlyActiveProjects && projectStatus !== 'Active') return acc;

    if (projectId && projectId !== '') {
      const projectCode = projectId.split('_')[0];
      const clientCode = projectCode.replace(/[0-9]/g, '');

      if (clientCode in acc) {
        return { ...acc, [clientCode]: [ ...acc[clientCode], projectId ] };
      } else {
        return { ...acc, [clientCode]: [ projectId ] };
      }
    } else {
      return acc;
    }
  }, {});
}

/**
 * Create dictionary for the following:
 * ClientCode -> TWCompanyId
 * 
 * @param {[object]} clients Rows from the Project Revenus spreadsheet's Clients sheet
 * @returns Dictionary object
 */
export function getClientCodeToTWCompanyId(clients) {
  return clients.reduce((acc, client) => {
    const clientCode = client['ClientCode'];

    if (clientCode === '') {
      return acc;
    } else {
      return { ...acc, [clientCode]: client['TWCompanyId'] };
    }
  }, {});
}

/**
 * Create dictionary for the following:
 * TWCompanyId -> ClientGroup
 * 
 * @param {[object]} clients Rows from the Project Revenus spreadsheet's Clients sheet
 * @returns Dictionary object
 */
export function getTeamworkIdToClientGroup(clients) {
  return clients.reduce((acc, client) => {
    const twCompanyId = client['TWCompanyId'];
    const clientGroup = client['ClientGroup'];

    if (twCompanyId === '') {
      return acc;
    } else {
      return { ...acc, [twCompanyId]: clientGroup };
    }
  }, {});
}

/**
 * Create dictionary for the following:
 * TWCompanyId -> ClientCode
 * 
 * @param {[object]} clients Rows from the Project Revenus spreadsheet's Clients sheet
 * @returns Dictionary object
 */
export function getTeamworkIdToClientCode(clients) {
  return clients.reduce((acc, client) => {
    const twCompanyId = client['TWCompanyId'];
    const clientCode = client['ClientCode'];

    if (twCompanyId === '') {
      return acc;
    } else {
      return { ...acc, [twCompanyId]: clientCode };
    }
  }, {});
}
