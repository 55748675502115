import axios from 'axios';

/**
 * Gets the cached copy of the Contract Processing report. It's possible the cache file
 * was not generated yet or has somehow been deleted. In this case the report will refresh
 * and can take several minutes.
 * @param {string} token Instat Apps API token
 * @param {object} params Any URL parameters
 * @returns Contract processing report data
 */
export async function getContractProcessingReport(token, params = {}) {
  const request = {
    url: `api/reports/contract-processing`,
    method: 'get',
    headers: {
      Authorization: `Bearer ${token}`,
    },
    params,
  };

  try {
    const response = await axios(request);
    return {
      status: 200,
      message: 'Contract processing report data received.',
      data: response.data,
    };
  } catch (error) {
    const statusCode = Number(error.message.slice(error.message.length - 3));
    return { status: statusCode, message: error.message, data: {} };
  }
}

/**
 * Refreshes the Contract Processing report with new start and end date values. This process can
 * take several minutes. The refreshed report is cached on the back end.
 * @param {string} token Instat Apps API token
 * @param {object} body Values required to rerun report (start and end dates)
 * @returns Response from Instat Apps API
 */
export async function refreshContractProcessingReport(token, startDate, endDate) {
  const request = {
    url: `api/reports/contract-processing`,
    method: 'post',
    headers: {
      Authorization: `Bearer ${token}`,
    },
    data: {
      startDate,
      endDate,
    },
  };

  try {
    const response = await axios(request);
    return {
      status: 200,
      message: 'Contract processing report refreshed and report data received.',
      data: response.data,
    };
  } catch (error) {
    const statusCode = Number(error.message.slice(error.message.length - 3));
    return { status: statusCode, message: error.message, data: {} };
  }
}
