import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { useTable } from 'react-table';
import { Table } from 'semantic-ui-react';
import columnDefinitions from '../data/column-definitions';

function ContractProcessingReportTable({
  tableData,
  isLoading,
  tableSort,
  handleTableHeaderClick,
}) {
  const { columnForSort, direction } = tableSort;

  const columns = useMemo(columnDefinitions, [ columnDefinitions ]);
  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = useTable({
    columns: columns,
    data: tableData,
  });

  return (
    <React.Fragment>
      <div style={{ overflowX: 'auto', padding: '10px 0 2px 0' }}>
        {tableData.length === 0 || isLoading ? (
          <div />
        ) : (
          <Table celled singleLine unstackable compact selectable sortable {...getTableProps()}>
            <Table.Header>
              {headerGroups.map(headerGroup => (
                <Table.Row {...headerGroup.getHeaderGroupProps()}>
                  {headerGroup.headers.map(column => {
                    return (
                      <Table.HeaderCell
                        {...column.getHeaderProps()}
                        sorted={columnForSort === column.id ? direction : null}
                        onClick={() => handleTableHeaderClick(column.id)}>
                        {column.render('Header')}
                      </Table.HeaderCell>
                    );
                  })}
                </Table.Row>
              ))}
            </Table.Header>
            <Table.Body {...getTableBodyProps()}>
              {rows.map((row, i) => {
                prepareRow(row);

                return (
                  <Table.Row {...row.getRowProps()}>
                    {row.cells.map(cell => (
                      <Table.Cell {...cell.getCellProps()}>{cell.render('Cell')}</Table.Cell>
                    ))}
                  </Table.Row>
                );
              })}
            </Table.Body>
          </Table>
        )}
      </div>
    </React.Fragment>
  );
}

ContractProcessingReportTable.propTypes = {
  tableData: PropTypes.arrayOf(
    PropTypes.shape({
      content: PropTypes.string,
      startDate: PropTypes.string,
      startToSigned: PropTypes.oneOfType([ PropTypes.number, PropTypes.string ]),
      startToComplete: PropTypes.oneOfType([ PropTypes.number, PropTypes.string ]),
    })
  ).isRequired,
  isLoading: PropTypes.bool.isRequired,
  tableSort: PropTypes.shape({
    columnForSort: PropTypes.string,
    direction: PropTypes.string,
  }).isRequired,
  handleTableHeaderClick: PropTypes.func.isRequired,
};

export default ContractProcessingReportTable;
