import React from 'react';
import { Container, Table, Message } from 'semantic-ui-react';

function UpdatesTable({ taskUpdates }) {
  function getUserTimezone() {
    return Intl.DateTimeFormat().resolvedOptions().timeZone;
  }

  const updateHeaders = ['User', 'Modification Date*', 'Due Date*'];
  /**
   * Takes a Date object and returns a formatted date/time string in the user's current time zone.
   * @param {Date} date
   * @returns Formatted date with user's time zone.
   */
  function formatDate(date) {
    return date.toLocaleString('en-US', {
      timeZone: getUserTimezone(),
    });
  }

  function formatTeamworkDate(dateStr) {
    if (dateStr === '') return 'None';

    const dateStrArr = dateStr.split('T');
    const dateValues = dateStrArr[0].split('-');
    return `${dateValues[1]}/${dateValues[2]}/${dateValues[0]}`;
  }

  function toTitleCase(str) {
    return str.replace(/\w\S*/g, function(txt) {
      return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
    });
  }

  return (
    <Table>
      <Table.Header>
        <Table.Row>
          {updateHeaders.map(th => {
            return (
              <Table.HeaderCell key={`task-updates-th-${th}`} onClick={() => console.log('sort')}>
                {th}
              </Table.HeaderCell>
            );
          })}
        </Table.Row>
      </Table.Header>
      <Table.Body>
        {taskUpdates.map((update, rIndex) => {
          return (
            <Table.Row key={`task-updates-tr-${update.id}`}>
              <Table.Cell>{update.user}</Table.Cell>
              <Table.Cell>{formatDate(new Date(update.modificationDate))}</Table.Cell>
              <Table.Cell>{formatTeamworkDate(update.dueDate)}</Table.Cell>
            </Table.Row>
          );
        })}
      </Table.Body>
      <Table.Footer fullWidth>
        <Table.Row>
          <Table.HeaderCell colSpan={updateHeaders.length}>
            * All dates and times are given in your current timezone "{getUserTimezone()}."
          </Table.HeaderCell>
        </Table.Row>
      </Table.Footer>
    </Table>
  );
}

export default UpdatesTable;
