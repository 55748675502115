import React from 'react';

const calculationExplanations = [
  {
    Column: 'Incurred Cost',
    'Calculation Explanation': (
      <div>
        <p>Incurred Hours x 10kft Rate</p>
      </div>
    ),
  },
  {
    Column: 'Budget Remaining',
    'Calculation Explanation': (
      <div>
        <p>Budget - Incurred Cost</p>
      </div>
    ),
  },
  {
    Column: 'Scheduled Cost',
    'Calculation Explanation': (
      <div>
        <p>Scheduled Hours x 10kft Rate</p>
      </div>
    ),
  },
  {
    Column: 'Breakeven Cost',
    'Calculation Explanation': (
      <div>
        <p>Incurred Hours x Breakeven Rate</p>
      </div>
    ),
  },
  {
    Column: 'Profit',
    'Calculation Explanation': (
      <div>
        <p>Budget - Breakeven Cost</p>
      </div>
    ),
  },
  {
    Column: 'Scheduled Breakeven Cost',
    'Calculation Explanation': (
      <div>
        <p>Scheduled Hours x Breakeven Rate</p>
      </div>
    ),
  },
];

export default calculationExplanations;
