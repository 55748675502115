import React from 'react';

const AppsIcon = ({ fillColor = 'rgba(255,255,255,.9)', width = '24', height = '24' }) => {
  let icon = (
    <svg
      fill={fillColor}
      xmlns={'http://www.w3.org/2000/svg'}
      width={width}
      height={height}
      viewBox={'0 0 24 24'}
    >
      <g fillRule="evenodd">
        <rect width="6" height="6" rx="1" />
        <rect width="6" height="6" y="9" rx="1" />
        <rect width="6" height="6" x="9" y="9" rx="1" />
        <rect width="6" height="6" x="18" y="9" rx="1" />
        <rect width="6" height="6" y="18" rx="1" />
        <rect width="6" height="6" x="9" y="18" rx="1" />
        <rect width="6" height="6" x="18" y="18" rx="1" />
        <rect width="6" height="6" x="9" rx="1" />
        <rect width="6" height="6" x="18" rx="1" />
      </g>
    </svg>
  );

  return icon;
};

export default AppsIcon;
