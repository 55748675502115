import axios from 'axios';

export async function getActiveProjectsFinancialsReport(token, showError) {
  const request = {
    url: '/api/reports/active-projects-financials',
    method: 'get',
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  try {
    const response = await axios(request);
    return { status: 200, message: 'Data received.', data: response.data };
  } catch (error) {
    const statusCode = Number(error.message.slice(error.message.length - 3));

    return { status: statusCode, message: error.message, data: {} };
  }
}

export async function refreshActiveProjectsFinancialsReport(token) {
  const request = {
    url: '/api/reports/active-projects-financials/refresh',
    method: 'get',
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  try {
    const response = await axios(request);
    return { status: 200, message: 'Data refreshed and received.', data: response.data };
  } catch (error) {
    const statusCode = Number(error.message.slice(error.message.length - 3));

    return { status: statusCode, message: error.message, data: {} };
  }
}

/**
 * Makes a call to Instat Apps API to create a new spreadsheet in the given user's Google Drive root folder.
 * Column widths are automatically resized to fit content.
 * @param {string} token Instat Apps API token
 * @param {object} data Contains key-value pairs for the following keys: title, values, and userEmail. title is the name of the spreadsheet file, userEmail is the Instat domain email for the user creating the new Google Sheets spreadsheet, and values is an array of arrays of strings containing row values (each array of strings is a row)
 * @returns Object containing status code (200 for success) and message
 */
export async function openInSheets(token, data) {
  const { title, values, userEmail } = data;

  // Create new spreadsheet with values
  const request = {
    url: `/api/google-sheets/open-in-sheets`,
    method: 'post',
    headers: {
      Authorization: `Bearer ${token}`,
    },
    data: {
      title,
      userEmail,
      values,
    },
  };

  try {
    const response = await axios(request);
    const { message, spreadsheetId, sheetId } = response.data;

    // Auto resize column widths to fit content
    const requestAdjustWidth = {
      url: `/api/google-sheets/batch-update-spreadsheet`,
      method: 'post',
      headers: {
        Authorization: `Bearer ${token}`,
      },
      data: {
        spreadsheetId,
        userEmail,
        requests: [
          {
            autoResizeDimensions: {
              dimensions: {
                sheetId,
                dimension: 'COLUMNS',
                startIndex: 0,
              },
            },
          },
        ],
      },
    };

    const responseAdjustWidth = await axios(requestAdjustWidth);
    console.log(JSON.stringify(responseAdjustWidth));

    return { status: 200, message, spreadsheetId };
  } catch (error) {
    const statusCode = Number(error.message.slice(error.message.length - 3));
    return { status: statusCode, message: error.message };
  }
}
