import React from 'react';
import ProjectFinancialsChart from './ProjectFinancialsChart';
import MultiProjectFinancialsChart from './MultiProjectFinancialsChart';

function FinancialsReportSwitch({ reportData, getProjectNameFrom10kftId, viewOptions }) {
  const projectKeys = Object.keys(reportData);
  const isMulti = projectKeys.length > 1;

  return isMulti ? (
    <MultiProjectFinancialsChart
      reportData={
        viewOptions.showPassThru
          ? projectKeys.reduce(
              (acc, key) => ({ ...acc, [key]: reportData[key].projectDataWithPassThru }),
              {}
            )
          : projectKeys.reduce((acc, key) => ({ ...acc, [key]: reportData[key].projectData }), {})
      }
      getProjectNameFrom10kftId={getProjectNameFrom10kftId}
      viewOptions={viewOptions}
    />
  ) : (
    <ProjectFinancialsChart
      reportData={
        viewOptions.showPassThru
          ? reportData[projectKeys[0]].projectDataWithPassThru
          : reportData[projectKeys[0]].projectData
      }
      viewOptions={viewOptions}
    />
  );
}

export default FinancialsReportSwitch;
