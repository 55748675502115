/**
 * P R O J E C T   C R E A T O R
 * This is a component that serves as a form to submit an API request to create new projects. See below information on new project field specifics.
 *
 * values for the newly created project
 * NOTE: this project must not currently exist within the specified account
 * people in Unfuddle have IDs: instat=1, kathie=2, kyle=3, dave=4, mark=5, patches=6, holly=7, shelia=8, christy=9, kenton=12, ryan=21, anitha=24, balaji=30, brenda=31, wendy=36, maureen=37, mohan=38, srinath=39, lori=54, chris=55, ravindran=58, sudarsan=62, sudha=69, shivkumar=67, shirisha=81
 * contractors: soumya=25, CROS NT: jaikumar=74, laya=75, ramadevi=76, senthil=83, sridhar=77, umayal=71, kiran=82, srihari=73
 * non-active people: cary=33, ravikumar=34,
 * themes for project leads: kyle=grey, mark=blue, dave=green, patches=orange, shelia=purple, kenton=black, anitha=teal ##
 *
 * PROJECT_THEME: grey
 * CLIENT: Celgene
 * PROJECT_TITLE: 'CELG FEDR TED12015/12037'
 * PROJECT_SHORTNAME: celg-fedr-ted12015
 * PROJECT_DESCRIPTION: 'CSR rewrite for TED12015 and TED12037 (to be combined in one CSR)'
 * PROJECT_STATUS: Confirmed
 * PROJECT_ADMIN: [3]
 * PROJECT_NONADMIN: [5,6,7,9,21,24,30,36,37,38,39,54,55,67,69,81,25,74,75,76,83,77,71,82,73]
 * DO_10KFT: false
 * DO_UNFUDDLE: true
 * DO_REPO: true
 */

import React, { Fragment, useState, useEffect } from 'react';
import { Formik, Field } from 'formik';
import * as Yup from 'yup';
import axios from 'axios';
import { themeOptions } from '../../data/project-creator/beanstalk';
import {
  Container,
  Form,
  Message,
  Select,
  Button,
  Divider,
  Header,
  Icon,
  Label,
  Dimmer,
  Loader,
} from 'semantic-ui-react';
import { useToasts } from 'react-toast-notifications';
import CheckboxField from '../../components/CheckboxField';
import DateField from '../../components/DateField';
import './styles/index.scss';
import { authenticateGoogle } from '../../services/api/auth';
import { getPeople, getCompanies } from '../../services/api/teamwork';
import AddClientModal from '../AddClientModal';
import { getProjectRevenueRows } from '../../services/api/project-revenue';
import { useAuth, useAuthFunctions } from '../../context/AuthContext';

export const ProjectCreator = ({ history }) => {
  const { addToast } = useToasts();
  const auth = useAuth();
  const { token } = auth.user;
  const { logout, authUser } = useAuthFunctions();

  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingInitial, setIsLoadingInitial] = useState(true);
  const [people, setPeople] = useState([]);
  const [companies, setCompanies] = useState([]);
  const [revenueProjects, setRevenueProjects] = useState([]);
  const [clientLists, setClientLists] = useState({ revenue: [], teamwork: [] });
  const [addClientIsOpen, setAddClientIsOpen] = useState(false);

  const ProjectCreatorSchema = Yup.object().shape({
    hasParentProject: Yup.string().notRequired(),
    parentProject: Yup.string().when('hasParentProject', (hasParentProject, schema) => {
      if (hasParentProject === 'yes') return schema.required('Parent project selection required.');
      else return schema.notRequired();
    }),
    projectTheme: Yup.string().required('Project Theme field is required.'),
    client: Yup.string().required('Client field is required.'),
    projectTitle: Yup.string().required('Project name field is required.'),
    projectShortName: Yup.string().required('Project short name field is required.'),
    projectDescription: Yup.string().required('Project description is required.'),
    startDate: Yup.date()
      .nullable()
      .max(Yup.ref('endDate'))
      .when('endDate', (endDate, schema) => {
        // Makes sure both dates required if one is filled.
        if (endDate) return schema.required('Start date required when end date given.');
        else return schema.notRequired();
      }),
    endDate: Yup.date()
      .nullable()
      .min(Yup.ref('startDate'))
      .notRequired(),
    projectStatus: Yup.string().required('Project status field is required.'),
    projectType: Yup.string().required('Project type field is required.'),
    do10kft: Yup.bool().notRequired(),
    doTw: Yup.bool().notRequired(),
    doRepo: Yup.bool().notRequired(),
    doGoogleDrive: Yup.bool().notRequired(),
    accountLead: Yup.string().notRequired(),
    projectLead: Yup.string().notRequired(),
    employees: Yup.array()
      .of(Yup.string())
      .notRequired(),
  });

  // Get list of people from Teamwork
  async function getSetPeople(token) {
    try {
      let people = await getPeople(token);
      let peopleFormatted = people.map(person => ({
        key: person.id,
        text: `${person.firstName} ${person.lastName}`,
        value: person.id,
      }));
      setPeople(peopleFormatted);
    } catch (error) {
      console.error('Could not get people from database.');
      logout();
      history.push('/login');
    }
  }

  /**
   * Gets a full list of clients from:
   *   - Project Revenue spreadsheet
   *   - Teamwork
   * @param {string} token Instat Apps API token
   */
  async function getSetCompanies(token) {
    try {
      let companies = await getCompanies(token);
      const clientsAll = await getProjectRevenueRows(token, 'Clients');
      let clients = clientsAll
        .filter(c => c.values['ClientCode'] !== '' && c.values['TWCompanyId'] !== '')
        .map(c => c.values);
      let revenueToTeamwork = clients.reduce((dict, client) => {
        const twCompany = companies.find(company => company.id === client['TWCompanyId']);

        if (!twCompany) return dict;

        return {
          ...dict,
          [client['ClientCode']]: twCompany,
        };
      }, {});
      setClientLists({
        ...clientLists,
        revenue: clientsAll.map(c => c.values),
        teamwork: companies,
        revenueToTeamwork,
      });

      let clientOptions = clients
        .filter(c => c['ClientCode'] !== '' && c['TWCompanyId'] !== '')
        .map(c => ({ key: c['ClientCode'], value: c['ClientCode'], text: c['Client'] }));
      setCompanies(clientOptions);
    } catch (error) {
      console.error('Could not get companies from database');
      logout();
      history.push('/login');
    }
  }

  async function getSetRevenueProjects(token) {
    try {
      let projects = await getProjectRevenueRows(token, 'Projects');
      projects = projects
        .map(p => p.values)
        .filter(p => p['Status'] === 'Active')
        .filter(p => p['ProjectId'] !== '');
      setRevenueProjects(projects);
    } catch (error) {
      console.error('Could not get projects from Project Revenue spreadsheet.');
    }
  }

  async function initializeData(token) {
    console.log('initializing data...');
    await getSetPeople(token);
    await getSetCompanies(token);
    await getSetRevenueProjects(token);
    console.log('done');
  }

  useEffect(() => {
    initializeData(token)
      .then(data => setIsLoadingInitial(false))
      .catch(err => console.error(err));
  }, [history, logout, token]);

  const projectStatusOptions = [
    { key: 'confirmed', text: 'Confirmed', value: 'Confirmed' },
    { key: 'tentative', text: 'Tentative', value: 'Tentative' },
  ];
  const themeOptionsFormatted = themeOptions.map(theme => ({
    key: theme.value,
    text: theme.label,
    value: theme.value,
  }));

  // Determines which folders and docs are added to project folder in Google Drive
  const projectTypes = [
    {
      key: 'biostats',
      text: 'Biostats',
      value: 'biostats',
    },
    {
      key: 'biostats,dm',
      text: 'Biostats and Data Management',
      value: 'biostats,dm',
    },
    {
      key: 'consulting',
      text: 'Consulting',
      value: 'consulting',
    },
    {
      key: 'dm',
      text: 'Data Management',
      value: 'dm',
    },
    {
      key: 'split-out',
      text: 'Split-Out',
      value: 'split-out',
    },
  ];
  projectTypes.sort((a, b) => (a.text.toUpperCase() < b.text.toUpperCase() ? -1 : 1));

  // Initial values for the project creation form inputs.
  const initialValues = {
    client: null,
    hasParentProject: 'no',
    parentProject: '',
    projectTitle: '',
    projectShortName: '',
    projectDescription: '',
    contract: '',
    startDate: null,
    endDate: null,
    projectStatus: '',
    projectType: '',
    projectTheme: 'label-white',
    projectAdmin: undefined,
    projectNonAdmin: undefined,
    do10kft: false,
    doTw: false,
    doRepo: true,
    doGoogleDrive: true,
    accountLead: '',
    projectLead: '',
    employees: [],
  };

  function getProjectOptions() {
    const uniqueProjectCodes = revenueProjects.reduce((acc, p) => {
      const projectCode = p['ProjectCode'];
      if (projectCode in acc) return acc;
      else return { ...acc, [projectCode]: p['Project Name'] };
    }, {});

    const projectOptions = Object.keys(uniqueProjectCodes).map(code => ({
      key: code,
      value: code,
      text: `${uniqueProjectCodes[code]} (${code})`,
    }));
    projectOptions.sort((a, b) => {
      if (a.text < b.text) return -1;
      if (a.text > b.text) return 1;
      return 0;
    });

    return projectOptions;
  }

  /**
   * If this function is called, that means that a catch statement at the end of the line has been reached.
   * It can mean one of two things:
   * 1. User token has either expired or it doesn't exist
   * 2. User's Google authentication has either expired or doesn't exist
   *
   * This will redirect the user to the login page and allow the user to log into their Google
   * account again. The logout action is called in order to ensure that any user data is cleared
   * before authenticating with Google again. This should prevent any potential weird login errors.
   *
   * @param {Object} error error object received during API call
   */
  const errorLogout = async error => {
    // REVIEW: Some sort of error notification instead of printing to console.
    addToast(
      error.data.error.message ||
        'Sorry, there was an error while trying to create the project. Please log in and try again.',
      { appearance: 'error', autoDismiss: true }
    );
    await logout();
    history.push('/login');
  };

  const notifyClientCreated = (message, appearance = 'success') => {
    addToast(message, {
      appearance,
      autoDismiss: true,
    });
  };

  /**
   * C O M P O N E N T
   */
  return (
    <Fragment>
      <Dimmer active={isLoadingInitial} inverted>
        <Loader inverted>Initializing form data...</Loader>
      </Dimmer>

      <Container style={{ margin: '30px 0' }}>
        <div style={{ maxWidth: '800px', margin: 'auto' }}>
          <Message attached header={'Project Creator'} size={'huge'} />
          <Message attached icon>
            <Icon name="info circle" />
            <div>
              <p>
                Fill out the form below to create a new project. Any fields with a red asterisk are
                required. Be sure to select all the options you need to create the project in each
                service.
              </p>
              <p>
                Questions?{' '}
                <a href="https://slack.com/app_redirect?channel=UH3AW9GTZ">
                  Message Jamie on Slack! <Icon name="external" />
                </a>
              </p>
            </div>
          </Message>
          <Formik
            initialValues={initialValues}
            onSubmit={async (values, { resetForm }) => {
              // TODO: on submit, refresh projects list for parent project
              setIsLoading(true);

              // Format client entity for backend
              let client = companies.find(c => c.key === values.client);
              let clientTeamworkId = clientLists.revenueToTeamwork[values.client].id;
              values = {
                ...values,
                client: { code: client.key, id: clientTeamworkId, name: client.text },
              };

              // Submit initial request
              const request = {
                url: '/api/project-creator',
                method: 'post',
                data: values,
                headers: {
                  Authorization: `Bearer ${token}`,
                },
              };

              try {
                const response = await axios(request);
                addToast(response.data.messages.join('\n'), {
                  appearance: 'success',
                  autoDismiss: true,
                });
                setIsLoading(false);
              } catch (error) {
                if (error.response.status === 500 || error.response.status === 409) {
                  addToast(
                    error.response.data.error.message ||
                      'Sorry, there was an error while trying to create the project. Please try again.',
                    { appearance: 'error', autoDismiss: true }
                  );
                } else {
                  /**
                   * Complete Re-authentication
                   * If this error handler callback is reached, it means that the user's access token has expired or is otherwise invalid. A full app authentication will be invoked. In this case, the following will automatically happen:
                   * 1. Re-authenticate with Google
                   * 2. Re-authenticate with API server
                   * 3. Save user data (new Google JWS token and new API access token) to redux state
                   * 4. Make API create project request using new token
                   *
                   * Success: Form processes as normal. Since new tokens are saved to state, future app functions will proceed as normal.
                   * Error: User is redirected to /login and user is completely logged out (user data deleted from redux state).
                   *
                   * NOTE: If react-google-login package ever removed, follow the instructions at https://developers.google.com/identity/sign-in/web/sign-in to add Google API package. The react-google-login package automatically adds the necessary HTML script to load the Google API functions.
                   */

                  const gauth = await authenticateGoogle();
                  const userData = await authUser(gauth);

                  try {
                    const response = await axios({
                      url: '/api/project-creator',
                      method: 'post',
                      data: values,
                      headers: {
                        Authorization: `Bearer ${userData.token}`,
                      },
                    });
                    addToast(response.data.messages.join('\n'), {
                      appearance: 'success',
                      autoDismiss: true,
                    });
                  } catch (error) {
                    errorLogout(error.response);
                  }
                }

                setIsLoading(false);
              }

              resetForm(initialValues);
            }}
            validationSchema={ProjectCreatorSchema}
            enableReinitialize
          >
            {({ values, handleSubmit, errors, touched, setFieldValue, isValid, dirty }) => (
              <Form
                onSubmit={handleSubmit}
                className={'attached fluid segment'}
                loading={isLoading}
                style={{ borderRadius: '0 0 .28571429rem .28571429rem' }}
              >
                {/* Main Project Details */}
                <Header>
                  <Icon name={'tasks'} />
                  Project Details
                </Header>

                {/* Parent Project Selection */}
                <Form.Group widths="equal" style={{ margin: '0 -.5em 0' }}>
                  <Form.Field
                    control={Select}
                    name={'hasParentProject'}
                    label={'Has Parent Project?'}
                    options={[
                      { key: 'no', value: 'no', text: 'No' },
                      { key: 'yes', value: 'yes', text: 'Yes' },
                    ]}
                    value={values.hasParentProject}
                    onChange={(e, { name, value }) => setFieldValue(name, value)}
                  />
                  {errors.projectCode && touched.projectCode ? (
                    <div style={{ fontSize: '10px', color: 'red' }}>{errors.projectCode}</div>
                  ) : null}
                  <Form.Field
                    control={Select}
                    name={'parentProject'}
                    label={'Parent Project'}
                    options={getProjectOptions()}
                    value={values.parentProject}
                    onChange={(e, { name, value }) => setFieldValue(name, value)}
                    disabled={values.hasParentProject === 'no'}
                    search
                    required={values.hasParentProject === 'yes'}
                  />
                  {errors.parentProject && touched.parentProject ? (
                    <div style={{ fontSize: '10px', color: 'red' }}>{errors.parentProject}</div>
                  ) : null}
                </Form.Group>
                <div style={{ marginBottom: '1em', marginTop: '.25em' }}>
                  <small style={{ color: '#6c757d!important' }}>
                    Unless a parent project is selected, a new project code will be created for this
                    project.
                  </small>
                </div>

                {/* Project Title */}
                <Form.Group style={{ margin: '0 -.5em 0' }}>
                  <Form.Field required style={{ width: '100%' }}>
                    <label htmlFor={'projectTitle'}>Project Name</label>
                    <Field
                      name={'projectTitle'}
                      type={'text'}
                      placeholder={''}
                      autoComplete={'off'}
                    />
                    {errors.projectTitle && touched.projectTitle ? (
                      <div style={{ fontSize: '10px', color: 'red' }}>{errors.projectTitle}</div>
                    ) : null}
                  </Form.Field>
                </Form.Group>
                <div style={{ marginBottom: '1em', marginTop: '0' }}>
                  <small style={{ color: '#6c757d!important' }}>
                    Project name must follow one of these formats:
                    <ul style={{ margin: '0', lineHeight: '1.2' }}>
                      <li>
                        <strong>CRO</strong>: sponsor name + protocol + short description
                      </li>
                      <li>
                        <strong>No CRO</strong>: protocol + short description
                      </li>
                    </ul>
                  </small>
                </div>

                {/* Project Short Name */}
                <Form.Field required>
                  <label htmlFor={'projectShortName'}>Project Short Name</label>
                  <Field
                    name={'projectShortName'}
                    type={'text'}
                    placeholder={'Ex: celg-fedr-ted12015'}
                    autoComplete={'off'}
                    validate={value => {
                      let error;
                      let regex = new RegExp(/^[-a-z0-9]*$/g);
                      if (!regex.test(value)) {
                        error =
                          'Short name can only contain lowercase letters, numbers, and dashes.';
                      }
                      return error;
                    }}
                  />
                  {errors.projectShortName && touched.projectShortName ? (
                    <div style={{ fontSize: '10px', color: 'red' }}>{errors.projectShortName}</div>
                  ) : null}
                </Form.Field>

                {/* Project Description */}
                <Form.Field required>
                  <label htmlFor={'projectDescription'}>Project Description</label>
                  <Field
                    name={'projectDescription'}
                    type={'text'}
                    placeholder={'Ex: Description...'}
                    autoComplete={'off'}
                  />
                  {errors.projectDescription && touched.projectDescription ? (
                    <div style={{ fontSize: '10px', color: 'red' }}>
                      {errors.projectDescription}
                    </div>
                  ) : null}
                </Form.Field>

                {/* Contract */}
                <Form.Field required>
                  <label htmlFor={'contract'}>Contract</label>
                  <Field
                    name={'contract'}
                    type={'text'}
                    placeholder={'Ex: WO32'}
                    autoComplete={'off'}
                  />
                  {errors.contract && touched.contract ? (
                    <div style={{ fontSize: '10px', color: 'red' }}>{errors.contract}</div>
                  ) : null}
                </Form.Field>

                {/* Start & End Dates */}
                <Form.Group widths={'equal'} style={{ margin: '0 -.5em 0' }}>
                  <Form.Field
                    label={'Start Date'}
                    name={'startDate'}
                    control={DateField}
                    selected={values.startDate}
                    onChange={date => {
                      setFieldValue('startDate', date);
                    }}
                    selectsStart
                    startDate={values.startDate}
                    endDate={values.endDate}
                    maxDate={values.endDate}
                    placeholderText={'MM/DD/YYYY'}
                    autoComplete={'off'}
                    required={values.startDate !== null || values.endDate !== null}
                  />
                  {errors.startDate && touched.startDate ? (
                    <div style={{ fontSize: '10px', color: 'red' }}>{errors.startDate}</div>
                  ) : null}
                  <Form.Field
                    label={'End Date'}
                    name={'endDate'}
                    control={DateField}
                    selected={values.endDate}
                    onChange={date => setFieldValue('endDate', date)}
                    selectsEnd
                    startDate={values.startDate}
                    endDate={values.endDate}
                    minDate={values.startDate}
                    placeholderText={'MM/DD/YYYY'}
                    autoComplete={'off'}
                    required={values.startDate !== null || values.endDate !== null}
                  />
                  {errors.endDate && touched.endDate ? (
                    <div style={{ fontSize: '10px', color: 'red' }}>{errors.endDate}</div>
                  ) : null}
                </Form.Group>
                <div style={{ marginBottom: '1em', marginTop: '.25em' }}>
                  <small style={{ color: '#6c757d!important' }}>
                    <strong>NOTE:</strong> It is not possible to only set one date. Use your best
                    guess for the other date if unknown.
                  </small>
                </div>

                {/* Client */}
                <Form.Group style={{ display: 'flex', flexDirection: 'row', marginBottom: '1em' }}>
                  <Form.Field
                    control={Select}
                    name={'client'}
                    label={'Client'}
                    options={companies}
                    placeholder="Select client..."
                    value={values.client}
                    required
                    onChange={(e, { name, value }) => setFieldValue(name, value)}
                    className="client-input"
                    search
                  />
                  <AddClientModal
                    isOpen={addClientIsOpen}
                    toggleOpen={() => setAddClientIsOpen(!addClientIsOpen)}
                    token={token}
                    notifyClientCreated={notifyClientCreated}
                    clients={companies.map(c => c.text)}
                    clientCodes={clientLists.revenue
                      .filter(c => c['ClientCode'] !== '')
                      .map(c => c['ClientCode'])}
                    refreshClientsDropdown={() => getSetCompanies(token)}
                  />
                </Form.Group>
                {errors.client && touched.client ? (
                  <div style={{ fontSize: '10px', color: 'red' }}>{errors.client}</div>
                ) : null}

                {/* Project Theme */}
                <Form.Field
                  control={Select}
                  name={'projectTheme'}
                  label={'Project Theme'}
                  options={themeOptionsFormatted}
                  placeholder="Select theme..."
                  defaultValue={'label-white'}
                  onChange={(e, { name, value }) => setFieldValue(name, value)}
                />
                {errors.projectTheme && touched.projectTheme ? (
                  <div style={{ fontSize: '10px', color: 'red' }}>{errors.projectTheme}</div>
                ) : null}

                {/* Project Status */}
                <Form.Field
                  control={Select}
                  name={'projectStatus'}
                  label={'Project Status'}
                  options={projectStatusOptions}
                  placeholder="Select status..."
                  value={values.projectStatus}
                  required
                  onChange={(e, { name, value }) => setFieldValue(name, value)}
                  style={{ marginBottom: '-0.75em' }}
                />
                {errors.projectStatus && touched.projectStatus ? (
                  <div style={{ fontSize: '10px', color: 'red' }}>{errors.projectStatus}</div>
                ) : null}
                <div style={{ marginBottom: '1em' }}>
                  <small style={{ color: '#6c757d!important' }}>
                    If marked as <em>Confirmed</em>, the project will be listed as "Active" in the
                    Projects sheet.
                  </small>
                </div>

                {/* Project Type */}
                <Form.Field
                  control={Select}
                  name={'projectType'}
                  label={'Project Type'}
                  options={projectTypes}
                  placeholder="Select type..."
                  value={values.projectType}
                  required
                  onChange={(e, { name, value }) => setFieldValue(name, value)}
                  style={{ marginBottom: '-0.75em' }}
                />
                {errors.projectType && touched.projectType ? (
                  <div style={{ fontSize: '10px', color: 'red' }}>{errors.projectType}</div>
                ) : null}
                <div style={{ marginBottom: '1em' }}>
                  <small style={{ color: '#6c757d!important' }}>
                    <strong>NOTE:</strong> Data Management and Consulting types do <em>not</em>{' '}
                    import TLFs/SDTM/ADaM Specs templates. Split-out projects will not create a
                    folder in Google Drive; use these for splitting a project into multiple
                    sub-project rows in the spreadsheet.
                  </small>
                </div>

                {/* User Access Options */}
                <Divider />
                <Header>
                  <Icon name={'users'} />
                  <Header.Content>
                    User Access
                    <Header.Subheader>
                      Choose which employees will be given access to the project in Teamwork. By
                      default, the project lead is given project administrator privileges; all
                      others selected are given default permissions.
                    </Header.Subheader>
                  </Header.Content>
                </Header>
                {/* Account Lead */}
                <Form.Field
                  control={Select}
                  name={'accountLead'}
                  label={'Account Lead'}
                  options={people}
                  placeholder={'Select account lead...'}
                  value={values.accountLead}
                  fluid
                  search
                  clearable
                  onChange={(e, { name, value }) => setFieldValue(name, value)}
                />
                {errors.accountLead && touched.accountLead ? (
                  <div style={{ fontSize: '10px', color: 'red' }}>{errors.accountLead}</div>
                ) : null}

                {/* Project Lead */}
                <Form.Field
                  control={Select}
                  name={'projectLead'}
                  label={'Project Lead'}
                  options={people}
                  placeholder={'Select project lead...'}
                  value={values.projectLead}
                  fluid
                  search
                  clearable
                  onChange={(e, { name, value }) => setFieldValue(name, value)}
                />
                {errors.projectLead && touched.projectLead ? (
                  <div style={{ fontSize: '10px', color: 'red' }}>{errors.projectLead}</div>
                ) : null}

                {/* Employees with Access */}
                <Form.Field
                  control={Select}
                  name={'employees'}
                  label={'Employee(s) With Access'}
                  options={people}
                  placeholder={'Select employee(s)...'}
                  value={values.employees}
                  fluid
                  search
                  multiple
                  onChange={(e, { name, value }) => setFieldValue(name, value)}
                />
                {errors.employees && touched.employees ? (
                  <div style={{ fontSize: '10px', color: 'red' }}>{errors.employees}</div>
                ) : null}

                {/* Options */}
                <Divider />
                <Header>
                  <Icon name={'options'} />
                  <Header.Content>
                    Options
                    <Header.Subheader>
                      Choose apps where the project should be created. Regardless of what is
                      selected, a new row will be added to the Project Revenue spreadsheet.
                    </Header.Subheader>
                  </Header.Content>
                </Header>
                <Form.Group inline>
                  {/* Do Teamwork */}
                  <CheckboxField labelText={'Teamwork'} name={'doTw'} />
                  {errors.doTw && touched.doTw ? (
                    <div style={{ fontSize: '10px', color: 'red' }}>{errors.doTw}</div>
                  ) : null}

                  {/* Do Beanstalk */}
                  <CheckboxField labelText={'Repository'} name={'doRepo'} />
                  {errors.doRepo && touched.doRepo ? (
                    <div style={{ fontSize: '10px', color: 'red' }}>{errors.doRepo}</div>
                  ) : null}

                  {/* Do Google Drive */}
                  <CheckboxField labelText={'Google Drive'} name={'doGoogleDrive'} />
                  {errors.doGoogleDrive && touched.doGoogleDrive ? (
                    <div style={{ fontSize: '10px', color: 'red' }}>{errors.doGoogleDrive}</div>
                  ) : null}
                </Form.Group>
                <Divider />
                <div>
                  <Form.Field>
                    <Button
                      id={'project-creator-submit'}
                      primary
                      type={'submit'}
                      disabled={isLoading || !isValid || !dirty}
                      style={{
                        cursor: `${isLoading || !isValid || !dirty ? 'not-allowed' : 'pointer'}`,
                      }}
                    >
                      Submit
                    </Button>
                    {(isLoading || !isValid || !dirty) && (
                      <Label pointing={'left'}>
                        Make sure all required fields (<span style={{ color: '#db2828' }}>*</span>)
                        are filled out.
                      </Label>
                    )}
                  </Form.Field>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </Container>

      <br />
      <br />
    </Fragment>
  );
};

export default ProjectCreator;
