import React, { useState, useEffect } from 'react';
import logoLight from '../../media/instat-logo-light.png';
import './styles/index.scss';
import Help from '../Help';
import { useAuthFunctions } from '../../context/AuthContext';
import { GoogleLoginButton } from '../../components/GoogleLoginButton';

export const Login = ({ history, location }) => {
  document.title = 'Instat Apps - Login';

  const [showHelp, setShowHelp] = useState(false);
  const [prevLocation, setPrevLocation] = useState(null);

  const { authUser } = useAuthFunctions();

  useEffect(() => {
    if (location.state && location.state.from) setPrevLocation(location.state.from);
  }, [location.state]);

  /**
   * Takes response from Google OAuth API and logs in user, saving user info to auth context.
   * @param {Object} response Response from Google OAuth API
   */
  const responseGoogle = async response => {
    const { credential } = response;

    try {
      await authUser(credential);

      // Send user to previous page or homepage
      console.log('now redirecting to page');
      if (prevLocation) history.push(prevLocation);
      history.push('/');
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <React.Fragment>
      <div className={'auth-page'}>
        <img id={'instat-logo'} src={logoLight} width={'300px'} alt={'Instat'} />
        <br />
        <br />
        <div className={'auth-page-text'}>
          <h1>Welcome!</h1>
          <br />
          <p>
            Please click on the sign in button below and sign in with your Instat Google account.
          </p>
        </div>
        <GoogleLoginButton responseGoogle={responseGoogle} />
        <br />
        <br />
        <Help show={showHelp} toggleHelp={() => setShowHelp(!showHelp)} />
      </div>
    </React.Fragment>
  );
};

export default Login;
