import initialState from './initialState';

function _toggleViewOption(state, optionName) {
  return {
    ...state,
    viewOptions: { ...state.viewOptions, [optionName]: !state.viewOptions[optionName] },
  };
}

function reducer(state, action) {
  switch (action.type) {
    case 'SET_INITIAL_STATE':
      return { ...initialState };
    case 'UPDATE_REPORT_DATA':
      return { ...state, data: action.data };
    case 'UPDATE_DEAL_TABLE_DATA':
      return { ...state, dealTableData: action.dealTableData };
    case 'UPDATE_YEARLY_TOTALS':
      return { ...state, yearlyTotals: action.yearlyTotals };
    case 'UPDATE_DEAL_AMOUNTS_BY_YEAR':
      return { ...state, dealAmountsByYear: action.dealAmountsByYear };
    case 'TOGGLE_VIEW_OPTION':
      if (action.payload.optionName.includes('reportType')) {
        const optionInfo = action.payload.optionName.split('/');

        return {
          ...state,
          viewOptions: {
            ...state.viewOptions,
            reportType: optionInfo[1],
          },
        };
      } else {
        return _toggleViewOption(state, action.payload.optionName);
      }
    case 'CHANGE_VIEW_OPTION':
      return {
        ...state,
        viewOptions: {
          ...state.viewOptions,
          [action.payload.optionName]: action.payload.newValue,
        },
      };
    case 'TOGGLE_DATA_VIEW':
      return { ...state, dataView: action.dataView };
    default:
      return state;
  }
}

export default reducer;
