import React from 'react';
import PropTypes from 'prop-types';
import { Field } from 'formik';
import CheckboxInput from './CheckboxInput';

const CheckboxField = ({ name, labelText }) => {
  return (
    <div className={'field'}>
      <div className={'ui checkbox'}>
        <Field component={CheckboxInput} name={name} />
        <label>{labelText}</label>
      </div>
    </div>
  );
};

CheckboxField.propTypes = {
  name: PropTypes.string.isRequired,
  labelText: PropTypes.string.isRequired,
};

export default CheckboxField;
