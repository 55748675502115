export function getProjectCodeToProjectIdsDictionary(projects) {
  const dict = {};

  for (const project of projects) {
    const projectCode = project['ProjectCode'].trim();
    const projectId = project['ProjectId'];

    if (projectCode in dict) {
      dict[projectCode].push(projectId);
    } else {
      dict[projectCode] = [ projectId ];
    }
  }

  return dict;
}

export function getProjectCodeTo10kftProjectIdsDictionary(projects) {
  const dict = {};

  for (const project of projects) {
    const projectCode = project['ProjectCode'].trim();
    const tenkftProjectId = project['10kftProjectId'];

    if (projectCode in dict) {
      dict[projectCode].add(tenkftProjectId);
    } else {
      dict[projectCode] = new Set([ tenkftProjectId ]);
    }
  }

  // Convert all sets to arrays
  for (const projectCode in dict) {
    dict[projectCode] = Array.from(dict[projectCode]);
  }

  return dict;
}

export function getProjectCodeToProjectNameDictionary(projects) {
  const dict = {};

  for (const project of projects) {
    const projectCode = project['ProjectCode'].trim();
    const projectName = project['Project Name'];

    if (!(projectCode in dict)) {
      dict[projectCode] = projectName;
    }
  }

  return dict;
}
