/**
 * Beanstalk Theme Color Options
 */

export const themeOptions = [
  { value: 'label-white', label: 'White' },
  { value: 'label-red', label: 'Red' },
  { value: 'label-red-orange', label: 'Red Orange' },
  { value: 'label-orange', label: 'Orange' },
  { value: 'label-yellow', label: 'Yellow' },
  { value: 'label-yellow-green', label: 'Yellow Green' },
  { value: 'label-aqua-green', label: 'Aqua Green' },
  { value: 'label-green', label: 'Green' },
  { value: 'label-green-blue', label: 'Green Blue' },
  { value: 'label-sky-blue', label: 'Sky Blue' },
  { value: 'label-light-blue', label: 'Light Blue' },
  { value: 'label-blue', label: 'Blue' },
  { value: 'label-orchid', label: 'Orchid' },
  { value: 'label-violet', label: 'Violet' },
  { value: 'label-brown', label: 'Brown' },
  { value: 'label-black', label: 'Black' },
  { value: 'label-grey', label: 'Grey' },
];
