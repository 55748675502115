import React, { useContext, useEffect, useReducer } from 'react';
import { login } from '../services/api/auth';

// EXAMPLE of what Auth state looks like:
//
// auth: {
//   isAuthenticated: boolean,
//   user: {
//     gauth: {
//       tokenType: string,
//       idToken: string,
//     },
//     _id: string,
//     email: string,
//     firstName: string,
//     lastName: string,
//     photoUrl: string,
//     roles: [string],
//     token: string,
//   }
// },

const initialState = {
  isAuthenticated: false,
  isLoading: true,
};

function reducer(state, action) {
  switch (action.type) {
    case 'SET_CURRENT_USER':
      // requires user param
      return {
        isAuthenticated: action.user ? !!Object.keys(action.user).length : false,
        isLoading: false,
        user: action.user,
      };
    case 'UPDATE_TOKEN':
      // requires token param
      return {
        ...state,
        user: {
          ...state.user,
          token: action.token,
        },
      };
    default:
      return state;
  }
}

const AuthContext = React.createContext();
const AuthFunctionsContext = React.createContext();

export const useAuth = () => useContext(AuthContext);
export const useAuthFunctions = () => useContext(AuthFunctionsContext);

export function AuthProvider({ children }) {
  const [state, dispatch] = useReducer(reducer, initialState);

  useEffect(() => {
    // Get initial auth state from local storage.
    const authState = localStorage.getItem('auth');
    const localAuthState = authState
      ? JSON.parse(authState)
      : { ...initialState, isLoading: false };
    dispatch({ type: 'SET_CURRENT_USER', user: localAuthState.user });
  }, []);

  useEffect(() => {
    // Whenever auth state is updated it's saved to localstorage.
    localStorage.setItem('auth', JSON.stringify(state));
  }, [state]);

  // All functions that can be used to edit the auth state.
  const authFunctions = {
    authUser: async credential => {
      const response = await login(credential);
      const user = {
        credential,
        ...response.user,
      };

      dispatch({
        type: 'SET_CURRENT_USER',
        user,
      });
    },
    updateToken: token => {
      dispatch({
        type: 'UPDATE_TOKEN',
        token,
      });
    },
    logout: () => {
      localStorage.removeItem('auth');
      dispatch({
        type: 'SET_CURRENT_USER',
        user: {},
      });
    },
  };

  return (
    <AuthContext.Provider value={state}>
      <AuthFunctionsContext.Provider value={authFunctions}>
        {children}
      </AuthFunctionsContext.Provider>
    </AuthContext.Provider>
  );
}
