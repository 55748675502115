/**
 * Checks to see if any of the user's assigned roles match a list of roles.
 * @param {[string]} userRoles User's assigned roles
 * @param {[string]} acceptedRoles List of roles user must be a part of
 */
function verifyRoles(userRoles, acceptedRoles) {
  return userRoles.some(r => acceptedRoles.includes(r));
}

module.exports = {
  verifyRoles,
};
