import React from 'react';
import { Link } from 'react-router-dom';
import { Menu, Dropdown, Image, Icon } from 'semantic-ui-react';
import instatLogo from '../media/instat-logo-light.png';
import AppsIcon from '../components/icons/AppsIcon';
import { verifyRoles } from '../utils/ui';
import { appLinks } from '../data/app-links';
import { appLinkSections } from '../data/app-link-sections';
import { sortObject } from '../utils/array-sorting';
import { useAuth, useAuthFunctions } from '../context/AuthContext';
import { googleLogout } from '@react-oauth/google';

export const Navbar = () => {
  const auth = useAuth();
  const { logout } = useAuthFunctions();

  const { isAuthenticated, user } = auth;

  const userAppLinks =
    user && user.roles
      ? appLinks.filter(appLink =>
          appLink.roles.length === 0 ? appLink : verifyRoles(user.roles, appLink.roles)
        )
      : [];

  const userAppLinkGroups = userAppLinks.reduce((acc, link) => {
    const { section } = link;
    if (section in acc) {
      acc[section].push(link);
      return acc;
    } else {
      return {
        ...acc,
        [section]: [link],
      };
    }
  }, {});
  for (const section in userAppLinkGroups) {
    userAppLinkGroups[section].sort((a, b) => sortObject(a, b, 'name'));
  }

  /**
   * Logs out user and clears auth context.
   */
  const handleLogout = () => {
    googleLogout();
    logout();
  };

  return (
    <React.Fragment>
      {isAuthenticated && (
        <Menu
          attached={'top'}
          style={{
            border: 'none',
            borderBottom: '1px solid #d4d4d5',
            marginBottom: '20px',
            zIndex: '1000',
          }}
          inverted
        >
          <Menu.Item name={'home'} style={{ padding: '0 16px' }}>
            <img src={instatLogo} alt={'instat logo'} style={{ width: '50px' }} />
          </Menu.Item>
          <Menu.Item as={Link} to={'/'}>
            <Icon name={'home'} size={'large'} style={{ marginRight: '0' }} />
          </Menu.Item>
          <Dropdown
            item
            simple
            trigger={
              <span>{<AppsIcon fillColor={'#fff'} width={'18'} height={'18'} /> || 'Apps'}</span>
            }
            icon={null}
            style={{ paddingTop: '0', paddingBottom: '0' }}
          >
            <Dropdown.Menu>
              {/* Only show links available to user's current roles */}
              {userAppLinks.length > 0 ? (
                Object.keys(userAppLinkGroups).map((section, sectionIndex, groupsArr) => {
                  const isLast = sectionIndex === groupsArr.length - 1;

                  return (
                    <React.Fragment key={section}>
                      <Dropdown.Header
                        icon={
                          appLinkSections[section] ? appLinkSections[section] : 'dot circle outline'
                        }
                        content={section === '' ? 'Miscellaneous' : section}
                      />
                      {userAppLinkGroups[section].map((appLink, index) => (
                        <Dropdown.Item key={`app-link-${index}`} as={Link} to={appLink.link}>
                          {appLink.name}
                        </Dropdown.Item>
                      ))}
                      {!isLast && <Dropdown.Divider />}
                    </React.Fragment>
                  );
                })
              ) : (
                <Dropdown.Item disabled>No apps available</Dropdown.Item>
              )}
            </Dropdown.Menu>
          </Dropdown>
          <Menu.Menu position={'right'}>
            <Dropdown
              item
              trigger={
                <span>
                  <Image avatar src={user.photoUrl} /> {user.firstName}
                </span>
              }
            >
              <Dropdown.Menu>
                <Dropdown.Item onClick={handleLogout}>Logout</Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </Menu.Menu>
        </Menu>
      )}
    </React.Fragment>
  );
};

export default Navbar;
