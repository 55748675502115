import React from 'react';
import { Redirect, useLocation } from 'react-router-dom';
import PrivateRoute from './PrivateRoute';
import { useAuth } from '../context/AuthContext';
import LoadingPage from './LoadingPage';

export const PermissionsLockedRoute = ({ roles, ...props }) => {
  const location = useLocation();
  const { isAuthenticated, isLoading, user } = useAuth();

  // Checks to see if any of the user's roles are in the given list of roles to allow access to route
  // This component also checks for user authentication
  const hasPermission =
    user && user.roles && roles && user.roles.filter(r => roles.includes(r)).length > 0;

  if (isLoading) {
    // Checking if loading state saved to localStorage is essential.
    // If this loading page isn't rendered while it checks, the
    // PermissionsLockedRoute will fail to wait to pick up cached
    // auth data and redirect to login every time.
    return <LoadingPage />;
  }
  if (hasPermission) {
    // Authentication will be checked in PrivateRoute component
    return <PrivateRoute {...props} />;
  } else if (!hasPermission && isAuthenticated) {
    // User not allowed to access page, but they are authenticated -> send home
    return <Redirect to={{ pathname: '/', state: { from: location } }} />;
  } else {
    // User not allowed to access page and is not authenticated -> login
    return <Redirect to={{ pathname: '/login', state: { from: location } }} />;
  }
};

export default PermissionsLockedRoute;
