import React, { useEffect, useReducer } from 'react';
import PropTypes from 'prop-types';
import ReactRouterPropTypes from 'react-router-prop-types';
import { useToasts } from 'react-toast-notifications';
import {
  Button,
  Container,
  Dropdown,
  Header,
  Form,
  Segment,
  Icon,
  Dimmer,
  Loader,
} from 'semantic-ui-react';
import { Formik } from 'formik';
import reducer from './state/reducer';
import initialState from './state/initialState';
import { fetchProgrammingTasksReportData } from '../../services/api/reports/programming-tasks-report';
import FilterOptionsForm from './components/FilterOptionsForm';
import ReportTableView from './components/ReportTableView';
import { generateCSV } from './utils/generate-csv';
import { openInSheets } from '../../services/googlesheets/open-in-sheets';
import { getTableHeaderData } from './utils/table-mapping';
import { generateGoogleSheetsData } from './utils/generate-google-sheets-data';
import CalculationsHelpModal from '../../components/CalculationsHelpModal';
import calculationExplanations from './data/calculation-explanations';
import { useSocket } from '../../context/SocketContext';
import { useAuth, useAuthFunctions } from '../../context/AuthContext';

function ProgrammingTasksReport({ history }) {
  document.title = 'Instat Apps - Programming Tasks Report';
  const { addToast } = useToasts();
  const { user } = useAuth();
  const { token, email } = user;
  const { logout } = useAuthFunctions();
  const socket = useSocket();
  const [state, dispatch] = useReducer(reducer, initialState);

  /**
   * Alters the loader state. Both the status and message can be edited.
   * @param {boolean} loadingStatus Whether or not loading is happening.
   * @param {string} loadingMessage Message to present to the user in the loader.
   */
  async function changeLoadingStatus(loadingStatus, loadingMessage) {
    dispatch({
      type: 'SET_LOADING_STATUS_AND_MESSAGE',
      loadingStatus,
      loadingMessage,
    });
  }

  /**
   * Emits a message to socket.io to initiate a data cache refresh.
   *
   * *NOTE*: The loading status is removed below in the websocket handler.
   */
  async function handleDataRefresh() {
    changeLoadingStatus(true, 'Refreshing data cache'); // removed later
    socket.emit('programming-tasks-report:refresh');
  }

  async function fetchAndSetReportDataCache(token) {
    const response = await fetchProgrammingTasksReportData(token);
    return response;
  }

  /**
   * Generates a report based on the filters given by the user. State is changed to reflect
   * the report's data.
   * @param {object} filters Filters used to generate report (AL, PL, etc.).
   */
  async function generateReport(filters = {}) {
    dispatch({ type: 'GENERATE_REPORT', filters });
  }

  /**
   * Creates a CSV file and downloads it in the user's browser.
   */
  function handleDownloadCSV() {
    // TODO: if no table data, warn user

    changeLoadingStatus(true, 'Creating CSV file for export');

    const csv = generateCSV(state.tableRows);

    const csvDownloadElement = document.createElement('a');
    const file = new Blob([csv], { type: 'text/csv' });
    csvDownloadElement.href = URL.createObjectURL(file);
    csvDownloadElement.download = `Programming Tasks Report.csv`;
    csvDownloadElement.click();

    changeLoadingStatus(false);
  }

  /**
   * Creates a new Google Sheets spreadsheet with the report data.
   * The user is automatically redirected to this report.
   */
  function handleOpenInSheets() {
    changeLoadingStatus(true, 'Exporting to Google Sheets');

    const todayDate = new Date().toISOString().slice(0, 10);

    // Prepare data for upload
    const headers = getTableHeaderData();
    const rows = generateGoogleSheetsData(state.tableRows);

    openInSheets(token, {
      title: `Programming Tasks Report ${todayDate}`,
      values: [headers, ...rows],
      userEmail: email,
    })
      .then(async res => {
        // TODO: format spreadsheet?

        const newTab = window.open(
          `https://docs.google.com/spreadsheets/d/${res.spreadsheetId}`,
          '_blank',
          'noopener,noreferrer'
        );
        if (newTab) newTab.opener = null;
      })
      .then(() => {
        addToast('Data exported as a new spreadsheet to your Google Drive.', {
          appearance: 'success',
        });
        changeLoadingStatus(false);
      })
      .catch(err => {
        console.error(err);
        addToast(err.message, { appearance: 'error', autoDismiss: true });
      });
  }

  function handleTableSorting(headerName) {
    console.log(`time to sort by ${headerName}!`);
    dispatch({ type: 'CHANGE_TABLE_SORT', column: headerName });
  }

  /**
   * Websockets (socket.io)
   *
   * Socket is pulled from context.
   */
  useEffect(() => {
    socket.on('connect', () => {
      // console.log('connected to websocket!');
    });
    socket.on('programming-tasks-report:refresh', res => {
      changeLoadingStatus(false);
    });
    socket.on('programming-tasks-report:refresh-update', updateMessage => {
      // Update the UI with the message passed back. These are used to tell the user what's going on here.
      changeLoadingStatus(true, updateMessage);
    });
  }, []);

  /**
   * Get report data from server.
   * Also checks to make sure user is logged in.
   */
  useEffect(() => {
    changeLoadingStatus(true, 'Downloading data cache');
    fetchAndSetReportDataCache(token).then(res => {
      if (res.status === 401) {
        // Notify user their session has expired, present toast notification,
        // and redirect to login.
        addToast('Your session has expired. Please sign in again.', {
          appearance: 'error',
          autoDismiss: true,
        });
        logout();
        history.push('/login');
      } else if (res.status !== 200) {
        // Catch all other error codes and notify user of error in toast notification.
        // User is not logged out.
        addToast(res.message, { appearance: 'error', autoDismiss: true });
      } else {
        // Success!
        dispatch({ type: 'SET_REPORT_DATA_CACHE', cache: res.data });
      }

      changeLoadingStatus(false);
    });
  }, [history, logout, token]);

  /**
   * Refresh table when data cache is refreshed. The refreshed table takes
   * into account any currently selected filter options.
   */
  useEffect(() => {
    // TODO: Refresh report when data cache is refreshed.
  }, [state.cache]);

  return (
    <React.Fragment>
      <Container style={{ margin: '30px 0', display: 'flex', flexDirection: 'column' }}>
        <Dimmer active={state.loading.isActive} inverted>
          <Loader inverted>{state.loading.message}</Loader>
        </Dimmer>
        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
          <Header as="h1">
            <Header.Content>Programming Tasks Report</Header.Content>
            <Header.Subheader>
              Data Last Updated: {new Date(state.cache.dateModified).toLocaleString()}{' '}
            </Header.Subheader>
          </Header>
          <div>
            <CalculationsHelpModal calculationExplanations={calculationExplanations} />
          </div>
        </div>
        <Segment>
          <Formik
            enableReinitialize
            initialValues={{
              clients: [],
              accountLeads: [],
              projectLeads: [],
            }}
            onSubmit={values => {
              generateReport(values);
            }}
          >
            {({ values, handleSubmit, handleReset, setFieldValue, errors, touched }) => (
              <Form onSubmit={handleSubmit} className="attached">
                <FilterOptionsForm
                  formProps={{ values, setFieldValue, errors, touched }}
                  handleReset={handleReset}
                  cache={state.cache}
                  isLoading={state.loading.isActive}
                />
                <div
                  style={{
                    marginTop: '30px',
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                  }}
                >
                  <div>
                    <Button
                      type="submit"
                      primary
                      icon
                      labelPosition="right"
                      disabled={state.loading.isActive}
                    >
                      Generate Report
                      <Icon name="line graph" />
                    </Button>
                    <Button
                      onClick={handleDataRefresh}
                      type="button"
                      icon
                      labelPosition="right"
                      disabled={state.loading.isActive}
                    >
                      Refresh Report Data
                      <Icon name="refresh" />
                    </Button>
                  </div>
                  <div>
                    <Dropdown
                      text="Export"
                      icon="download"
                      floating
                      labeled
                      button
                      secondary="true"
                      className="button secondary icon"
                    >
                      <Dropdown.Menu>
                        <Dropdown.Item onClick={handleDownloadCSV}>
                          <Icon name="file excel outline" /> CSV
                        </Dropdown.Item>
                        <Dropdown.Item onClick={handleOpenInSheets}>
                          <Icon name="google drive" />
                          Google Sheets
                        </Dropdown.Item>
                      </Dropdown.Menu>
                    </Dropdown>
                  </div>
                </div>
              </Form>
            )}
          </Formik>
        </Segment>
      </Container>
      <br />
      <ReportTableView
        tableRows={state.tableRows}
        tableSorting={state.tableSorting}
        handleTableSorting={handleTableSorting}
      />
    </React.Fragment>
  );
}

ProgrammingTasksReport.propTypes = {
  history: ReactRouterPropTypes.history.isRequired,
};

export default ProgrammingTasksReport;
