import React from 'react';

const calculationExplanations = [
  {
    Column: 'Days from Start to Fully Signed',
    'Calculation Explanation': (
      <div>
        <p>Start date to when column changed to "Fully Signed".</p>
      </div>
    ),
  },
  {
    Column: 'Days from Start to Process Completed',
    'Calculation Explanation': (
      <div>
        <p>Start date to completed date.</p>
      </div>
    ),
  },
];

export default calculationExplanations;
