import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { useTable } from 'react-table';
import { Table } from 'semantic-ui-react';
import columnDefinitions from '../data/column-definitions';

function DealInfoTable({ dealTableData }) {
  const columns = useMemo(columnDefinitions, [columnDefinitions]);
  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = useTable({
    columns: columns,
    data: dealTableData,
  });

  return (
    <React.Fragment>
      {dealTableData.length === 0 ? (
        <div />
      ) : (
        <div style={{ overflowX: 'auto', padding: '10px 0 2px 0' }}>
          <Table celled compact unstackable selectable {...getTableProps()}>
            <Table.Header>
              {headerGroups.map(headerGroup => (
                <Table.Row {...headerGroup.getHeaderGroupProps()}>
                  {headerGroup.headers.map(column => {
                    return (
                      <Table.HeaderCell {...column.getHeaderProps()}>
                        {column.render('Header')}
                      </Table.HeaderCell>
                    );
                  })}
                </Table.Row>
              ))}
            </Table.Header>
            <Table.Body {...getTableBodyProps()}>
              {rows.map((row, i) => {
                prepareRow(row);

                return (
                  <Table.Row {...row.getRowProps()}>
                    {row.cells.map(cell => (
                      <Table.Cell {...cell.getCellProps()}>{cell.render('Cell')}</Table.Cell>
                    ))}
                  </Table.Row>
                );
              })}
            </Table.Body>
          </Table>
        </div>
      )}
    </React.Fragment>
  );
}

DealInfoTable.propTypes = {
  dealTableData: PropTypes.arrayOf(
    PropTypes.shape({
      dealCompany: PropTypes.string,
      dealTitle: PropTypes.string,
      amount: PropTypes.string,
      startDate: PropTypes.string,
      stopDate: PropTypes.string,
      dealStage: PropTypes.string,
      dealProbability: PropTypes.string,
    })
  ).isRequired,
};

export default DealInfoTable;
