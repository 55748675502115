/**
 * Returns a list of header strings as they should appear in the table UI.
 * @returns Array of header strings.
 */
export function getTableHeaderData() {
  return [
    'Client',
    'Project',
    'Project Lead',
    'Programming Lead US',
    'Programming Lead IN',
    'Task Tag',
    'Task',
    'Start Date',
    'Due Date',
    'Tasks Complete',
    'Tasks Remaining',
    'Progress of Remaining Tasks',
    'Milestone',
    'Milestone Date',
    'Milestone Tag',
    'Programmer/Statistician',
    'Priority',
    'Update/Comments',
  ];
}
