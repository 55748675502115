import React, { useContext, useState } from 'react';
import io from 'socket.io-client';
import { useToasts } from 'react-toast-notifications';
import { useAuth, useAuthFunctions } from './AuthContext';

const SocketContext = React.createContext();

/**
 * Returns the correct URL for connecting to the socket.io server based on node environment.
 * If development, localhost and port is returned.
 * @returns URL string
 */
function getSocketAddress() {
  if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
    // dev
    return 'localhost:8081';
  }
  return 'https://apps.instat.com';
}

export function useSocket() {
  return useContext(SocketContext);
}

/**
 * *NOTE:* The SocketProvider **must** be rendered after the AuthProvider, because
 * it relies on the authentication state to determine whether or not to make the connection.
 */
export function SocketProvider({ history, children }) {
  const { isAuthenticated, isLoading, user } = useAuth();
  const { logout } = useAuthFunctions();
  const { addToast } = useToasts();
  const [hasConnectionError, setHasConnectionError] = useState(false);

  let socket;
  if (!isLoading && isAuthenticated) {
    const socketAddress = getSocketAddress();
    socket = io(socketAddress, { auth: { token: user.token } });

    socket.on('connect', socket => {
      console.log('[socket.io] Connected to server.');
      setHasConnectionError(false);
    });
    socket.on('connect_error', err => {
      console.error(`[socket.io connect_error] ${err.message}`);
      socket.disconnect();
      if (err.message === 'Authentication is required.' && !hasConnectionError) {
        // If it can't connect it's because the token is not valid. The user is
        // logged out and redirected to the login page.
        setHasConnectionError(true);
        addToast('Could not authenticate with server. Please try logging in again.', {
          appearance: 'error',
        });
        logout();
        window.location.pathname = '/login';
      }
    });
  }

  return <SocketContext.Provider value={socket}>{children}</SocketContext.Provider>;
}
