import { sortObject } from '../../../../utils/array-sorting';
import { camelToTitleCase } from '../../../../utils/string-transformations';
import columnDefinitions from '../../data/column-definitions';

export default function setReportData(state, report) {
  const { data, missingRates } = report;
  // const tableData = [
  //   [
  //     'Category',
  //     'Resource',
  //     'Team Member',
  //     '10kft Rate',
  //     'Breakeven Rate',
  //     'Budget Hours',
  //     'Incurred Hours',
  //     'Scheduled Hours',
  //     'Hours Remaining',
  //     'Budget',
  //     'Incurred Cost',
  //     'Scheduled Cost',
  //     'Breakeven Cost',
  //     'Scheduled Breakeven Cost',
  //     '[Budget Remaining]',
  //     '[Profit]',
  //   ],
  // ];

  // Take report data and format as table data--arrays of column values
  const tableData = [];
  for (const category in data) {
    const categorySection = { ...data[category], subRows: [] };
    const resources = data[category].subRows;

    for (const resource in resources) {
      const teamMembers = Object.values(resources[resource].subRows);
      teamMembers.sort((a, b) => sortObject(a, b, 'teamMember'));

      categorySection.subRows.push({ ...resources[resource], subRows: teamMembers });
    }

    tableData.push(categorySection);
  }
  // Sort table data by category number
  for (const category of tableData) {
    category.subRows.sort((a, b) => sortObject(a, b, 'resource'));
  }
  tableData.sort((a, b) => sortObject(a, b, 'category'));

  // Add total row to bottom
  let totalRow = {};
  for (const category in data) {
    const categoryRow = data[category];
    if (Object.keys(totalRow).length === 0) {
      totalRow = { ...categoryRow };
      totalRow.category = 'TOTAL';
      if (totalRow.subRows) delete totalRow.subRows;
    } else {
      totalRow = {
        ...totalRow,
        incurredHours: totalRow.incurredHours + categoryRow.incurredHours,
        scheduledHours: totalRow.scheduledHours + categoryRow.scheduledHours,
        hoursRemaining: totalRow.hoursRemaining + categoryRow.hoursRemaining,
        budget: totalRow.budget + categoryRow.budget,
        incurredCost: totalRow.incurredCost + categoryRow.incurredCost,
        scheduledCost: totalRow.scheduledCost + categoryRow.scheduledCost,
        breakevenCost: totalRow.breakevenCost + categoryRow.breakevenCost,
        scheduledBreakevenCost:
          totalRow.scheduledBreakevenCost + categoryRow.scheduledBreakevenCost,
        budgetRemaining: totalRow.budgetRemaining + categoryRow.budgetRemaining,
        profit: totalRow.profit + categoryRow.profit,
      };
    }
  }
  tableData.push(totalRow);

  // Get column letters and indices for report
  const dataForColumnLetters = { ...data };
  const categoryObj = { ...Object.values(dataForColumnLetters)[0] };
  if ('subRows' in categoryObj) delete categoryObj.subRows;
  const tableHeaders = columnDefinitions().map(c => c.accessor);
  const columns = tableHeaders.reduce(
    (acc, header, hIndex) => ({
      ...acc,
      [header]: {
        index: hIndex,
        letter: getColumnLetter(tableHeaders, header),
        key: header,
        name: camelToTitleCase(header),
      },
    }),
    {}
  );

  // Generate CSV string
  /**
   * Recursively generates CSV-formatted string.
   * @param {[object]} rows Array of row objects
   * @param {string} str Current CSV string value
   * @returns
   */
  function getCSV(rows, str) {
    // Add headers
    if (str === '') {
      str += `${tableHeaders.map(h => `"${camelToTitleCase(h)}"`).join(',')}\n`;
    }

    for (const row of rows) {
      const rowPartial = { ...row };
      delete rowPartial.subRows;
      if (row.subRows && row.subRows.length > 0) {
        str += `${tableHeaders.map(header => `"${rowPartial[header]}"`).join(',')}\n`;
        const subRowsStr = getCSV(row.subRows, str);
        str = subRowsStr;
      } else {
        str += `${tableHeaders.map(header => `"${rowPartial[header]}"`).join(',')}\n`;
      }
    }

    return str;
  }

  // Generate Google Sheets values
  /**
   * Takes an array of headers and returns the column letter for the specified header string.
   * @param {[String]} tableHeaders Array of headers of a sheet
   * @param {String} header Header for which you want column letter
   * @returns String of letters representing the header's column
   */
  function getColumnLetter(tableHeaders, header) {
    let columnNumber = tableHeaders.indexOf(header) + 1;
    if (columnNumber === -1) throw new Error('Could not find column number from given header.');

    let temp,
      letter = '';

    while (columnNumber > 0) {
      temp = (columnNumber - 1) % 26;
      letter = String.fromCharCode(temp + 65) + letter;
      columnNumber = (columnNumber - temp - 1) / 26;
    }
    return letter;
  }

  /**
   * Takes the report row values and formats them for upload to a Google Sheet.
   * Cells that are formulas are also calculated.
   * @returns [[string]] formatted values for uploading to a new Google Sheet
   */
  function generateGoogleSheetsValues() {
    const reportData = { ...data };

    const reportDataSorted = [...tableData.slice(0, tableData.length - 1)];
    const gsValues = [tableHeaders.map(key => columns[key].name)]; // adds header row

    // Generate total row template (values calculated from arrays later)
    const totalValues = ['TOTAL', '', '', '', '', [], [], [], [], [], [], [], [], [], [], []];

    // Process categories, resources, and team members
    let rowCount = 1;
    for (const category of reportDataSorted) {
      const categoryRowNum = rowCount + 1;
      rowCount += 1; // increment for category row

      // Add to total row summations
      totalValues[columns.budgetHours.index].push(categoryRowNum);
      totalValues[columns.incurredHours.index].push(categoryRowNum);
      totalValues[columns.scheduledHours.index].push(categoryRowNum);
      totalValues[columns.hoursRemaining.index].push(categoryRowNum);
      totalValues[columns.budget.index].push(categoryRowNum);
      totalValues[columns.incurredCost.index].push(categoryRowNum);
      totalValues[columns.scheduledCost.index].push(categoryRowNum);
      totalValues[columns.breakevenCost.index].push(categoryRowNum);
      totalValues[columns.scheduledBreakevenCost.index].push(categoryRowNum);

      // Generate category row
      const categoryData = { ...category };
      const categorySubRows = categoryData.subRows ? categoryData.subRows : null;
      delete categoryData.subRows;

      // Create arrays for calculated cells
      const categoryValues = Object.values(categoryData);
      categoryValues[columns['10kftRate'].index] = [];
      categoryValues[columns.breakevenRate.index] = [];
      categoryValues[columns.incurredHours.index] = [];
      categoryValues[columns.scheduledHours.index] = [];
      categoryValues[columns.hoursRemaining.index] = [];
      categoryValues[columns.incurredCost.index] = [];
      categoryValues[columns.scheduledCost.index] = [];
      categoryValues[columns.breakevenCost.index] = [];
      categoryValues[columns.scheduledBreakevenCost.index] = [];

      const categorySubRowsArr = [];
      if (categorySubRows) {
        for (const resource of categorySubRows) {
          const resourceRowNum = rowCount + 1;
          rowCount += 1; // increment for resource row

          const resourceData = { ...resource };
          const resourceSubRows = resourceData.subRows ? resourceData.subRows : null;
          delete resourceData.subRows;

          // Increment category's summation rows
          categoryValues[columns.incurredHours.index].push(rowCount);
          categoryValues[columns.scheduledHours.index].push(rowCount);
          categoryValues[columns.hoursRemaining.index].push(rowCount);
          categoryValues[columns.incurredCost.index].push(rowCount);
          categoryValues[columns.scheduledCost.index].push(rowCount);
          categoryValues[columns.breakevenCost.index].push(rowCount);
          categoryValues[columns.scheduledBreakevenCost.index].push(rowCount);

          // Create arrays for calculated cells
          // const resourceValues = Object.values(resourceData);
          const resourceValues = tableHeaders.map(header => resourceData[header]);
          resourceValues[columns['10kftRate'].index] = [];
          resourceValues[columns.breakevenRate.index] = [];
          resourceValues[columns.incurredHours.index] = [];
          resourceValues[columns.scheduledHours.index] = [];
          resourceValues[columns.hoursRemaining.index] = [];
          resourceValues[columns.incurredCost.index] = [];
          resourceValues[columns.scheduledCost.index] = [];
          resourceValues[columns.breakevenCost.index] = [];
          resourceValues[columns.scheduledBreakevenCost.index] = [];

          const resourceSubRowsArr = [];
          for (const teamMember of resourceSubRows) {
            const teamMemberRowNum = rowCount + 1;
            rowCount += 1; // increment for team member row

            const teamMemberCopy = { ...teamMember };
            const teamMemberValues = tableHeaders.map(header => teamMemberCopy[header]);

            // Increment category's summation rows
            categoryValues[columns['10kftRate'].index].push(teamMemberRowNum);
            categoryValues[columns.breakevenRate.index].push(teamMemberRowNum);

            // Increment resource's summation rows
            resourceValues[columns['10kftRate'].index].push(teamMemberRowNum);
            resourceValues[columns.breakevenRate.index].push(teamMemberRowNum);
            resourceValues[columns.incurredHours.index].push(teamMemberRowNum);
            resourceValues[columns.scheduledHours.index].push(teamMemberRowNum);
            resourceValues[columns.hoursRemaining.index].push(teamMemberRowNum);
            resourceValues[columns.incurredCost.index].push(teamMemberRowNum);
            resourceValues[columns.scheduledCost.index].push(teamMemberRowNum);
            resourceValues[columns.breakevenCost.index].push(teamMemberRowNum);
            resourceValues[columns.scheduledBreakevenCost.index].push(teamMemberRowNum);

            // Create formulas for team member cells
            teamMemberValues[
              columns.incurredCost.index
            ] = `=${columns['10kftRate'].letter}${teamMemberRowNum}*${columns.incurredHours.letter}${teamMemberRowNum}`;
            teamMemberValues[
              columns.scheduledCost.index
            ] = `=${columns['10kftRate'].letter}${teamMemberRowNum}*${columns.scheduledHours.letter}${teamMemberRowNum}`;
            teamMemberValues[
              columns.breakevenCost.index
            ] = `=${columns.breakevenRate.letter}${teamMemberRowNum}*${columns.incurredHours.letter}${teamMemberRowNum}`;
            teamMemberValues[
              columns.scheduledBreakevenCost.index
            ] = `=${columns.breakevenRate.letter}${teamMemberRowNum}*${columns.scheduledHours.letter}${teamMemberRowNum}`;

            resourceSubRowsArr.push(teamMemberValues);
          }

          // Create formulas for summation cells (resource)
          const tenkftRateColumnInfo = columns['10kftRate'];
          const breakevenRateColumnInfo = columns.breakevenRate;
          const incurredHoursColumnInfo = columns.incurredHours;

          for (const [cIndex, column] of resourceValues.entries()) {
            if (cIndex === tenkftRateColumnInfo.index) {
              // 10kft Rate weighted average
              const weightedNumerator = `SUM(${column
                .map(
                  rowNum =>
                    `${tenkftRateColumnInfo.letter}${rowNum}*${incurredHoursColumnInfo.letter}${rowNum}`
                )
                .join(',')})`;
              const averageNumerator = `SUM(${column
                .map(rowNum => `${tenkftRateColumnInfo.letter}${rowNum}`)
                .join(',')})`;
              const denominator = `SUM(${column.map(
                rowNum => `${incurredHoursColumnInfo.letter}${rowNum}`
              )})`;

              resourceValues[
                cIndex
              ] = `=IF(${denominator}>0,${weightedNumerator}/${denominator},${averageNumerator}/${column.length})`;
            } else if (cIndex === breakevenRateColumnInfo.index) {
              // Breakeven Rate weighted average
              const weightedNumerator = `SUM(${column
                .map(
                  rowNum =>
                    `${breakevenRateColumnInfo.letter}${rowNum}*${incurredHoursColumnInfo.letter}${rowNum}`
                )
                .join(',')})`;
              const averageNumerator = `SUM(${column
                .map(rowNum => `${tenkftRateColumnInfo.letter}${rowNum}`)
                .join(',')})`;
              const denominator = `SUM(${column.map(
                rowNum => `${incurredHoursColumnInfo.letter}${rowNum}`
              )})`;

              resourceValues[
                cIndex
              ] = `=IF(${denominator}>0,${weightedNumerator}/${denominator},${averageNumerator}/${column.length})`;
            } else if (Array.isArray(column)) {
              const columnInfo = Object.values(columns).find(c => c.index === cIndex);
              resourceValues[cIndex] = `=SUM(${column
                .map(val => `${columnInfo.letter}${val}`)
                .join(',')})`;
            }
          }

          categorySubRowsArr.push(resourceValues);
          categorySubRowsArr.push(...resourceSubRowsArr);
        }
      }

      // Create formulas for summation cells (category)
      const tenkftRateColumnInfo = columns['10kftRate'];
      const breakevenRateColumnInfo = columns.breakevenRate;
      const incurredHoursColumnInfo = columns.incurredHours;

      for (const [cIndex, column] of categoryValues.entries()) {
        if (cIndex === columns.budgetRemaining.index) {
          categoryValues[
            cIndex
          ] = `=MINUS(${columns.budget.letter}${categoryRowNum},${columns.incurredCost.letter}${categoryRowNum})`;
        } else if (cIndex === columns.profit.index) {
          categoryValues[
            cIndex
          ] = `=MINUS(${columns.budget.letter}${categoryRowNum},${columns.breakevenCost.letter}${categoryRowNum})`;
        } else if (cIndex === tenkftRateColumnInfo.index) {
          if (column.length === 0) {
            // For categories with no time entries (but are budgeted)
            categoryValues[cIndex] = '';
            continue;
          }

          // 10kft Rate weighted average
          const weightedNumerator = `SUM(${column
            .map(
              rowNum =>
                `${tenkftRateColumnInfo.letter}${rowNum}*${incurredHoursColumnInfo.letter}${rowNum}`
            )
            .join(',')})`;
          const averageNumerator = `SUM(${column
            .map(rowNum => `${tenkftRateColumnInfo.letter}${rowNum}`)
            .join(',')})`;
          const denominator = `SUM(${column.map(
            rowNum => `${incurredHoursColumnInfo.letter}${rowNum}`
          )})`;

          categoryValues[
            cIndex
          ] = `=IF(${denominator}>0,${weightedNumerator}/${denominator},${averageNumerator}/${column.length})`;
        } else if (cIndex === breakevenRateColumnInfo.index) {
          if (column.length === 0) {
            // For categories with no time entries (but are budgeted)
            categoryValues[cIndex] = '';
            continue;
          }

          // Breakeven Rate weighted average
          const weightedNumerator = `SUM(${column
            .map(
              rowNum =>
                `${breakevenRateColumnInfo.letter}${rowNum}*${incurredHoursColumnInfo.letter}${rowNum}`
            )
            .join(',')})`;
          const averageNumerator = `SUM(${column
            .map(rowNum => `${tenkftRateColumnInfo.letter}${rowNum}`)
            .join(',')})`;
          const denominator = `SUM(${column.map(
            rowNum => `${incurredHoursColumnInfo.letter}${rowNum}`
          )})`;

          categoryValues[
            cIndex
          ] = `=IF(${denominator}>0,${weightedNumerator}/${denominator},${averageNumerator}/${column.length})`;
        } else if (Array.isArray(column)) {
          if (column.length === 0) {
            // For categories with no time entries (but are budgeted)
            categoryValues[cIndex] = 0;
            continue;
          }

          const columnInfo = Object.values(columns).find(c => c.index === cIndex);
          categoryValues[cIndex] = `=SUM(${column
            .map(val => `${columnInfo.letter}${val}`)
            .join(',')})`;
        }
      }

      gsValues.push(categoryValues);
      gsValues.push(...categorySubRowsArr); // append all subrows for category
    }

    // Create formulas for TOTAL row
    for (const [cIndex, column] of totalValues.entries()) {
      if (cIndex === columns.budgetRemaining.index) {
        totalValues[cIndex] = `=MINUS(${columns.budget.letter}${rowCount + 1},${
          columns.incurredCost.letter
        }${rowCount + 1})`;
      } else if (cIndex === columns.profit.index) {
        totalValues[cIndex] = `=MINUS(${columns.budget.letter}${rowCount + 1},${
          columns.breakevenCost.letter
        }${rowCount + 1})`;
      } else if (Array.isArray(column)) {
        const columnInfo = Object.values(columns).find(c => c.index === cIndex);
        totalValues[cIndex] = `=SUM(${column.map(val => `${columnInfo.letter}${val}`).join(',')})`;
      }
    }

    gsValues.push(totalValues);

    return gsValues;
  }

  /**
   * Missing Rates CSV Generation
   */
  const missingRatesCsvString =
    missingRates.length > 0
      ? `${Object.keys(missingRates[0])
          .map(header => `"${header}"`)
          .join(',')}\n` +
        missingRates
          .map(row =>
            Object.values(row)
              .map(val => `"${val}"`)
              .join(',')
          )
          .join('\n')
      : '';

  return {
    ...state,
    report: {
      data: report,
      missingRates: {
        values: missingRates,
        csvBlob: new Blob([missingRatesCsvString], { type: 'text/csv' }),
      },
      tableData,
      csv: tableData.length > 0 ? getCSV(tableData, '') : '',
      googleSheets: {
        values: tableData.length > 0 ? generateGoogleSheetsValues() : [],
        columns,
      },
    },
  };
}
