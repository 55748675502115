import axios from 'axios';

/**
 * Gets the full Pipeline Projection Expected Revenue By Year report.
 * @param {string} token Instat Apps API token.
 * @param {object} params Any parameters for the GET request.
 * @returns Object containing status code, message, and data.
 */
export async function getPipelineProjectionExpectedRevenueByYear(token, params = {}) {
  const request = {
    url: `api/reports/pipeline-projection-expected-revenue`,
    method: 'get',
    headers: {
      Authorization: `Bearer ${token}`,
    },
    params,
  };

  try {
    const response = await axios(request);
    return {
      status: 200,
      message: '"Pipeline Projection Expected Revenue By Year" report data received.',
      data: response.data,
    };
  } catch (error) {
    const statusCode = Number(error.message.slice(error.message.length - 3));
    return { status: statusCode, message: error.message, data: {} };
  }
}
