import React from 'react';
import PropTypes from 'prop-types';

const CheckboxInput = ({ field: { name, value, onChange, onBlur } }) => (
  <input
    name={name}
    type={'checkbox'}
    value={value}
    checked={value}
    onChange={onChange}
    onBlur={onBlur}
  />
);

CheckboxInput.propTypes = {
  field: PropTypes.shape({
    name: PropTypes.string.isRequired,
    value: PropTypes.bool.isRequired,
    onChange: PropTypes.func.isRequired,
    onBlur: PropTypes.func.isRequired,
  }),
};

export default CheckboxInput;
