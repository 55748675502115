import axios from 'axios';

export async function getCostAnalysisReport(token, projectId) {
  const request = {
    url: `/api/reports/cost-analysis`,
    method: 'get',
    headers: {
      Authorization: `Bearer ${token}`,
    },
    params: {
      projectId,
    },
  };

  try {
    const response = await axios(request);
    return { status: 200, message: 'Cost Analysis Report data received.', data: response.data };
  } catch (error) {
    const statusCode = Number(error.message.slice(error.message.length - 3));
    return { status: statusCode, message: error.message, data: {} };
  }
}
