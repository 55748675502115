import axios from 'axios';

/**
 * Gets a list of all people listed in Teamwork.
 * @param {String} token Instat Apps API token
 * @returns Array of people including firstName, lastName, id
 */
export async function getPeople(token) {
  const request = {
    url: '/api/teamwork/people',
    method: 'get',
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  try {
    const response = await axios(request);
    return response.data;
  } catch (error) {
    // TODO: Better error handling -- some sort of informational message to user
    console.error(error);
  }
}

/**
 * Gets a list of all companies listed in Teamwork.
 * @param {String} token Instat Apps API token
 * @returns Array of companies including name and id
 */
export async function getCompanies(token) {
  const request = {
    url: '/api/teamwork/companies',
    method: 'get',
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  try {
    const response = await axios(request);
    return response.data;
  } catch (error) {
    // TODO: Better error handling -- some sort of informational message to user
    console.error(error);
  }
}

// TODO:
export async function getAllProjects(token) {
  const request = {
    url: '/api/teamwork/projects',
    method: 'get',
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  try {
    const response = await axios(request);
    return response.data;
  } catch (error) {
    // TODO: Better error handling -- some sort of informational message to user
    console.error(error);
  }
}
