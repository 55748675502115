import React from 'react';

const calculationExplanations = [
  {
    Column: 'Monthly Amounts',
    'Calculation Explanation': (
      <div>
        <p>
          Takes a deal and divides the total amount into monthly installments over the range of
          dates for the deal.
        </p>
        <p>These values are computed on the backend.</p>
      </div>
    ),
  },
  {
    Column: 'Yearly Amounts',
    'Calculation Explanation': (
      <div>
        <p>Sums up monthly amounts for each year.</p>
        <p>
          These values are computed on the frontend when the user selects the yearly display option.
        </p>
      </div>
    ),
  },
];

export default calculationExplanations;
