import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { Label } from 'semantic-ui-react';
import './index.scss';

function DateSlider(props) {
  const { max } = props;
  const sliderRef = useRef(null);
  const [sliderVal, setSliderVal] = useState(props.max);

  // Remove non-slider props
  const sliderProps = { ...props };
  if ('onChange' in sliderProps) delete sliderProps.onChange;

  const convertLabelToDate = dateInt => {
    const date = new Date(dateInt);
    return `${date.getMonth() + 1}/${date.getDate()}/${date.getFullYear()}`;
  };

  const getSliderPercentage = value => {
    return ((value - props.min) / (props.max - props.min)) * 100;
  };

  useEffect(() => {
    setSliderVal(max);
  }, [max]);

  return (
    <div className="date-slider-container">
      <span style={{ paddingRight: '5px' }}>
        <Label style={{ padding: '5px', cursor: 'default' }}>{convertLabelToDate(props.min)}</Label>
      </span>
      <input
        type="range"
        ref={sliderRef}
        className="date-slider"
        {...sliderProps}
        style={{
          background: `linear-gradient(to right, rgb(27, 28, 29), rgb(27, 28, 29) 98%, rgb(242, 242, 242) 98%, rgb(242, 242, 242) 100%)`,
        }}
        onChange={e => {
          // Adjust slider color to fill up to thumb
          const value = parseInt(e.target.value);
          const percentage = ((value - props.min) / (props.max - props.min)) * 100;
          // sliderRef.current.value = e.target.value;
          setSliderVal(value);
          sliderRef.current.style.background = `linear-gradient(to right, rgb(27, 28, 29), rgb(27, 28, 29) ${percentage}%, rgb(242, 242, 242) ${percentage}%, rgb(242, 242, 242) 100%)`;

          props.onChange(e);
        }}
        value={sliderVal}
      />
      <span style={{ paddingLeft: '5px' }}>
        <Label style={{ padding: '5px', cursor: 'default' }}>{convertLabelToDate(props.max)}</Label>
      </span>
    </div>
  );
}

DateSlider.propTypes = {
  min: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  max: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  value: PropTypes.number,
  onChange: PropTypes.func,
  disabled: PropTypes.bool,
};

DateSlider.defaultProps = {
  min: 0,
  max: 100,
  onChange: () => {},
};

export default DateSlider;
