/**
 * Initial state containing all clients and projects.
 */
const initialState = {
  data: [
    {
      amount: 0,
      date: '',
    },
  ],
  dataView: [
    {
      amount: 0,
      date: '',
    },
  ],
  dealTableData: [],
  dealAmountsByYear: [],
  viewOptions: {
    reportType: 'monthly',
    reportNumYears: 1,
    showCartesianGrid: false,
  },
};

export default initialState;
