import React from 'react';
import { Form, Select, Button } from 'semantic-ui-react';

function FilterOptionsForm({
  isLoadingClients,
  projects,
  clients,
  accountLeads,
  projectLeads,
  handleFilterValuesChange,
  formProps,
}) {
  // const handleFormSubmit = values => handleFilterValuesChange(values);
  const handleFilterSelection = async (filterType, newValue) => {
    const currentFilterValues = {
      clients: formProps.values.clients,
      al: formProps.values.al,
      pl: formProps.values.pl,
    };
    // console.log({ ...currentFilterValues, [filterType]: newValue });
    const filteredProjectOptions = handleFilterValuesChange(
      {
        ...currentFilterValues,
        [filterType]: newValue,
      },
      projects
    );
    return filteredProjectOptions;
  };

  const handleFilterOnChange = (e, { name, value }) => {
    formProps.setFieldValue(name, value);
    const currentProjects = formProps.values.projects;

    handleFilterSelection(name, value).then(filteredProjectOptions => {
      if (filteredProjectOptions.length > 0) {
        const filteredProjectsValues = currentProjects.filter(project =>
          filteredProjectOptions.some(p => p.value === project)
        );
        formProps.setFieldValue('projects', filteredProjectsValues);
      } else {
        formProps.setFieldValue('projects', currentProjects);
      }
    });
  };

  return (
    <React.Fragment>
      {/* <Form.Group> */}
      <Button
        onClick={() => {
          // Clear all filter values and reset Contract+Project dropdown
          formProps.setFieldValue('clients', []);
          formProps.setFieldValue('al', []);
          formProps.setFieldValue('pl', []);
          handleFilterValuesChange({ clients: [], al: [], pl: [] }, projects);
        }}
        type="button"
        size="tiny"
        compact
        style={{ marginBottom: '10px' }}
        secondary>
        Clear Filters
      </Button>
      {/* </Form.Group> */}

      <Form.Group widths="equal">
        {/* Client */}
        <Form.Field
          control={Select}
          name="clients"
          label="Clients"
          options={clients}
          placeholder={isLoadingClients ? 'Loading...' : 'Select clients...'}
          value={formProps.values.clients}
          onChange={handleFilterOnChange}
          search
          multiple
          disabled={isLoadingClients}
        />
        {formProps.errors.clients && formProps.touched.clients ? (
          <div style={{ fontSize: '10px', color: 'red' }}>{formProps.errors.clients}</div>
        ) : null}

        {/* Empty field to improve spacing for Client field */}
        <Form.Field />
      </Form.Group>
      <Form.Group widths="equal">
        {/* Account Lead */}
        <Form.Field
          control={Select}
          name="al"
          label="Account Leads"
          options={accountLeads}
          placeholder={isLoadingClients ? 'Loading...' : 'Select account leads...'}
          value={formProps.values.al}
          onChange={handleFilterOnChange}
          search
          multiple
          disabled={isLoadingClients}
        />
        {formProps.errors.al && formProps.touched.al ? (
          <div style={{ fontSize: '10px', color: 'red' }}>{formProps.errors.al}</div>
        ) : null}

        {/* Project Lead */}
        <Form.Field
          control={Select}
          name="pl"
          label="Project Leads"
          options={projectLeads}
          placeholder={isLoadingClients ? 'Loading...' : 'Select project leads...'}
          value={formProps.values.pl}
          onChange={handleFilterOnChange}
          search
          multiple
          disabled={isLoadingClients}
        />
        {formProps.errors.pl && formProps.touched.pl ? (
          <div style={{ fontSize: '10px', color: 'red' }}>{formProps.errors.pl}</div>
        ) : null}
      </Form.Group>
    </React.Fragment>
  );
}

export default FilterOptionsForm;
