import React from 'react';

const calculationExplanations = [
  {
    Column: 'Budget',
    'Calculation Explanation': (
      <div>
        <p>Sum of all a project's Payment Tracking rows.</p>
      </div>
    ),
  },
  {
    Column: 'As of Today',
    'Calculation Explanation': (
      <div>
        <p>Payments - Costs</p>
        <p>For the current month.</p>
      </div>
    ),
  },
  {
    Column: 'Payments',
    'Calculation Explanation': (
      <div>
        <p>The following “Rev Type” payment rows are ignored:</p>
        <ul>
          <li>“Exclude”</li>
        </ul>
        <p>
          Note that <em>pass-thru</em> payments are included in this report. Payments are added by
          their “Projected Payment” column value. They are summed up by month and year.
        </p>
      </div>
    ),
  },
  {
    Column: 'Total Billable',
    'Calculation Explanation': (
      <div>
        <p>Billable + Expenses</p>
      </div>
    ),
  },
  {
    Column: 'Billable',
    'Calculation Explanation': (
      <div>
        <p>
          Sum of amounts (hours x bill rate) for each month. Both regularly entered hours and
          scheduled (future) hours are used.
        </p>
      </div>
    ),
  },
  {
    Column: 'Expenses',
    'Calculation Explanation': (
      <div>
        <p>Sum of expenses in 10kft for the project. Pass-thru expenses are included.</p>
      </div>
    ),
  },
  {
    Column: 'Breakeven Costs',
    'Calculation Explanation': (
      <div>
        <p>Same as costs, but the breakeven rate for that time entry’s year is used.</p>
      </div>
    ),
  },
  {
    Column: 'Revenue Margin',
    'Calculation Explanation': (
      <div>
        <p>( ( Payments - Breakeven Costs ) ➗ Payments ) ✖️ 100</p>
      </div>
    ),
  },
];

export default calculationExplanations;
