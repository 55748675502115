import React from 'react';
import { currencyString } from '../../utils/numbers';
import './index.scss';

/**
 * Tooltip compatible with the Recharts package. It has much nicer formatting compared to the
 * default tooltip popup. There are three sections:
 * 1. Header : at the top of the tooltip; gives a title to it.
 * 2. Items : the main body of the tooltip; a list of items, usually just the main data points.
 * 3. Totals : any totals you want included based on the main data points.
 */
function BetterRechartsTooltip({ headerText, items, totals }) {
  return (
    <div className="better-tooltip">
      <div className="better-tooltip-header">
        <span className="better-tooltip-header-text">{headerText}</span>
      </div>
      {items && (
        <div>
          {items.map(({ label, labelColor, value, valueType }, itemIndex) => {
            const valueText = valueType === 'currency' ? currencyString(value) : value;
            return (
              <div className="better-tooltip-label" key={`better-tooltip-item-${itemIndex}`}>
                <strong style={{ color: labelColor, paddingRight: '5px' }}>{label}</strong>
                <span className="better-tooltip-amount">{valueText}</span>
              </div>
            );
          })}
        </div>
      )}
      {totals && <hr />}
      {totals && (
        <div>
          {totals.map(({ name, value, valueType }, totalIndex) => {
            const valueText = valueType === 'currency' ? currencyString(value) : value;
            return (
              <div className="better-tooltip-label" key={`better-tooltip-total-${totalIndex}`}>
                <strong>{name} Total:</strong>
                <span className="better-tooltip-amount">{valueText}</span>
              </div>
            );
          })}
        </div>
      )}
    </div>
  );
}

export default BetterRechartsTooltip;
