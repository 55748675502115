import { isNumeric } from '../../../../utils/numbers';

/**
 * Function used for sorting rows based on a given column. Takes into account dates, numbers, and strings.
 * @param {string} key Name of column used for sorting
 * @returns Function to sort rows based on given column. To be passed into [].sort()
 */
const sortBy = key => (a, b) => {
  if (isNumeric(a[key]) && isNumeric(b[key])) {
    // For currency values (stored as numbers in state and rendered as currency in component)
    return a[key] > b[key] ? 1 : b[key] > a[key] ? -1 : 0;
  } else if (isNaN(Date.parse(a[key])) || isNaN(Date.parse(b[key]))) {
    let valA = a[key].toLowerCase();
    let valB = b[key].toLowerCase();
    return valA > valB ? 1 : valB > valA ? -1 : 0;
  } else {
    let valA = new Date(a[key]);
    let valB = new Date(b[key]);
    return valA > valB ? 1 : valB > valA ? -1 : 0;
  }
};

export function sortTable(state, column) {
  if (state.tableSorting.column === column) {
    return {
      ...state,
      tableRows: [...state.tableRows].reverse(),
      tableSorting: {
        ...state.tableSorting,
        direction: state.tableSorting.direction === 'ascending' ? 'descending' : 'ascending',
      },
    };
  }

  return {
    ...state,
    tableRows: state.tableRows.sort(sortBy(column)),
    tableSorting: {
      ...state.tableSorting,
      column,
      direction: 'ascending',
    },
  };
}
