import React, { useReducer, useEffect, useState } from 'react';
import { Container, Header, Segment, Loader, Message, Icon } from 'semantic-ui-react';
import { useToasts } from 'react-toast-notifications';
import RevenueBarChart from './components/RevenueBarChart';
import ViewOptions from './components/ViewOptions';
import initialState from './state/initialState';
import reducer from './state/reducer';
import { getPipelineProjectionExpectedRevenueByYear } from '../../services/api/reports/pipeline-projection-expected-revenue';
import { runAuthVerification } from '../../services/api/auth';
import DealInfoTable from './components/DealInfoTable';
import Big from 'big.js';
import { setBigForCurrency } from '../../utils/numbers';
import CalculationsHelpModal from '../../components/CalculationsHelpModal';
import calculationExplanations from './data/calculation-explanations';
import { useAuth, useAuthFunctions } from '../../context/AuthContext';
import DealAmountYearlyBreakdownTable from './components/DealAmountYearlyBreakdownTable';

function PipelineProjectionExpectedRevenue({ history }) {
  document.title = 'Instat Apps - Pipeline Projection Expected Revenue';
  setBigForCurrency(Big);
  const { addToast } = useToasts();
  const auth = useAuth();
  const { token } = auth.user;
  const { logout } = useAuthFunctions();

  const [state, dispatch] = useReducer(reducer, initialState);
  const [isLoading, setIsLoading] = useState(true);

  const { data, viewOptions, yearlyTotals } = state;

  useEffect(() => {
    async function main() {
      await runAuthVerification(token);

      const res = await getPipelineProjectionExpectedRevenueByYear(token);
      const reportDataReceived = res.data.reportData;
      const { reportData, yearlyTotals } = formatReportAndSumYearly(reportDataReceived);

      dispatch({ type: 'UPDATE_REPORT_DATA', data: reportData });
      dispatch({ type: 'UPDATE_DEAL_TABLE_DATA', dealTableData: res.data.dealInfo });
      dispatch({ type: 'UPDATE_YEARLY_TOTALS', yearlyTotals: yearlyTotals });
      dispatch({
        type: 'UPDATE_DEAL_AMOUNTS_BY_YEAR',
        dealAmountsByYear: res.data.dealAmountsByYear,
      });
      setIsLoading(false);
    }

    main().catch(err => {
      const errorMsg = err.message;
      if (errorMsg.includes('status code 401')) {
        /**
         * Notify user their session has expired, present toast notification, and redirect to login.
         */
        addToast('Your session has expired. Please sign in again.', {
          appearance: 'error',
          autoDismiss: true,
        });
        logout();
        history.push('/login');
      } else {
        /**
         * Catch all other error codes and notify user of error in toast notification.
         * User is not logged out.
         */
        addToast(err.message, { appearance: 'error', autoDismiss: true });
      }
      console.error(err);
    });
  }, [token]);

  useEffect(() => {
    /**
     * Gets called when one of the following is true:
     * 1. New data received from the API.
     * 2. User toggles between monthly/yearly views.
     */
    const { reportType, reportNumYears } = viewOptions;
    const currentYear = new Date().getFullYear();

    if (reportType === 'monthly') {
      if (reportNumYears === 1) {
        const currentYearData = data.filter(d => {
          const year = parseInt(d.date.split('/')[1]);
          return year === currentYear;
        });
        dispatch({ type: 'TOGGLE_DATA_VIEW', dataView: currentYearData });
      } else if (reportNumYears === 2) {
        const currentYearPlusOneData = data.filter(d => {
          const year = parseInt(d.date.split('/')[1]);
          return year >= currentYear && year <= currentYear + 1;
        });
        dispatch({ type: 'TOGGLE_DATA_VIEW', dataView: currentYearPlusOneData });
      } else if (reportNumYears === 3) {
        const currentYearPlusTwoData = data.filter(d => {
          const year = parseInt(d.date.split('/')[1]);
          return year >= currentYear && year <= currentYear + 2;
        });
        dispatch({ type: 'TOGGLE_DATA_VIEW', dataView: currentYearPlusTwoData });
      } else {
        dispatch({ type: 'TOGGLE_DATA_VIEW', dataView: data });
      }
    } else if (reportType === 'yearly') {
      // Combine monthly amounts for each year
      const { yearlyTotals } = formatReportAndSumYearly(data);

      // Format data for chart and sort
      const dataView = [];
      for (const yearKey in yearlyTotals) {
        dataView.push({
          amount: yearlyTotals[yearKey],
          date: parseInt(yearKey),
        });
      }
      dataView.sort((a, b) => a.date - b.date);

      dispatch({ type: 'TOGGLE_DATA_VIEW', dataView });
    }
  }, [data, viewOptions]);

  /**
   * Handles view options state changes.
   * @param {string} optionName Name of view option being toggled.
   * @param {string} newValue What the new value should be (optional).
   */
  function handleToggleViewOption(optionName, newValue) {
    if (newValue) {
      dispatch({ type: 'CHANGE_VIEW_OPTION', payload: { optionName, newValue } });
    } else {
      dispatch({ type: 'TOGGLE_VIEW_OPTION', payload: { optionName } });
    }
  }

  /**
   * Totals up yearly amounts and formats monthly amounts as floats.
   * @param {array} monthlyData Array of amount/date objects.
   * @returns Both reportData and yearlyTotals with amounts as accurately calculated floats.
   */
  function formatReportAndSumYearly(monthlyData) {
    const report = [];
    const totalsByYear = {};
    for (const month of monthlyData) {
      const year = month.date.split('/')[1];
      if (year in totalsByYear) {
        totalsByYear[year] = totalsByYear[year].add(month.amount);
      } else {
        totalsByYear[year] = new Big(month.amount);
      }
      report.push({ amount: parseFloat(month.amount), date: month.date });
    }

    for (const year in totalsByYear) {
      const yearAsNumber = parseFloat(totalsByYear[year]);
      totalsByYear[year] = yearAsNumber;
    }

    return {
      reportData: report,
      yearlyTotals: totalsByYear,
    };
  }

  return (
    <div>
      <Container style={{ margin: '30px 0' }}>
        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
          <Header as="h1">Pipeline Projection: Expected Revenue</Header>
          <div>
            <CalculationsHelpModal calculationExplanations={calculationExplanations} />
          </div>
        </div>
        <Message>
          <Message.Content>
            <p>Click on any of the below links to jump to the corresponding table.</p>
            <ul>
              <li>
                <a href="#deals-list">Deals List</a>
              </li>
              <li>
                <a href="#deals-amount-yearly-breakdown">Deals Amount Yearly Breakdown</a>
              </li>
            </ul>
          </Message.Content>
        </Message>
        <ViewOptions viewOptions={viewOptions} handleToggleViewOption={handleToggleViewOption} />
        {isLoading ? (
          <Segment style={{ height: '500px' }}>
            <Loader active={isLoading}>Loading data...</Loader>
          </Segment>
        ) : (
          <RevenueBarChart
            data={state.dataView}
            viewOptions={state.viewOptions}
            yearlyTotals={yearlyTotals}
          />
        )}
        <Header as="h2" id="deals-list">
          <Icon name="table" />
          <Header.Content>
            Deals List
            <Header.Subheader>Contains all deals included in the above chart.</Header.Subheader>
          </Header.Content>
        </Header>
        <DealInfoTable dealTableData={state.dealTableData} />

        <Header as="h2" id="deals-amount-yearly-breakdown">
          <Icon name="table" />
          <Header.Content>
            Deals Amount Yearly Breakdown
            <Header.Subheader>
              Spreads the deal amount over the deal's date range by year.
            </Header.Subheader>
          </Header.Content>
        </Header>
        <DealAmountYearlyBreakdownTable dealAmountsByYear={state.dealAmountsByYear} />
      </Container>

      <br />
      <br />
      <br />
    </div>
  );
}

export default PipelineProjectionExpectedRevenue;
