import React from 'react';
import DatePicker from 'react-datepicker';
import { Icon } from 'semantic-ui-react';
import 'react-datepicker/dist/react-datepicker.css';
import './index.scss';

/**
 * TODO: Customize styles in Sass file to make it look like a native Semantic UI component.
 * See the link below for an idea on how to style it similarly.
 * https://github.com/arthurdenner/react-semantic-ui-datepickers
 */
const DateField = props => {
  return (
    <div style={{ position: 'relative' }}>
      <DatePicker {...props} showMonthDropdown showYearDropdown dropdownMode="select" />
      <Icon
        style={{
          position: 'absolute',
          right: '10px',
          top: '10px',
          color: '#bfbfbf',
        }}
        name={'calendar alternate outline'}
      />
    </div>
  );
};

export default DateField;
