export function isNumeric(num) {
  return (typeof num === 'number' || (typeof num === 'string' && num.trim() !== '')) && !isNaN(num);
}

export function setBigForCurrency(Big) {
  Big.DP = 2; // round to 2 decimal places
  Big.RM = Big.roundHalfUp; // rounds towards nearest neighbour, if equidistant, rounds away from zero.
}

/**
 * Takes a number and returns it as a formatted currency string. Defaults to USD.
 * @param {number} num Number to be converted to currency.
 * @param {string} locale The locale for toLocaleString function.
 * @param {string} currency The currency code.
 * @returns Returns a number formatted as currency.
 */
export const currencyString = (num, locale = 'en-US', currency = 'USD') =>
  num.toLocaleString(locale, { style: 'currency', currency });
