import { getTeamMemberFromTag } from '../../utils/generate-report-helpers';

const pgmTagIds = [88984, 88986, 88987, 88988, 88985];

/**
 * Filters all tasks based on filter options selected by the user.
 * @param {object[]} tasks Array of Teamwork task objects.
 * @param {object} filters Filters specified by the user for report.
 * @param {object} projectsDict Map between project id and project object.
 * @returns Array of Teamwork task objects corresponding to filters.
 */
function filterTasks(tasks, filters, projectsDict) {
  const { clients, accountLeads, projectLeads } = filters;

  // Ignore filtering if no filters selected.
  if (clients.length === 0 && accountLeads.length === 0 && projectLeads.length === 0) return tasks;

  /**
   * Takes the list of all tasks and filters based on whether or not it falls within the
   * user-provided filters for clients, account leads, and project leads.
   */
  return tasks.filter(task => {
    const results = {
      clients: false,
      accountLeads: false,
      projectLeads: false,
    };
    const project = projectsDict[task.projectId];

    if (clients.length > 0) {
      if (clients.some(cId => cId === `${task.clientId}`)) {
        results.clients = true;
      }
    } else {
      // no client filters, so ignore
      results.clients = true;
    }
    if (accountLeads.length > 0) {
      if (accountLeads.some(al => project.tags.includes(al))) {
        results.accountLeads = true;
      }
    } else {
      // no account lead filters, so ignore
      results.accountLeads = true;
    }
    if (projectLeads.length > 0) {
      if (projectLeads.some(pl => project.tags.includes(pl))) {
        results.projectLeads = true;
      }
    } else {
      // no project lead filters, so ignore
      results.projectLeads = true;
    }

    return Object.values(results).every(r => r);
  });
}

/**
 * Gets the task tag value for report table for parent tasks. Essentially it
 * finds the tag that's on the parent task and splits the tag name at the underscore
 * character, leaving the task tag.
 *
 * For example, let's say the task has a tag Pgm_SDTM. This function will return SDTM.
 *
 * If no task tag is found, an empty string is returned.
 * @param {object} task Teamwork task object.
 * @returns Parent task tag value (SDTM, ADaM, Tables, etc.).
 */
function getTaskTagValue(task) {
  const pgmTag = task.tags.filter(tag => pgmTagIds.includes(tag.id));
  if (pgmTag.length > 0) {
    return pgmTag[0].name.split('_')[1];
  }

  return '';
}

/**
 * Takes a given parent task and any existing subtask information and creates a full
 * parent task object. These objects can be used for rows in the report table.
 *
 * Note that this function can be used in one of two ways:
 * 1. Create a new parent task row object from a given parent task.
 * 2. Create a new parent task row object with placeholder empty data for keys.
 *
 * Option #2 is useful when a subtask is encountered but the parent task hasn't been created yet.
 *
 * @param {string} templateType Indicates what the parent task name should be. Options are "custom" and "normal".
 * @param {object} dictionaries Dictionary of tasklist id -> milestone id, milestone id -> milestone, project id -> project
 * @param {object} parentTask Teamwork task object.
 * @param {object} existingSubtaskInfo Any existing subtask info in a parent task row.
 * @returns Fully filled out parent task for table row.
 */
function getParentTaskTemplate(
  templateType,
  dictionaries,
  parentTask = {},
  existingSubtaskInfo = {}
) {
  /**
   * This function is used to get the name of either the parent task or the task list name
   * if a task has been moved out of the original nested programming task structure.
   * @param {string} templateType "normal" or "custom"
   * @param {object} parentTask Teamwork task object for parent task.
   * @returns Name of the parent task.
   */
  function getParentTaskName(templateType, parentTask) {
    switch (templateType) {
      case 'normal':
        return parentTask.content ?? '';
      case 'custom':
        return parentTask.tasklistName ?? '';
      default:
        return '';
    }
  }

  const { milestonesDict, tasklistsToMilestonesDict, projectsDict } = dictionaries;

  const milestoneId = tasklistsToMilestonesDict[parentTask.tasklistId] ?? '';
  const milestone = milestonesDict[milestoneId] ?? {};
  const project = projectsDict[parentTask.projectId] ?? {};

  return {
    name: getParentTaskName(templateType, parentTask),
    subtasks: existingSubtaskInfo.subtasks ?? [],
    client: {
      id: parentTask.clientId ?? '',
      name: parentTask.clientName ?? '',
    },
    project: {
      id: parentTask.projectId ?? '',
      name: parentTask.projectName ?? '',
      lead: getTeamMemberFromTag(project.tags, 'PL: '),
      programmingLeadUS: getTeamMemberFromTag(project.tags, 'LPgmUS: '),
      programmingLeadIN: getTeamMemberFromTag(project.tags, 'LPgmIN: '),
    },
    taskTag: getTaskTagValue(parentTask), // ADaM/SDTM/TLF/Invoicing Trigger
    startDate: parentTask.startDate ?? '',
    dueDate: parentTask.dueDate ?? '',
    taskStatus: existingSubtaskInfo.taskStatus ?? {
      // This will provide data for:
      // - tasks complete
      // - tasks remaining (can calculate)
      // - progress of remaining
      total: 0,
      completed: 0,
      avgProgress: 0,
    },
    milestone: {
      id: milestone.id ?? '',
      name: milestone.name ?? '',
      date: milestone.deadline ?? '',
      tag: milestone.tags && milestone.tags.length > 0 ? milestone.tags[0].name : '',
    },
    teamMembers: existingSubtaskInfo.teamMembers ?? [],
  };
}

/**
 * Provides an updated task progress percentage for all remaining programming
 * tasks. Any tasks completed are ignored and not factored into this average.
 * @param {object} taskStatus The task status object from a table row.
 * @param {number} taskProgress Task progress amount from Teamwork.
 * @returns Number representing the task progress percentage average for remaining tasks.
 */
function calculateAvgProgress(taskStatus, taskProgress) {
  // If task is complete, do not include in average calculation.
  if (taskProgress === 100) {
    return taskStatus.avgProgress;
  } else {
    const numTasksRemaining = taskStatus.total - taskStatus.completed + 1;
    const sumProgress = taskStatus.avgProgress * (numTasksRemaining - 1);
    const newSumProgress = sumProgress + taskProgress;
    return Math.round(newSumProgress / numTasksRemaining);
  }
}

function incrementSubtasks(task, parentTask) {
  // Add to list of subtasks
  parentTask.subtasks.push(task);

  // Update task status values.
  const taskCompletedIncrementValue = task.completed ? 1 : 0;
  parentTask.taskStatus = {
    ...parentTask.taskStatus,
    total: parentTask.taskStatus.total + 1,
    completed: parentTask.taskStatus.completed + taskCompletedIncrementValue,
    avgProgress: calculateAvgProgress(parentTask.taskStatus, task.progress),
  };

  // Update team members list.
  // Checks if team member is already listed and then adds if not.
  const teamMemberNames = task.assignedTo;
  if (teamMemberNames !== '') {
    const teamMemberNameList = teamMemberNames.split(',');
    parentTask.teamMembers = [...new Set([...parentTask.teamMembers, ...teamMemberNameList])];
  }

  // Update start date
  // The start date for the row should be the maximum due date out of all tasks.
  function getMinStartDate(currentStartDate, subtaskStartDate) {
    if (subtaskStartDate === '') {
      return currentStartDate;
    }
    if (currentStartDate === '') {
      return subtaskStartDate;
    }
    return parseInt(currentStartDate) < parseInt(subtaskStartDate)
      ? currentStartDate
      : subtaskStartDate;
  }
  parentTask.startDate = getMinStartDate(parentTask.startDate, task.startDate);

  // Update due date
  // The due date for the row should be the maximum due date out of all tasks.
  function getMaxDueDate(currentDueDate, subtaskDueDate) {
    if (subtaskDueDate === '') {
      return currentDueDate;
    }
    if (currentDueDate === '') {
      return subtaskDueDate;
    }
    return parseInt(currentDueDate) > parseInt(subtaskDueDate) ? currentDueDate : subtaskDueDate;
  }
  parentTask.dueDate = getMaxDueDate(parentTask.dueDate, task.dueDate);
}

function createTasklistMilestonesDict(milestones) {
  const dict = {};
  for (const milestone of milestones) {
    if (milestone.tasklists.length === 0) continue;

    for (const tasklist of milestone.tasklists) {
      dict[tasklist.id] = milestone.id;
    }
  }

  return dict;
}

function createMilestonesDict(milestones) {
  const dict = {};

  for (const milestone of milestones) {
    dict[milestone.id] = milestone;
  }

  return dict;
}

/**
 * Takes an array of Teamwork project objects and returns a dictionary going from
 * project id to project object. Tags are converted to an array of tag ids.
 * @param {object[]} projects Array of Teamwork project objects.
 * @returns Dictionary project id to project object.
 */
function createProjectsDict(projects) {
  const dict = {};

  for (const project of projects) {
    dict[project.id] = { ...project, tags: project.tags.map(({ id }) => id) };
  }

  return dict;
}

/**
 * Takes a list of Teamwork projects and maps each project's id to its entire project
 * object. This can then be used for quick lookup of project data by its id.
 * @param {object[]} projects Array of all Teamwork project objects.
 * @returns Dictionary mapping Teamwork project id to Teamwork project object.
 */
function createProjectIdToProjectDict(projects) {
  const dict = {};

  for (const project of projects) {
    dict[project.id] = project;
  }

  return dict;
}

export default function generateReportState(state, filters) {
  const programmingTagIds = [
    88949, // PgmTask_Dev
    88950, // PgmTask_Val
    88951, // PgmTask_Spec
    88984, // PgmTask_ADaM
    88985, // PgmTask_SDTM
    88986, // PgmTask_Tables
    88988, // PgmTask_Figures
    88987, // PgmTask_Listings
  ];

  const { cache } = state;
  const { projects, tasks, milestones } = cache;
  const projectsDict = createProjectsDict(projects);

  const dictionariesForParentTaskTemplate = {
    milestonesDict: createMilestonesDict(milestones),
    tasklistsToMilestonesDict: createTasklistMilestonesDict(milestones),
    projectsDict: createProjectIdToProjectDict(projects),
  };

  const filteredTasks = filterTasks(tasks, filters, projectsDict); // based on user filters

  const tableData = {};
  for (const task of filteredTasks) {
    const isSubtask =
      task.content.startsWith('Create DEV ') ||
      task.content.startsWith('Create VAL ') ||
      task.content.startsWith('Update Specs:') ||
      task.content.startsWith('Update specs:');
    const isNonTagged = !programmingTagIds.some(tagId =>
      task.tags.map(tag => tag.id).some(tId => tId === tagId)
    );

    if (isSubtask) {
      /**
       * All subtasks will add/increment data in its corresponding parent
       * task. If the parent task does not exist, it is added with all
       * information blank. It should eventually be filled in once the
       * parent task is reached.
       */
      const parentTaskId = task.parentTaskId;

      if (parentTaskId === '') {
        const customParentTaskId = `TL${task.tasklistId}`;

        if (customParentTaskId in tableData) {
          incrementSubtasks(task, tableData[customParentTaskId]);
        } else {
          const newCustomParentTask = getParentTaskTemplate(
            'custom',
            dictionariesForParentTaskTemplate,
            task
          );
          incrementSubtasks(task, newCustomParentTask);
          tableData[customParentTaskId] = newCustomParentTask;
        }

        continue;
      }

      if (parentTaskId in tableData) {
        incrementSubtasks(task, tableData[parentTaskId]);
      } else {
        // Parent task does not exist. Add in a blank new parent task.
        const newParentTask = getParentTaskTemplate('normal', dictionariesForParentTaskTemplate);
        incrementSubtasks(task, newParentTask);
        tableData[task.parentTaskId] = newParentTask;
      }
    } else if (isNonTagged) {
      /**
       * These tasks are ones in deliverable task lists, programming template task lists, and any
       * task list that has a milestone assigned to it.
       */
      const customParentTaskId = `TL${task.tasklistId}`;
      if (customParentTaskId in tableData) {
        incrementSubtasks(task, tableData[customParentTaskId]);
      } else {
        const newCustomParentTask = getParentTaskTemplate(
          'custom',
          dictionariesForParentTaskTemplate,
          task
        );
        incrementSubtasks(task, newCustomParentTask);
        tableData[customParentTaskId] = newCustomParentTask;
      }
    } else {
      /**
       * All parent tasks are added here. It is possible that a subtask is
       * reached before the parent, so first it needs to check if the parent
       * already is added. If it already exists in the list, then fill in the
       * missing values.
       */
      const parentTaskId = task.id;
      if (parentTaskId in tableData) {
        // Set parent task-specific values.
        const updatedParentTask = getParentTaskTemplate(
          'normal',
          dictionariesForParentTaskTemplate,
          task,
          tableData[parentTaskId]
        );
        tableData[parentTaskId] = updatedParentTask;
      } else {
        // Add new parent task to table rows.
        const newParentTask = getParentTaskTemplate(
          'normal',
          dictionariesForParentTaskTemplate,
          task
        );
        tableData[parentTaskId] = newParentTask;
      }
    }
  }

  return { ...state, tableData: Object.values(tableData) };
}
