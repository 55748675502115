import React, { useState } from 'react';
import { Popup, Table, Modal, Button, Icon } from 'semantic-ui-react';

function CalculationsHelpModal({ calculationExplanations }) {
  const [isOpen, setIsOpen] = useState(false);

  const headers = Object.keys(calculationExplanations[0]);
  const sortedCalculationExplanations = [...calculationExplanations];
  sortedCalculationExplanations.sort((a, b) => a.Column.localeCompare(b.Column));

  return (
    <div>
      <Popup
        content="Calculation Explanations"
        position="bottom left"
        mouseEnterDelay={500}
        trigger={
          <Button onClick={() => setIsOpen(true)} icon circular>
            <Icon name="calculator" />
          </Button>
        }
      />
      <Modal dimmer="blurring" open={isOpen} onClose={() => setIsOpen(false)}>
        <Modal.Header>Calculations Explanations</Modal.Header>
        <Modal.Content>
          <Table celled>
            <Table.Header>
              <Table.Row>
                {headers.map((h, hIndex) => (
                  <Table.HeaderCell key={`header-${hIndex}`}>{h}</Table.HeaderCell>
                ))}
              </Table.Row>
            </Table.Header>

            <Table.Body>
              {sortedCalculationExplanations.map((ce, ceIndex) => (
                <Table.Row key={`ce-${ceIndex}`}>
                  {Object.values(ce).map((val, valIndex) => (
                    <Table.Cell key={`val-${valIndex}`}>{val}</Table.Cell>
                  ))}
                </Table.Row>
              ))}
            </Table.Body>
          </Table>
        </Modal.Content>
        <Modal.Actions>
          <Button secondary onClick={() => setIsOpen(false)}>
            Close
          </Button>
        </Modal.Actions>
      </Modal>
    </div>
  );
}

CalculationsHelpModal.defaultProps = {
  calculationExplanations: [
    {
      Column: 'Test',
      'Calculation Explanation': 'This is the explainer text.',
    },
  ],
};

export default CalculationsHelpModal;
