const initialState = {
  cache: {
    dateModified: '',
    accountLeads: [],
    projectLeads: [],
    companies: [],
    projects: [],
    tasks: [],
    tasklists: [],
    milestones: [],
  },
  filters: {
    al: [],
    pl: [],
    companies: [],
  },
  tableData: [],
  tableRows: [],
  tableSorting: {
    column: '',
    direction: '',
  },
  isLoading: {
    cache: true,
  },
  loading: {
    isActive: false,
    message: '',
  },
};

export default initialState;
