import React from 'react';
import PropTypes from 'prop-types';
import { Formik } from 'formik';
import { Form, Button, Icon, Message } from 'semantic-ui-react';
import * as Yup from 'yup';
import { format } from 'date-fns';
import DateField from '../../../components/DateField';

const ContractProcessingFilterFormSchema = Yup.object().shape({
  startDate: Yup.date().nullable().max(Yup.ref('endDate')).when('endDate', (endDate, schema) => {
    // Makes sure both dates required if one is filled.
    if (endDate) return schema.required('Start date required when end date given.');
    else return schema.notRequired();
  }),
  endDate: Yup.date().nullable().min(Yup.ref('startDate')).notRequired(),
});

function ContractProcessingFilterForm({ filters, isLoading, updateReport }) {
  return (
    <Formik
      enableReinitialize
      validationSchema={ContractProcessingFilterFormSchema}
      initialValues={filters}
      onSubmit={async (values, actions) => await updateReport(values)}
      isLoading={isLoading}>
      {({ values, handleSubmit, setFieldValue, errors, touched }) => (
        <Form>
          <Form.Group widths={'equal'} style={{ margin: '0 -.5em 0' }}>
            <Form.Field
              label="Start Date"
              name="startDate"
              control={DateField}
              selected={values.startDate}
              onChange={date => {
                // TODO: When start date set, automatically fill end date with today's date unless selected date is in future
                setFieldValue('startDate', date);
              }}
              startDate={values.startDate}
              endDate={values.endDate}
              placeholderText="MM/DD/YYYY"
              autoComplete="off"
              required={values.startDate !== null || values.endDate !== null}
            />
            {errors.startDate && touched.startDate ? (
              <div style={{ fontSize: '10px', color: 'red' }}>{errors.startDate}</div>
            ) : null}
            <Form.Field
              label="End Date"
              name="endDate"
              control={DateField}
              selected={values.endDate}
              onChange={date => setFieldValue('endDate', date)}
              startDate={values.startDate}
              endDate={values.endDate}
              minDate={values.startDate}
              placeholderText="MM/DD/YYYY"
              autoComplete="off"
              required={values.startDate !== null || values.endDate !== null}
            />
            {errors.endDate && touched.endDate ? (
              <div style={{ fontSize: '10px', color: 'red' }}>{errors.endDate}</div>
            ) : null}
          </Form.Group>
          <Message warning style={{ display: 'block' }}>
            <Message.Header>
              <Icon name="warning sign" />Refreshing the Contract Processing Report
            </Message.Header>
            {/* <Message.Content> */}
            <p>Please note the following before refreshing the report:</p>
            <ul>
              <li>
                If you provide a large date range,{' '}
                <em>the report refresh can take several minutes to complete</em>. It might require a
                page refresh when done.
              </li>
              <li>
                The newly refreshed report will be <em>cached for all users</em>. When this app is
                loaded again, this newly cached report will be loaded by default.
              </li>
            </ul>
            {/* </Message.Content> */}
          </Message>

          <Button
            onClick={handleSubmit}
            type="submit"
            icon
            labelPosition="left"
            primary
            disabled={isLoading}>
            <Icon name="refresh" />
            Refresh Report
          </Button>
        </Form>
      )}
    </Formik>
  );
}

ContractProcessingFilterForm.propTypes = {
  filters: PropTypes.shape({
    startDate: PropTypes.instanceOf(Date),
    endDate: PropTypes.instanceOf(Date),
  }).isRequired,
  isLoading: PropTypes.bool.isRequired,
  updateReport: PropTypes.func.isRequired,
};

export default ContractProcessingFilterForm;
