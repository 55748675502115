import React from 'react';

const calculationExplanations = [
  {
    Column: 'Total Payments',
    'Calculation Explanation': (
      <div>
        <p>Sum of Payment Tracking rows that meet these requirements:</p>
        <ul>
          <li>“Invoice Sent” column value marked as “Sent”</li>
          <li>“Revenue Received” column value marked as “Received”</li>
        </ul>
        <p>
          Pending Payments (used in other calculations) are Payment Tracking rows that have the
          “Invoice Sent” column set to “Sent” but the “Revenue Received” column value is not set to
          “Received”.
        </p>
        <p>
          Pass-thru payments can either be included or excluded. A pass-thru payment is any Payment
          Tracking row that has the value “Pass-thru” given in the “Rev Type” column.
        </p>
      </div>
    ),
  },
  {
    Column: 'Total Billables',
    'Calculation Explanation': (
      <div>
        <p>Sum of all time entry amount values as follows:</p>
        <p>bill rate x hours</p>
        <p>The time entries pulled from the 10kft API are filtered as follows:</p>
        <ul>
          <li>not suggestions (not future time entries)</li>
          <li>before or on today’s date</li>
        </ul>
      </div>
    ),
  },
  {
    Column: 'Total Expenses',
    'Calculation Explanation': (
      <div>
        <p>
          All expenses are retrieved from 10kft. They are filtered to only include expenses through
          today’s date and summed up.
        </p>
        <p>
          Pass-thru expenses can either be included or excluded. A pass-thru expense is any expense
          from 10kft from any of these categories:
        </p>
        <ul>
          <li>DM: EDC Pass-thru</li>
          <li>General Pass-thru</li>
        </ul>
      </div>
    ),
  },
  {
    Column: 'Net Cash',
    'Calculation Explanation': (
      <div>
        <p>Total Payments - Total Billables - Total Expenses</p>
      </div>
    ),
  },
  {
    Column: 'Total Breakeven',
    'Calculation Explanation': (
      <div>
        <p>Total Breakeven + Total Expenses</p>
        <p>
          Total Breakeven = Sum of all time entry amount values using the breakeven rate as defined
          in the Cost Analysis spreadsheet. For each time entry, amount = breakeven rate × hours.
          The same time entries are used for both Total Billables and Total Breakeven.
        </p>
      </div>
    ),
  },
  {
    Column: 'Net Cash (Breakeven)',
    'Calculation Explanation': (
      <div>
        <p>Total Payments - Total Breakeven</p>
      </div>
    ),
  },
  {
    Column: 'Accrued Total Revenue',
    'Calculation Explanation': (
      <div>
        <p>Total Payments + Payments Pending</p>
      </div>
    ),
  },
  {
    Column: 'Diff from Planned Margin',
    'Calculation Explanation': (
      <div>
        <p>(Total Payments + Pending Payments) - Total Billables - Total Expenses</p>
      </div>
    ),
  },
  {
    Column: 'Actual Margin (Breakeven)',
    'Calculation Explanation': (
      <div>
        <p>(Total Payments + Payments Pending) - Total Breakeven</p>
      </div>
    ),
  },
  {
    Column: 'Profit Margin',
    'Calculation Explanation': (
      <div>
        <p>Actual Margin (Breakeven) ÷ Accrued Total Revenue</p>
      </div>
    ),
  },
];

export default calculationExplanations;
