import axios from 'axios';

/**
 * Makes request to log in user to Instat Apps API.
 * @param {String} token Google authentication token
 * @returns User object from API login route
 */
export async function login(token) {
  try {
    const response = await axios.post(
      '/api/login',
      {},
      { headers: { Authorization: `Bearer ${token}` } }
    );
    return response.data;
  } catch (error) {
    console.log('something went wrong with login function');
    console.log(error);
  }
}

/**
 * Presents user with Google authentication window. Result is stored as
 */
export async function authenticateGoogle() {
  const auth2 = window.gapi.auth2.getAuthInstance();
  const res = await auth2.signIn();
  const tokenType = res.Zi.token_type;
  const idToken = res.Zi.id_token;

  return {
    gauth: { tokenType, idToken },
  };
}

/**
 * Checks to make sure the user's authentication token hasn't expired.
 * @param {string} token Instat Apps auth token.
 * @returns If successful it returns the res object data (message).
 */
export async function runAuthVerification(token) {
  const response = await axios.get('/api/login/verify', {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  return { status: response.status, message: response.data.message };
}
