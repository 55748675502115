function sortTableData(tableData, columnForSort) {
  // sort function for array of objects
  const columnSort = (a, b) => {
    const columnA = a[columnForSort];
    const columnB = b[columnForSort];

    if (columnA > columnB) return -1;
    else if (columnA < columnB) return 1;
    else return 0;
  };

  tableData.sort(columnSort);

  return tableData;
}

export default function changeTableSort(state, columnForSort) {
  if (state.tableSort.columnForSort === columnForSort) {
    console.log(`sorting by ${columnForSort} (switch directions)`);
    console.log(JSON.stringify(state.tableSort));
    return {
      ...state,
      report: {
        ...state.report,
        data: { ...state.report.data, tableData: [ ...state.report.data.tableData ].reverse() },
      },
      tableSort: {
        ...state.tableSort,
        direction: state.tableSort.direction === 'ascending' ? 'descending' : 'ascending',
      },
    };
  }

  console.log(`sorting by ${columnForSort} (switch columns)`);
  console.log(JSON.stringify(state.tableSort));
  return {
    ...state,
    report: {
      ...state.report,
      data: {
        ...state.report.data,
        tableData: sortTableData([ ...state.report.data.tableData ], columnForSort),
      },
    },
    tableSort: {
      ...state.tableSort,
      direction: 'ascending',
      columnForSort,
    },
  };
}
