import ProjectCreator from '../views/ProjectCreator';
import ProjectFinancialsReport from '../views/ProjectFinancialsReport';
import ActiveProjectsFinancialStatus from '../views/ActiveProjectsFinancialStatus';
import CostAnalysisReport from '../views/CostAnalysisReport';
import ContractProcessingReport from '../views/ContractProcessingReport';
import PipelineProjectionExpectedRevenue from '../views/PipelineProjectionExpectedRevenue';
import MetricsDashboard from '../views/MetricsDashboard';
import ProgrammingTasksReport from '../views/ProgrammingTasksReport';
import InvoiceTriggerActivityReport from '../views/InvoiceTriggerActivityReport';

export const appLinks = [
  {
    name: 'Project Creator',
    description:
      'In one step create projects in Teamwork, 10,000ft, Beanstalk, the Project Revenue spreadsheet, and Google Drive.',
    link: '/project-creator',
    roles: ['admin', 'leadership'],
    section: '',
    sectionIcon: '',
    component: ProjectCreator,
  },
  {
    name: 'Project Financials Report',
    description: 'Run reports on project costs and payments.',
    link: '/project-financials-report',
    roles: ['admin', 'leadership', 'project-leadership'],
    section: 'Reports',
    sectionIcon: 'chart area',
    component: ProjectFinancialsReport,
  },
  {
    name: 'Active Projects Financial Report',
    description:
      'View report on financial status of all active projects. This report can be saved to Google Drive or downloaded.',
    link: '/active-projects-financial-report',
    roles: ['admin', 'partner', 'finance'],
    section: 'Reports',
    sectionIcon: 'chart area',
    component: ActiveProjectsFinancialStatus,
  },
  {
    name: 'Cost Analysis Report',
    description:
      'Runs a single project report showing costs broken down by category, resource, and team member.',
    link: '/cost-analysis-report',
    roles: ['admin', 'partner', 'management'],
    section: 'Reports',
    sectionIcon: 'chart area',
    component: CostAnalysisReport,
  },
  {
    name: 'Contract Processing Report',
    description: 'Gives time from contract requested to fully signed.',
    link: '/contract-processing-report',
    roles: ['admin'],
    section: 'Reports',
    sectionIcon: 'chart area',
    component: ContractProcessingReport,
  },
  {
    name: 'Pipeline Projection - Expected Revenue',
    description: '',
    link: '/pipeline-projection-expected-revenue',
    roles: ['admin'],
    section: 'Reports',
    sectionIcon: 'chart area',
    component: PipelineProjectionExpectedRevenue,
  },
  {
    name: 'Metrics Dashboard',
    description: 'Company metrics reports for the Leadership team.',
    link: '/metrics-dashboard',
    roles: ['admin', 'partner', 'leadership'],
    section: 'Reports',
    sectionIcon: 'chart area',
    component: MetricsDashboard,
  },
  {
    name: 'Programming Tasks Report',
    description: 'View and export a report of the status of all programming tasks in Teamwork.',
    link: '/programming-tasks-report',
    roles: ['admin', 'partner', 'leadership'],
    section: 'Reports',
    sectionIcon: 'chart area',
    component: ProgrammingTasksReport,
  },
  {
    name: 'Invoice Trigger Activity Report',
    description: 'View a report of invoice trigger historical changes.',
    link: '/invoice-trigger-activity-report',
    roles: ['admin', 'partner', 'leadership'],
    section: 'Reports',
    sectionIcon: 'chart area',
    component: InvoiceTriggerActivityReport,
  },
];
