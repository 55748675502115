import { Parser } from 'json2csv';
import { getTableHeaderData } from './table-mapping';

export function generateCSV(tableRows) {
  const tableHeaders = getTableHeaderData();

  const opts = { fields: tableHeaders };

  const parser = new Parser(opts);
  const csv = parser.parse(tableRows);

  return csv;
}
