export function sortObject(a, b, key) {
  if (a[key] < b[key]) return -1;
  else if (a[key] > b[key]) return 1;
  else return 0;
}

export function sortArrayStringsDesc(a, b, caseSensitive = false) {
  const strA = caseSensitive ? a : a.toLowerCase();
  const strB = caseSensitive ? b : b.toLowerCase();

  if (strA < strB) return -1;
  if (strA > strB) return 1;
  else return 0;
}
