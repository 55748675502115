import React from 'react';
import { currencyFormatter } from '../../../utils/string-transformations';

function columnDefinitions() {
  return [
    {
      Header: 'Company',
      accessor: 'dealCompany',
    },
    {
      Header: 'Deal',
      accessor: 'dealTitle',
    },
    {
      Header: 'Amount',
      accessor: 'amount',
      Cell: ({ value }) => (
        <div style={{ textAlign: 'right' }}>{currencyFormatter.format(value)}</div>
      ),
    },
    {
      Header: 'Start Date',
      accessor: 'startDate',
    },
    {
      Header: 'Stop Date',
      accessor: 'stopDate',
    },
    {
      Header: 'Stage',
      accessor: 'dealStage',
    },
    {
      Header: 'Probability',
      accessor: 'dealProbability',
    },
  ];
}

export default columnDefinitions;
