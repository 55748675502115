const initialState = {
  projects: [],
  initialValues: {
    project: '',
  },
  options: {
    projects: [],
  },
  report: {
    timestamp: '',
    data: {},
    missingRates: {
      values: [],
      csvBlob: null,
    },
    tableData: [],
    csv: '',
    googleSheets: [],
  },
};

export default initialState;
