/**
 * Takes a month number and year and returns full month name and year.
 * @param {string} dateString Month and year formatted as MM/yyyy.
 * @returns A date string with full name of month and year.
 */
export function convertMonthYearNumbersToDate(dateString) {
  const dateArray = dateString.split('/');
  const months = [
    '',
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ];
  return `${months[parseInt(dateArray[0])]} ${dateArray[1]}`;
}
