import React, { Fragment, useState, useEffect, useReducer } from 'react';
import { useLocation } from 'react-router-dom';
import {
  Container,
  Header,
  Button,
  Form,
  Select,
  Segment,
  Icon,
  Message,
  Accordion,
  Loader,
  Checkbox,
  Divider,
} from 'semantic-ui-react';
import { Formik } from 'formik';
import { useToasts } from 'react-toast-notifications';
import FinancialsReportSwitch from './components/FinancialsReportSwitch';
import FilterOptionsForm from './components/FilterOptionsForm';
import './styles/main.scss';
import { getClientsAndProjects } from './utils/projects';
import initialState from './state/initialState';
import reducer from './state/reducer';
import { getClientCodeToProjectId } from './utils/client-dict';
import {
  getProjectIdToProject,
  getProjectCodeToTenkftId,
  getProjectCodeToTeamworkId,
  getAccountLeadToProjectId,
  getProjectLeadToProjectId,
  getProjectCodeToProjectName,
  getTenkftIdToProjectId,
} from './utils/project-dict';
import { getProjectRevenueReport } from '../../services/api/project-revenue';
import RedirectPopup from '../../components/RedirectPopup';
import ProjectFinancialsChartViewOptions from './components/ProjectFinancialsChartViewOptions';
import CalculationsHelpModal from '../../components/CalculationsHelpModal';
import calculationExplanations from './data/calculation-explanations';
import { useAuth, useAuthFunctions } from '../../context/AuthContext';

function ProjectFinancialsReport({ history }) {
  document.title = 'Instat Apps - Project Financials Report';
  const { addToast } = useToasts();
  const auth = useAuth();
  const { token } = auth.user;
  const { logout } = useAuthFunctions();

  /**
   * Q U E R Y   P A R A M S
   */
  const query = new URLSearchParams(useLocation().search);
  const getQueryVar = varName => {
    const queryString = query.get(varName);
    return queryString
      ? queryString.includes(',')
        ? queryString.split(',')
        : [queryString]
      : null;
  };
  const queryClient = getQueryVar('client');
  const queryProject = getQueryVar('project');
  const queryAL = getQueryVar('al');
  const queryPL = getQueryVar('pl');

  /**
   * S T A T E
   */
  const [state, dispatch] = useReducer(reducer, initialState);
  const [filtersInitialValues, setFiltersInitialValues] = useState({
    clients: [],
    al: [],
    pl: [],
  });
  const [initialValuesFormik, setInitialValuesFormik] = useState({
    projects: [],
    allProjects: false,
  });
  const [projectOptions, setProjectOptions] = useState([]);
  const [clientOptions, setClientOptions] = useState([]);
  const [accountLeadOptions, setAccountLeadOptions] = useState([]);
  const [projectLeadOptions, setProjectLeadOptions] = useState([]);
  const [activeAccordionIndex, setActiveAccordionIndex] = useState(0);
  const [activeFilterAccordionIndex, setActiveFilterAccordionIndex] = useState(-1);
  const [showRedirect, setShowRedirect] = useState(false);
  const [showPassThru, setShowPassThru] = useState(false);

  const setIsLoading = (loaderName, newLoaderState) => {
    dispatch({ type: 'SET_LOADER', payload: { loaderName, newLoaderState } });
  };

  const handleTogglePassThru = () => {
    dispatch({ type: 'TOGGLE_VIEW_OPTION', payload: { optionName: 'showPassThru' } });
  };

  const handleToggleViewOption = optionName => {
    dispatch({ type: 'TOGGLE_VIEW_OPTION', payload: { optionName } });
  };

  /**
   * Takes the full list of clients, filters out clients with no active projects, and returns list of formatted options for clients dropdown.
   * @param {[objects]} clients Array of all clients from spreadsheet
   * @returns Array of clients formatted as Select field options
   */
  const getActiveClientOptions = (clients, projects) => {
    const clientCodeToProjectId = getClientCodeToProjectId(projects, true);
    const activeClients = Object.keys(clientCodeToProjectId);
    const activeClientOptions = clients
      .filter(client => activeClients.includes(client['ClientCode']))
      .map(client => ({
        key: client['ClientCode'],
        text: client['Client'],
        value: client['ClientCode'],
      }));

    return activeClientOptions;
  };

  /**
   * Finds all active projects with a ProjectId and 10kftProjectId value.
   * @param {[object]} projects Array of all projects from spreadsheet
   * @returns Array of project row objects
   */
  const filterActiveProjects = projects =>
    projects.filter(
      project =>
        project['Status'] === 'Active' &&
        project['ProjectId'] !== '' &&
        project['10kftProjectId'] !== ''
    );

  /**
   * Takes the full list of projects, filters out inactive projects, and returns list of formatted options for projects dropdown.
   * @param {[object]} projects Array of all projects from spreadsheet
   * @returns Array of projects formatted as Select field options
   */
  const getActiveProjectOptions = projects => {
    const activeProjects = filterActiveProjects(projects);

    const uniqueActiveProjects = activeProjects.reduce((acc, project) => {
      const projectCode = project['ProjectCode'];

      if (projectCode in acc) {
        return acc;
      } else {
        return { ...acc, [projectCode]: project };
      }
    }, {});

    const activeProjectOptions = Object.values(uniqueActiveProjects).map(project => ({
      key: project['ProjectCode'],
      text: project['Project Name'],
      value: project['ProjectCode'],
    }));
    activeProjectOptions.sort((a, b) => {
      const nameA = a.text.toUpperCase();
      const nameB = b.text.toUpperCase();

      if (nameA < nameB) return -1;
      else if (nameA > nameB) return 1;
      else return 0;
    });

    return activeProjectOptions;
  };

  useEffect(() => {
    /**
     * Fetches clients and projects lists from Project Revenue spreadsheet when one of these conditions is met:
     * - Route reached
     * - API token value changes
     *
     * If projects included in query params, a report is generated after project id validation.
     */
    async function main() {
      const clientsAndProjects = await getClientsAndProjects(token);
      const { clients, projects } = clientsAndProjects;
      dispatch({ type: 'UPDATE_STATE', payload: clientsAndProjects });

      // Check for query params -> minimize form if projects in URL params
      if (queryProject) setActiveAccordionIndex(-1);

      const selectFieldOptions = await setSelectOptions(clients, projects);

      // Query params processing (handles filter processing on initial load as well)
      const initializedData = await initializeWithQueryParams(
        clients,
        projects,
        selectFieldOptions
      );
      const { matchedProjects, filterOptionsParams } = initializedData;

      // Generate report
      if (matchedProjects.length > 0) {
        // const projectCodeToTenkftId = getProjectCodeToTenkftId(projects);
        const projectCodeToTeamworkId = getProjectCodeToTeamworkId(projects);
        await generateReport(
          matchedProjects.map(project => project['ProjectCode']),
          filterOptionsParams,
          projectCodeToTeamworkId
        );
      }

      // Turn off form loading
      setIsLoading('form', false);
    }

    main().catch(err => {
      const errorMsg = err.message;
      if (errorMsg.includes('status code 401')) {
        /**
         * Notify user their session has expired, present toast notification, and redirect to login.
         */
        addToast('Your session has expired. Please sign in again.', {
          appearance: 'error',
          autoDismiss: true,
        });
        logout();
        history.push('/login');
      } else {
        /**
         * Catch all other error codes and notify user of error in toast notification.
         * User is not logged out.
         */
        addToast(err.message, { appearance: 'error', autoDismiss: true });
      }
      console.error(err);
    });
  }, [history, logout, token]);

  /**
   * Takes lists of client objects and project objects and formats them into an options lists for Select components.
   * @param {[object]} clients List of client objects
   * @param {[object]} projects List of project objects
   * @returns Object containing options for all select fields
   */
  async function setSelectOptions(clients = state.clients, projects = state.clients) {
    // REVIEW: Are there multiple 10kft project ids for one ProjectCode? Right now this is set to only use the first 10kft project id per ProjectCode
    // S E T   P R O J E C T S   O P T I O N S
    const activeProjectOptions = getActiveProjectOptions(projects);
    setProjectOptions(activeProjectOptions);

    // S E T   C L I E N T   O P T I O N S
    const activeClientOptions = getActiveClientOptions(clients, projects);
    setClientOptions(activeClientOptions);

    //
    const sortLeadsDescending = (a, b) => {
      const nameA = a.text.toUpperCase();
      const nameB = b.text.toUpperCase();

      if (nameA < nameB) return -1;
      else if (nameA > nameB) return 1;
      else return 0;
    };

    // S E T   A C C O U N T  L E A D   O P T I O N S
    const accountLeadToProjectId = getAccountLeadToProjectId(projects);
    const accountLeads = Object.keys(accountLeadToProjectId);
    const activeAccountLeadOptions = accountLeads.map(al => ({
      key: al,
      text: al.slice(4),
      value: al,
    }));
    activeAccountLeadOptions.sort(sortLeadsDescending);
    setAccountLeadOptions(activeAccountLeadOptions);

    // S E T   P R O J E C T  L E A D   O P T I O N S
    const projectLeadToProjectId = getProjectLeadToProjectId(projects);
    const projectLeads = Object.keys(projectLeadToProjectId);
    const activeProjectLeadOptions = projectLeads.map(pl => ({
      key: pl,
      text: pl.slice(4),
      value: pl,
    }));
    activeProjectLeadOptions.sort(sortLeadsDescending);
    setProjectLeadOptions(activeProjectLeadOptions);

    return {
      projectOptions: activeProjectOptions,
      clientOptions: activeClientOptions,
      accountLeadOptions: activeAccountLeadOptions,
      projectLeadOptions: activeProjectLeadOptions,
    };
  }

  /**
   * Check if query params and if form should be initialized with params data.
   * If not, page is reloaded as blank form.
   * @param {[object]} clients List of client objects
   * @param {[object]} projects List of project objects
   * @param {object} selectFieldOptions Options for all form select fields
   * @returns Matched projects (if any) and filter options (if any): { matchedProjects: [object], filterOptionsParams: clients: [string], al: [string], pl: [string] }
   */
  async function initializeWithQueryParams(clients, projects, selectFieldOptions) {
    const activeProjects = filterActiveProjects(projects);
    const filterOptionsParams = {
      clients: [],
      al: [],
      pl: [],
    };
    const { accountLeadOptions, projectLeadOptions } = selectFieldOptions;

    // C H E C K   U R L   P A R A M S
    if (queryProject) {
      // Find each project via project id (use dictionary)
      const matchedProjects = queryProject.map(projectCode =>
        activeProjects.find(project => project['ProjectCode'] === projectCode)
      );
      const hasUnmatchedProjects = matchedProjects.some(project => project === undefined);

      if (hasUnmatchedProjects) {
        /**
         * IF
         * One or more project ids are invalid.
         * 1. Notify user which project ids are invalid
         * 2. Reset form
         */
        const badIndices = matchedProjects
          .map((project, index) => {
            if (project === undefined) return index;
            else return -1;
          })
          .filter(indexValue => indexValue > -1);
        addToast(
          `The following given project ids do not exist: ${badIndices
            .map(i => queryProject[i])
            .join(', ')}. Please try report again.`,
          { appearance: 'error' }
        );

        history.replace('/project-financials-report');
        setActiveAccordionIndex(0);
        const activeProjectOptions = getActiveProjectOptions(projects);
        setProjectOptions(activeProjectOptions);

        return { matchedProjects: [], filterOptionsParams };
      } else {
        /**
         * ELSE
         * All project ids are valid.
         * 1. Update Projects dropdown to have given projects
         * 2. Check for filter options in params
         * 3. Set filter options based on params
         * 4. Filter project list based on filters
         * 5. Generate report
         */

        // Update Projects dropdown to have given projects
        const projectsInitialValues = matchedProjects.map(project => project['ProjectCode']);
        setInitialValuesFormik({ ...initialValuesFormik, projects: projectsInitialValues });

        if (queryClient) {
          const clientOptions = getActiveClientOptions(clients, projects);
          const foundClients = queryClient.map(clientCode =>
            clientOptions.find(clientOption => clientOption.value === clientCode)
          );
          const hasUnmatchedClients = foundClients.some(clientOption => clientOption === undefined);
          if (!hasUnmatchedClients) {
            // Set selected clients in filter list
            filterOptionsParams.clients = foundClients.map(clientOption => clientOption.value);
          }
        }

        if (queryAL) {
          const foundAccountLeads = queryAL.map(al =>
            accountLeadOptions.find(option => al === option.value)
          );
          const hasUnmatchedAccountLeads = foundAccountLeads.some(al => al === undefined);

          if (!hasUnmatchedAccountLeads) {
            filterOptionsParams.al = foundAccountLeads.map(al => al.value);
          }
        }

        if (queryPL) {
          const foundProjectLeads = queryPL.map(pl =>
            projectLeadOptions.find(option => pl === option.value)
          );
          const hasUnmatchedProjectLeads = foundProjectLeads.some(pl => pl === undefined);

          if (!hasUnmatchedProjectLeads) {
            filterOptionsParams.pl = foundProjectLeads.map(pl => pl.value);
          }
        }

        // Update state with selected filter options
        dispatch({ type: 'UPDATE_STATE', payload: filterOptionsParams });
        setFiltersInitialValues(filterOptionsParams);

        // Handle filter value change
        handleFilterValuesChange(filterOptionsParams, projects);

        return { matchedProjects, filterOptionsParams };
      }
    } else {
      // No query params -> load empty form
      history.replace('/project-financials-report');
      const activeProjectOptions = getActiveProjectOptions(projects);
      setProjectOptions(activeProjectOptions);

      return { matchedProjects: [], filterOptionsParams };
    }
  }

  /**
   * Filters out project options that don't match the given filter values.
   * @param {object} filterValues Object containing values for clients, al, and pl
   * @param {[object]} projects Array of project objects
   * @returns Array of formatted project options
   */
  async function handleFilterValuesChange(filterValues, projects) {
    const { clients, al, pl } = filterValues;

    // If no filters -> reset list to original
    if (clients.length === 0 && al.length === 0 && pl.length === 0) {
      const activeProjectOptions = getActiveProjectOptions(projects);
      setProjectOptions(activeProjectOptions);
      return activeProjectOptions;
    } else {
      const filteredProjects = projects
        .filter(project => {
          // Client filters
          if (clients.length === 0) return true;

          const clientCode = project['ProjectCode'].replace(/[0-9]/g, '');
          return clients.some(client => client === clientCode);
        })
        .filter(project => {
          // Account Lead filters
          if (al.length === 0) return true;

          if (project.teamworkData && project.teamworkData.tags) {
            const matchedALTags = project.teamworkData.tags
              .filter(tag => tag.name.includes('AL: '))
              .map(tag => tag.name)
              .filter(tag => al.some(a => a === tag));
            return matchedALTags.length > 0;
          } else {
            return false;
          }
        })
        .filter(project => {
          // Project Lead filters
          if (pl.length === 0) return true;

          if (project.teamworkData && project.teamworkData.tags) {
            const matchedPLTags = project.teamworkData.tags
              .filter(tag => tag.name.includes('PL: '))
              .map(tag => tag.name)
              .filter(tag => pl.some(p => p === tag));
            return matchedPLTags.length > 0;
          } else {
            return false;
          }
        });

      // List of filtered projects is empty -> notify user
      // Project options still set to empty array since that is what the user selected.
      if (filteredProjects.length === 0) {
        addToast('No projects match the given filters', {
          appearance: 'warning',
          autoDismiss: true,
        });
      }

      const filteredActiveProjectOptions = getActiveProjectOptions(filteredProjects);
      setProjectOptions(filteredActiveProjectOptions);
      return filteredActiveProjectOptions;
    }
  }

  async function generateReport(projects, filters, projectCodeToTeamworkId) {
    setIsLoading('form', true);
    dispatch({ type: 'SET_SELECTED_PROJECTS', payload: projects });

    console.log('generating report, projects =');
    console.log(JSON.stringify(projects, null, 2));

    const { clients, al, pl } = filters;
    let filterParams = `${
      clients.length > 0 ? `&client=${encodeURIComponent(clients.join(','))}` : ''
    }${al.length > 0 ? `&al=${encodeURIComponent(al.join(','))}` : ''}${
      pl.length > 0 ? `&pl=${encodeURIComponent(pl.join(','))}` : ''
    }`;
    history.push(
      `/project-financials-report?project=${encodeURIComponent(projects.join(','))}${filterParams}`
    );

    try {
      // Convert "Project Code" value to Teamwork project id value for backend.
      const projectsAsTeamworkProjectIds = projects.map(
        projectCode => projectCodeToTeamworkId[projectCode]
      );
      const reportDataResponse = await getProjectRevenueReport(token, projectsAsTeamworkProjectIds);

      // Print errors from response.
      // These errors are things like breakeven rate missing from spreadsheet.
      for (const projectId of Object.keys(reportDataResponse)) {
        const errors = reportDataResponse[projectId].projectData.errors;
        for (const error of errors) {
          addToast(error, { appearance: 'warning', autoDismiss: false });
        }
      }

      if (!reportDataResponse) {
        throw new Error(
          'Error retrieving report data for the selected project(s). Please try again.'
        );
      }

      // TODO: Set data payload for both with and without pass-thru
      // Report data is now set (id: [data]) -> interpret and chart
      dispatch({ type: 'SET_REPORT_DATA', payload: reportDataResponse });
    } catch (error) {
      console.error(error);
      addToast(error.message, { appearance: 'error', autoDismiss: true });
    }

    setIsLoading('form', false);
  }

  function generateReportHeader() {
    if (state.selectedProjects && state.selectedProjects.length > 0) {
      const { selectedProjects } = state;

      if (selectedProjects.length === 1) {
        // Single project report
        const projectCodeToProjectName = getProjectCodeToProjectName(state.projects);

        const projectId = selectedProjects[0];
        const projectName = projectCodeToProjectName[projectId];

        return <Header as="h2">{projectName}</Header>;
      } else {
        // Multiple project report
        return <Header as="h2">Multiple Projects Financial Report</Header>;
      }
    } else {
      // No report--no selected projects
      return <div style={{ height: '30px' }} />;
    }
  }

  /**
   * Component Functions
   */
  const toggleAccordion = (e, titleProps) => {
    const { index } = titleProps;
    const newIndex = activeAccordionIndex === index ? -1 : index;
    setActiveAccordionIndex(newIndex);
  };

  return (
    <Fragment>
      {showRedirect ? (
        <RedirectPopup
          delay={5}
          content={{
            header: 'Credentials Expired',
            headerIcon: 'lock',
            description:
              'Whoops! Looks like your credentials have expired. Please sign back in and try again.',
            redirectLocation: 'login',
          }}
          callback={() => {
            logout();
            history.push('/login');
          }}
        />
      ) : null}

      <Container style={{ margin: '30px 0' }}>
        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
          <Header as={'h1'}>Project Financials Report</Header>
          <div>
            <CalculationsHelpModal calculationExplanations={calculationExplanations} />
          </div>
        </div>

        <Accordion styled fluid>
          <Accordion.Title active={activeAccordionIndex === 0} index={0} onClick={toggleAccordion}>
            <Icon name="dropdown" />
            <Icon name="settings" />
            Report Options
            <Loader
              active={state.isLoading.form}
              inline
              size="mini"
              style={{ marginLeft: '10px' }}
            />
          </Accordion.Title>
          <Accordion.Content active={activeAccordionIndex === 0}>
            <div>
              {/* Project selection form */}
              <Message
                icon="info circle"
                header="Project Selection"
                content="Select the projects you want to include in the report. You can filter the list of projects by Client, Account Lead, or Project Lead. If the project you are looking for is not in the dropdown list, this usually means the 10,000ft project ID is not listed in the Project Revenue spreadsheet."
                style={{ boxShadow: 'none' }}
              />

              <Formik
                enableReinitialize
                initialValues={{ ...initialValuesFormik, ...filtersInitialValues }}
                onSubmit={async values => {
                  const { projects, clients, al, pl } = values;
                  // const projectCodeToTenkftId = getProjectCodeToTenkftId(state.projects);
                  const projectCodeToTeamworkId = getProjectCodeToTeamworkId(state.projects);
                  await generateReport(projects, { clients, al, pl }, projectCodeToTeamworkId);
                }}
              >
                {({ values, handleSubmit, setFieldValue, errors, touched }) => (
                  <Form onSubmit={handleSubmit} loading={state.isLoading.form} className="attached">
                    <div>
                      <Segment color="blue">
                        {/* Filters accordion */}
                        <Accordion
                          style={{
                            boxShadow: 'none',
                            marginTop: '0',
                            background: activeFilterAccordionIndex === 0 ? '#f7f7f7' : '',
                            borderRadius: activeFilterAccordionIndex === 0 ? '0.3em' : '',
                          }}
                        >
                          <Accordion.Title
                            active={activeFilterAccordionIndex === 0}
                            index={0}
                            onClick={(e, titleProps) =>
                              setActiveFilterAccordionIndex(
                                activeFilterAccordionIndex === titleProps.index
                                  ? -1
                                  : titleProps.index
                              )
                            }
                            style={{
                              paddingLeft: '3px',
                            }}
                          >
                            <Icon name="filter" />
                            Filters ({values.clients.length + values.al.length + values.pl.length})
                            <Icon name="dropdown" />
                          </Accordion.Title>
                          <Accordion.Content
                            active={activeFilterAccordionIndex === 0}
                            style={{
                              padding: '0 10px',
                              paddingTop: '5px',
                              paddingBottom: '1px',
                            }}
                          >
                            <FilterOptionsForm
                              isLoadingClients={state.isLoading.form}
                              projects={state.projects}
                              clients={clientOptions}
                              accountLeads={accountLeadOptions}
                              projectLeads={projectLeadOptions}
                              handleFilterValuesChange={handleFilterValuesChange}
                              formProps={{ values, setFieldValue, errors, touched }}
                              state={state}
                            />
                          </Accordion.Content>
                        </Accordion>

                        <Form.Group grouped>
                          {/* Contract+Project */}
                          <div style={{ display: 'flex', flexDirection: 'row' }}>
                            <Form.Field
                              control={Select}
                              name="projects"
                              label={`Projects (${projectOptions.length})`}
                              options={projectOptions}
                              placeholder="Select project(s)..."
                              value={values.projects}
                              required
                              onChange={(e, { name, value }) => setFieldValue(name, value)}
                              search
                              multiple
                              className="contract-project-select"
                            />
                            {errors.projects && touched.projects ? (
                              <div style={{ fontSize: '10px', color: 'red' }}>
                                {errors.projects}
                              </div>
                            ) : null}
                            <div style={{ display: 'flex', flexDirection: 'column' }}>
                              <Button
                                type="button"
                                style={{ marginLeft: '5px', marginTop: '2.2em' }}
                                icon
                                disabled={values.projects.length === 0}
                                onClick={() => {
                                  setFieldValue('projects', []);
                                  setFieldValue('allProjects', false);
                                }}
                              >
                                <Icon name="close" />
                              </Button>
                            </div>
                          </div>

                          {/* All Projects Checkbox */}
                          <Form.Field>
                            <Checkbox
                              name="allProjects"
                              label="All Projects"
                              checked={
                                values.projects.length > 0 &&
                                (values.allProjects ||
                                  values.projects.length === projectOptions.length)
                              }
                              onChange={(e, { name, checked }) => {
                                setFieldValue(name, checked);
                                if (checked) {
                                  setFieldValue(
                                    'projects',
                                    projectOptions.map(p => p.value)
                                  );
                                } else {
                                  setFieldValue('projects', []);
                                }
                              }}
                              toggle
                            />
                          </Form.Field>
                        </Form.Group>

                        <Divider />

                        <div style={{ marginTop: '30px' }}>
                          <Button
                            type="submit"
                            primary
                            icon
                            labelPosition="right"
                            disabled={values.projects.length === 0}
                          >
                            Generate Report
                            <Icon name="line graph" />
                          </Button>
                        </div>
                      </Segment>
                    </div>
                  </Form>
                )}
              </Formik>
            </div>
          </Accordion.Content>
        </Accordion>

        {/* Project Financials Chart */}
        {generateReportHeader()}

        <div>
          {Object.keys(state.reportData).length === 0 ? (
            state.isLoading.form ? (
              <Segment placeholder style={{ height: '500px' }}>
                <Header icon>
                  <div>
                    <Loader active inline="centered" style={{ marginBottom: '20px' }} />
                    Generating report...
                  </div>
                </Header>
              </Segment>
            ) : (
              <Segment placeholder style={{ height: '500px' }}>
                <Header icon>
                  {/* <Icon name="chart area" /> */}

                  {queryProject ? (
                    <div>
                      <Loader active inline="centered" style={{ marginBottom: '20px' }} />
                      Generating report...
                    </div>
                  ) : (
                    <div>
                      <Icon name="chart area" />
                      Select a Client and Project above to view the report.
                    </div>
                  )}
                </Header>
              </Segment>
            )
          ) : state.isLoading.form ? (
            <Segment placeholder style={{ height: '500px' }}>
              <Header icon>
                <div>
                  <Loader active inline="centered" style={{ marginBottom: '20px' }} />
                  Generating report...
                </div>
              </Header>
            </Segment>
          ) : (
            <Fragment>
              <ProjectFinancialsChartViewOptions
                handleToggleViewOption={handleToggleViewOption}
                viewOptions={state.viewOptions}
                isMultiReport={Object.keys(state.reportData).length > 1}
              />
              <FinancialsReportSwitch
                reportData={state.reportData}
                getProjectNameFrom10kftId={tenkftProjectId => {
                  const projectIdToProject = getProjectIdToProject(state.projects);
                  const tenkftProjectIdsDict = getTenkftIdToProjectId(state.projects);

                  const projectId = tenkftProjectIdsDict[tenkftProjectId];
                  const project = projectIdToProject[projectId];

                  return project['Project Name'];
                }}
                viewOptions={state.viewOptions}
              />
            </Fragment>
          )}
        </div>
        <br />
        <br />
        <br />
      </Container>
    </Fragment>
  );
}

export default ProjectFinancialsReport;
