import initialState from './initialState';

function _toggleViewOption(state, optionName) {
  return {
    ...state,
    viewOptions: { ...state.viewOptions, [optionName]: !state.viewOptions[optionName] },
  };
}

/**
 * Reducer for useReference in ProjectFinancialsReport component.
 */
function reducer(state, action) {
  switch (action.type) {
    case 'SET_INITIAL_STATE':
      return { ...initialState };
    case 'UPDATE_STATE':
      return { ...state, ...action.payload };
    case 'SET_LOADER':
      const { loaderName, newLoaderState } = action.payload;
      return {
        ...state,
        isLoading: { ...state.isLoading, [loaderName]: newLoaderState },
      };
    case 'SET_SELECTED_PROJECTS':
      return {
        ...state,
        selectedProjects: action.payload,
      };
    case 'SET_REPORT_DATA':
      return {
        ...state,
        reportData: action.payload,
      };
    case 'TOGGLE_VIEW_OPTION':
      const { optionName } = action.payload;

      if (optionName.includes('costType/')) {
        const optionInfo = optionName.split('/');
        return {
          ...state,
          viewOptions: {
            ...state.viewOptions,
            costType: optionInfo[1],
          },
        };
      } else {
        return _toggleViewOption(state, optionName);
      }
    default:
      return state;
  }
}

export default reducer;
