import { getTeamMemberFromTag } from '../../utils/generate-report-helpers';

function formatTeamworkDate(dateStr) {
  if (dateStr.length === 0) return '';

  const year = dateStr.slice(0, 4);
  const month = dateStr.slice(4, 6);
  const day = dateStr.slice(6);

  return `${month}-${day}-${year}`;
}

function getSortedTableRows(tableRows) {
  const sortedRows = tableRows;

  // Sort by task name
  sortedRows.sort((a, b) => {
    if (a['Task'] < b['Task']) return -1;
    if (a['Task'] > b['Task']) return 1;
    return 0;
  });

  // Sort by task tag
  sortedRows.sort((a, b) => {
    if (a['Task Tag'] < b['Task Tag']) return -1;
    if (a['Task Tag'] > b['Task Tag']) return 1;
    return 0;
  });

  // Sort by project
  sortedRows.sort((a, b) => {
    if (a['Project'] < b['Project']) return -1;
    if (a['Project'] > b['Project']) return 1;
    return 0;
  });

  // Sort by client
  sortedRows.sort((a, b) => {
    if (a['Client'] < b['Client']) return -1;
    if (a['Client'] > b['Client']) return 1;
    return 0;
  });

  return sortedRows;
}

function filterProjects(projects, filters) {
  const { clients, accountLeads, projectLeads } = filters;

  return projects.filter(project => {
    const results = {
      clients: false,
      accountLeads: false,
      projectLeads: false,
    };

    // Check client
    if (clients.length > 0) {
      if (clients.some(cId => cId === `${project.clientId}`)) {
        results.clients = true;
      }
    } else results.clients = true; // no client filters, so ignore

    // Check account lead
    if (accountLeads.length > 0) {
      if (accountLeads.some(al => project.tags.map(t => t.id).includes(al))) {
        results.accountLeads = true;
      }
    } else results.accountLeads = true; // no AL filters, so ignore

    // Check project lead
    if (projectLeads.length > 0) {
      if (projectLeads.some(pl => project.tags.map(t => t.id).includes(pl))) {
        results.projectLeads = true;
      }
    } else results.projectLeads = true; // no PL filters, so ignore

    return Object.values(results).every(r => r);
  });
}

function getClientNameFromId(clients, clientId) {
  if (!clients || !clientId) return '';

  const foundClient = clients.find(c => c.id === clientId);
  return foundClient ? foundClient.name : '';
}

export default function generateReportRows(state, filters) {
  const { tableData, cache } = state;
  const { projects, companies } = cache;

  // Add rows for projects that have no tasks
  const projectsWithNoTasks = [];
  const projectsAlreadyIncluded = new Set(tableData.map(td => td.project.id));
  const projectsFiltered = filterProjects(projects, filters);
  for (const project of projectsFiltered) {
    if (!projectsAlreadyIncluded.has(parseInt(project.id))) {
      projectsWithNoTasks.push({
        Client: getClientNameFromId(companies, project.clientId),
        Project: project.name,
        'Project Lead': getTeamMemberFromTag(project.tags, 'PL: '),
        'Programming Lead US': getTeamMemberFromTag(project.tags, 'LPgmUS: '),
        'Programming Lead IN': getTeamMemberFromTag(project.tags, 'LPgmIN: '),
        'Task Tag': 'None',
        Task: 'None',
        'Start Date': '',
        'Due Date': '',
        'Tasks Complete': 0,
        'Tasks Remaining': 0,
        'Progress of Remaining Tasks': 'N/A',
        Milestone: '',
        'Milestone Date': '',
        'Milestone Tag': '',
        'Programmer/Statistician': '',
        Priority: '',
        'Update/Comments': '',
      });
    }
  }

  // Row data is sorted in the generateReportData function, which runs before
  // this function.
  const tableRows = tableData.map(td => {
    const tasksRemaining = td.taskStatus.total - td.taskStatus.completed;
    const tasksRemainingProgress = tasksRemaining === 0 ? '100%' : `${td.taskStatus.avgProgress}%`;

    return {
      Client: td.client.name,
      Project: td.project.name,
      'Project Lead': td.project.lead,
      'Programming Lead US': td.project.programmingLeadUS,
      'Programming Lead IN': td.project.programmingLeadIN,
      'Task Tag': td.taskTag,
      Task: td.name,
      'Start Date': formatTeamworkDate(td.startDate),
      'Due Date': formatTeamworkDate(td.dueDate),
      'Tasks Complete': td.taskStatus.completed,
      'Tasks Remaining': tasksRemaining,
      'Progress of Remaining Tasks': tasksRemainingProgress,
      Milestone: td.milestone.name,
      'Milestone Date': formatTeamworkDate(td.milestone.date),
      'Milestone Tag': td.milestone.tag,
      'Programmer/Statistician': td.teamMembers.join(', '),
      Priority: '',
      'Update/Comments': '',
    };
  });

  const sortedRows = getSortedTableRows([...tableRows, ...projectsWithNoTasks]);

  return { ...state, tableRows: sortedRows };
}
