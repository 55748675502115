import axios from 'axios';

/**
 * Gets a list of all clients listed in the Project Revenue spreadsheet.
 * @param {String} token Instat Apps API token
 * @returns Array of people including firstName, lastName, id
 */
export async function getProjectRevenueClients(token) {
  const request = {
    url: '/api/project-revenue/clients',
    method: 'get',
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  const response = await axios(request);
  return response.data.clients;
}

/**
 * Gets all projects from Project Revenue spreadsheet.
 * @param {string} token Authentication token for current Google SSO session
 * @returns Array of project objects (rows)
 */
export async function getProjectRevenueProjects(token) {
  const request = {
    url: '/api/project-revenue/projects',
    method: 'get',
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  const response = await axios(request);
  return response.data.projects;
}

/**
 * Gets financial report data for the given project.
 * @param {string} token Instat Apps API token
 * @param {object} projects Array of projects for report [{contractProject, tenkftProjectId}]
 * @returns Report data object or false if error encountered
 */
export async function getProjectRevenueReport(token, projects) {
  const request = {
    url: `/api/reports/project-financials`,
    method: 'get',
    headers: {
      Authorization: `Bearer ${token}`,
    },
    params: {
      projects: encodeURIComponent(projects.join(',')),
    },
  };

  try {
    const response = await axios(request);
    return response.data.reportData;
  } catch (error) {
    console.error(error);
    return false;
  }
}

export async function getProjectRevenueRows(token, range) {
  const request = {
    url: `/api/project-revenue/get-rows`,
    method: 'get',
    headers: {
      Authorization: `Bearer ${token}`,
    },
    params: {
      range: encodeURIComponent(range),
    },
  };

  try {
    const response = await axios(request);
    return response.data.rows;
  } catch (error) {
    console.error(error);
    return false;
  }
}
