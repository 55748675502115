import React from 'react';
import PropTypes from 'prop-types';
import { Form, Select, Button } from 'semantic-ui-react';

function FilterOptionsForm({ formProps, handleReset, cache, isLoading }) {
  const clientOptions = cache.companies.map(c => ({ key: c.id, value: c.id, text: c.name }));
  const accountLeadOptions = cache.accountLeads.map(al => {
    const text = al.name.slice(4);
    return { key: al.id, value: al.id, text };
  });
  const projectLeadOptions = cache.projectLeads.map(pl => {
    const text = pl.name.slice(4);
    return { key: pl.id, value: pl.id, text };
  });

  const handleFilterOnChange = (e, { name, value }) => {
    formProps.setFieldValue(name, value);
  };

  return (
    <React.Fragment>
      <Button
        onClick={handleReset}
        type="button"
        size="tiny"
        compact
        style={{ marginBottom: '10px' }}
        secondary
      >
        Clear Filters
      </Button>

      <Form.Group widths="equal">
        <Form.Field
          control={Select}
          name="clients"
          label="Clients"
          options={clientOptions}
          placeholder={isLoading ? 'Loading...' : 'Select clients...'}
          value={formProps.values.clients}
          onChange={handleFilterOnChange}
          search
          multiple
          disabled={isLoading}
        />
        {formProps.errors.clients && formProps.touched.clients ? (
          <div style={{ fontSize: '10px', color: 'red' }}>{formProps.errors.clients}</div>
        ) : null}

        {/* Empty field to improve spacing for Client field. */}
        <Form.Field />
      </Form.Group>

      <Form.Group widths="equal">
        <Form.Field
          control={Select}
          name="accountLeads"
          label="Account Leads"
          options={accountLeadOptions}
          placeholder={isLoading ? 'Loading...' : 'Select account leads...'}
          value={formProps.values.accountLeads}
          onChange={handleFilterOnChange}
          search
          multiple
          disabled={isLoading}
        />
        {formProps.errors.accountLeads && formProps.touched.accountLeads ? (
          <div style={{ fontSize: '10px', color: 'red' }}>{formProps.errors.accountLeads}</div>
        ) : null}

        <Form.Field
          control={Select}
          name="projectLeads"
          label="Project Leads"
          options={projectLeadOptions}
          placeholder={isLoading ? 'Loading...' : 'Select project leads...'}
          value={formProps.values.projectLeads}
          onChange={handleFilterOnChange}
          search
          multiple
          disabled={isLoading}
        />
        {formProps.errors.clients && formProps.touched.clients ? (
          <div style={{ fontSize: '10px', color: 'red' }}>{formProps.errors.clients}</div>
        ) : null}
      </Form.Group>
    </React.Fragment>
  );
}

FilterOptionsForm.propTypes = {
  handleReset: PropTypes.func.isRequired,
  cache: PropTypes.object.isRequired, // would be best to include shape of object
  isLoading: PropTypes.bool.isRequired,
};

export default FilterOptionsForm;
