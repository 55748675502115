import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { Container, Button, Icon, Card, Header, Message } from 'semantic-ui-react';
import { verifyRoles } from '../../utils/ui';
import { appLinks } from '../../data/app-links';
import { appLinkSections } from '../../data/app-link-sections';
import { sortObject } from '../../utils/array-sorting';
import Emoji from '../../components/Emoji';
import { useAuth } from '../../context/AuthContext';

export const Home = () => {
  document.title = 'Instat Apps - Home';

  const { user } = useAuth();

  // Filter out app links user does not have permission to access
  const userAppButtons =
    user && user.roles
      ? appLinks.filter(appButton =>
          appButton.roles.length === 0 ? appButton : verifyRoles(user.roles, appButton.roles)
        )
      : [];

  // Group by section and sort by app name
  const userAppButtonGroups = userAppButtons.reduce((acc, link) => {
    const { section } = link;
    if (section in acc) {
      acc[section].push(link);
      return acc;
    } else {
      return {
        ...acc,
        [section]: [link],
      };
    }
  }, {});
  for (const section in userAppButtonGroups) {
    userAppButtonGroups[section].sort((a, b) => sortObject(a, b, 'name'));
  }

  return (
    <Container>
      <Message style={{ marginBottom: '10px' }}>
        <Header as="h2" style={{ marginBottom: '8px' }}>
          Hi, {user.firstName}! <Emoji symbol="👋" label="wave" />
        </Header>
        <p>
          Welcome to Instat Apps. Click on any of the buttons below to go to an app. Or you can use
          the dropdown above.
        </p>
        <p>
          If you do not see any apps below and you feel like you should, please reach out to Jamie
          or Kyle.
        </p>
      </Message>

      {userAppButtons.length > 0 ? (
        Object.keys(userAppButtonGroups).map(section => {
          return (
            <div key={section} className="home-card-group">
              <Header as="h2">
                <Icon
                  name={appLinkSections[section] ? appLinkSections[section] : 'dot circle outline'}
                />
                {section === '' ? 'Miscellaneous' : section}
              </Header>

              <Card.Group key={`${section}`} style={{ marginTop: '16px', marginBottom: '16px' }}>
                {userAppButtonGroups[section].map((appButton, index) => (
                  <Card key={`${section}-${index}`}>
                    <Card.Content>
                      <Card.Header>{appButton.name}</Card.Header>
                      <Card.Description>{appButton.description}</Card.Description>
                    </Card.Content>
                    <Card.Content extra>
                      <div>
                        <Button
                          icon
                          labelPosition="right"
                          size="medium"
                          color="blue"
                          as={Link}
                          to={appButton.link}
                          className="ui right floated"
                        >
                          <Icon name="arrow alternate circle right outline" />
                          Launch
                        </Button>
                      </div>
                    </Card.Content>
                  </Card>
                ))}
              </Card.Group>
            </div>
          );
        })
      ) : (
        <div>No apps available.</div>
      )}

      <br />
      <br />
    </Container>
  );
};

Home.propTypes = {
  user: PropTypes.shape({
    firstName: PropTypes.string,
    imageUrl: PropTypes.string,
  }),
};

Home.defaultProps = {
  user: {
    firstName: '',
    imageUrl: '',
  },
};

export default Home;
