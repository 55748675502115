import React from 'react';
import { Container, Header, Message } from 'semantic-ui-react';

function MetricsDashboard() {
  return (
    <Container style={{ margin: '30px 0', display: 'flex', flexDirection: 'column' }}>
      <Header as={'h1'}>Metrics Dashboard</Header>
      <Message
        info
        icon="info circle"
        content="The metrics below may take a few moments to load in."
      />
      <hr />
      {/* Monthly GAAP Revenue (Invoicing) - Current Year */}
      <iframe
        height="456"
        seamless
        frameborder="0"
        scrolling="no"
        src="https://docs.google.com/spreadsheets/d/e/2PACX-1vT_DsDTjvR-WdegKBpnFd3nhPkvemyQH5YJ8Sek-8PaZ6lQ1cb7KIRTo5-C1kqKIJ-RzrVqe_6OWQu9/pubchart?oid=820039035&amp;format=interactive"
      ></iframe>
      <hr />
      {/* Backlog by Services */}
      <iframe
        height="371"
        seamless
        frameBorder="0"
        scrolling="no"
        src="https://docs.google.com/spreadsheets/d/e/2PACX-1vT_DsDTjvR-WdegKBpnFd3nhPkvemyQH5YJ8Sek-8PaZ6lQ1cb7KIRTo5-C1kqKIJ-RzrVqe_6OWQu9/pubchart?oid=1238538342&amp;format=interactive"
      />
      <hr />
      {/* This Month's Invoicing */}
      <iframe
        height="800"
        seamless
        frameBorder="0"
        src="https://docs.google.com/spreadsheets/d/e/2PACX-1vT_DsDTjvR-WdegKBpnFd3nhPkvemyQH5YJ8Sek-8PaZ6lQ1cb7KIRTo5-C1kqKIJ-RzrVqe_6OWQu9/pubhtml?gid=889539421&amp;single=true&amp;widget=true&amp;headers=false"
      />
      <hr />
      {/* Current Year's Invoicing */}
      <iframe
        height="800"
        seamless
        frameBorder="0"
        src="https://docs.google.com/spreadsheets/d/e/2PACX-1vT_DsDTjvR-WdegKBpnFd3nhPkvemyQH5YJ8Sek-8PaZ6lQ1cb7KIRTo5-C1kqKIJ-RzrVqe_6OWQu9/pubhtml?gid=921053543&amp;single=true&amp;widget=true&amp;headers=false"
      />
    </Container>
  );
}

export default MetricsDashboard;
