/**
 * P R O J E C T   D I C T I O N A R I E S
 * Functions for creating useful project dictionaries.
 */

/**
 * Create dictionary for the following:
 * ProjectId -> Project row object
 *
 * @param {[object]} projects Rows from the Project Revenue spreadsheet's Projects sheet
 * @returns Dictionary object
 */
export function getProjectIdToProject(projects) {
  return projects.reduce((acc, project) => {
    const projectId = project['ProjectId'];

    if (projectId === '') {
      return acc;
    } else {
      return { ...acc, [projectId]: project };
    }
  }, {});
}

/**
 * Create dictionary for the following:
 * ProjectCode -> 10kftProjectId
 *
 * @param {[object]} projects Rows from the Project Revenue spreadsheet's Projects sheet
 * @returns Dictionary object
 */
export function getProjectCodeToTenkftId(projects) {
  return projects.reduce((acc, project) => {
    const tenkftProjectId = project['10kftProjectId'];
    const projectCode = project['ProjectCode'];

    if (tenkftProjectId !== '' && projectCode !== '' && !(projectCode in acc)) {
      return { ...acc, [projectCode]: tenkftProjectId };
    } else {
      return acc;
    }
  }, {});
}

/**
 * Create dictionary for the following:
 * ProjectCode -> TWProjectId
 *
 * @param {[object]} projects Rows from the Project Revenue spreadsheet's Projects sheet
 * @returns Dictionary object
 */
export function getProjectCodeToTeamworkId(projects) {
  return projects.reduce((acc, project) => {
    const teamworkProjectId = project['TWProjectId'];
    const projectCode = project['ProjectCode'];

    if (teamworkProjectId !== '' && projectCode !== '' && !(projectCode in acc)) {
      return { ...acc, [projectCode]: teamworkProjectId };
    } else {
      return acc;
    }
  }, {});
}

/**
 * Create dictionary for the following:
 * ProjectCode -> Project Name
 *
 * @param {[object]} projects Rows from the Project Revenue spreadsheet's Projects sheet
 * @returns Dictionary object
 */
export function getProjectCodeToProjectName(projects) {
  return projects.reduce((acc, project) => {
    const projectCode = project['ProjectCode'];
    const projectName = project['Project Name'];

    if (projectCode !== '' && !(projectCode in acc)) {
      return { ...acc, [projectCode]: projectName };
    } else {
      return acc;
    }
  }, {});
}

/**
 * Create dictionary for the following:
 * 10kftProjectId -> ProjectId
 *
 * @param {[object]} projects Rows from the Project Revenue spreadsheet's Projects sheet
 * @returns Dictionary object
 */
export function getTenkftIdToProjectId(projects) {
  return projects.reduce((acc, project) => {
    if (project.teamworkData && project['10kftProjectId'] !== '') {
      return { ...acc, [project['10kftProjectId']]: project['ProjectId'] };
    } else {
      return acc;
    }
  }, {});
}

/**
 * Create dictionary for the following:
 * TWProjectId -> ProjectId
 *
 * @param {[object]} projects Rows from the Project Revenue spreadsheet's Projects sheet
 * @returns Dictionary object
 */
export function getTeamworkIdToProjectId(projects) {
  return projects.reduce((acc, project) => {
    if (project.teamworkData && project['TWProjectId'] !== '') {
      return { ...acc, [project['TWProjectId']]: project['ProjectId'] };
    } else {
      return acc;
    }
  }, {});
}

/**
 * A C C O U N T / P R O J E C T   L E A D   D I C T I O N A R I E S
 */

/**
 * Create dictionary for the following:
 * Account Lead -> ProjectId
 *
 * @param {[object]} projects Rows from the Project Revenue spreadsheet's Projects sheet
 * @returns Dictionary object
 */
export function getAccountLeadToProjectId(projects) {
  return projects.reduce((acc, project) => {
    const { teamworkData } = project;
    if (teamworkData && teamworkData !== null && teamworkData.tags) {
      const projectTags = teamworkData.tags.map(t => t.name).filter(t => t.includes('AL:'));

      if (projectTags.length > 0) {
        const accountLead = projectTags[0];
        if (accountLead in acc) {
          return { ...acc, [accountLead]: [...acc[accountLead], project['ProjectId']] };
        } else {
          return { ...acc, [accountLead]: [project['ProjectId']] };
        }
      } else {
        return acc;
      }
    } else {
      return acc;
    }
  }, {});
}

/**
 * Create dictionary for the following:
 * Project Lead -> ProjectId
 *
 * @param {[object]} projects Rows from the Project Revenue spreadsheet's Projects sheet
 * @returns Dictionary object
 */
export function getProjectLeadToProjectId(projects) {
  return projects.reduce((acc, project) => {
    const { teamworkData } = project;
    if (teamworkData && teamworkData !== null && teamworkData.tags) {
      const projectTags = teamworkData.tags.map(t => t.name).filter(t => t.includes('PL:'));

      if (projectTags.length > 0) {
        const projectLead = projectTags[0];
        if (projectLead in acc) {
          return { ...acc, [projectLead]: [...acc[projectLead], project['ProjectId']] };
        } else {
          return { ...acc, [projectLead]: [project['ProjectId']] };
        }
      } else {
        return acc;
      }
    } else {
      return acc;
    }
  }, {});
}
