export default function setProjectOptions(state, projectRows) {
  // Get unique project options
  const projectOptions = [];
  const projectCodes = new Set();
  for (const row of projectRows) {
    if (!projectCodes.has(row['ProjectCode'])) {
      projectCodes.add(row['ProjectCode']);
      projectOptions.push({
        key: row['ProjectCode'],
        value: row['ProjectCode'],
        text: row['Project Name'],
      });
    }
  }
  projectOptions.sort((a, b) => {
    const optionA = a.text.toLowerCase();
    const optionB = b.text.toLowerCase();

    if (optionA < optionB) return -1;
    else if (optionA > optionB) return 1;
    else return 0;
  });

  return {
    ...state,
    projects: projectRows,
    options: {
      ...state.options,
      projects: projectOptions,
    },
  };
}
