import React from 'react';
import PropTypes from 'prop-types';
import {
  ResponsiveContainer,
  ComposedChart,
  Area,
  Line,
  CartesianGrid,
  XAxis,
  YAxis,
  Tooltip,
  ReferenceLine,
  Label,
} from 'recharts';
import ProjectFinancialsChartTooltip from './ProjectFinancialsChartTooltip';
import { useAuth } from '../../../context/AuthContext';

function ProjectFinancialsChart({ reportData, viewOptions }) {
  const auth = useAuth();
  const { roles } = auth.user;

  const { monthlyData, budget } = reportData;
  const today = new Date();
  const todayMonth = today.getMonth() + 1;
  const todayYear = today.getFullYear();
  const showBreakevenCosts = roles.some(
    r => r === 'leadership' || r === 'admin' || r === 'partners'
  );

  function getCashNeutralStatus() {
    const date = `${todayMonth}/${todayYear}`;
    const { monthlyData } = reportData;
    const data = monthlyData.find(item => item.date === date);
    if (data) {
      return data['Payments'] - data['Costs'];
    } else {
      return '';
    }
  }

  return (
    <ResponsiveContainer height={500} width="100%">
      <ComposedChart data={monthlyData}>
        <Area type="monotone" dataKey="Payments" stroke="#70bd9d" fill="#70bd9d" stackId="1" />
        <Area type="monotone" dataKey="Costs" stroke="#e47765" fill="#e47765" stackId="2" />
        <Area type="monotone" dataKey="Expenses" stroke="#e49c65" fill="#e49c65" stackId="2" />
        {showBreakevenCosts && (
          <Line
            type="monotone"
            dataKey="Breakeven Costs"
            dot={false}
            stroke="#000"
            strokeWidth={3}
          />
        )}
        {viewOptions.showCartesianGrid && <CartesianGrid strokeDasharray="1 5" />}
        <XAxis dataKey="date" />
        <YAxis
          axisLine={false}
          tickLine={false}
          tickFormatter={val => val.toLocaleString('en-US', { style: 'currency', currency: 'USD' })}
          width={100}
        />
        <Tooltip
          content={<ProjectFinancialsChartTooltip showBreakevenCosts={showBreakevenCosts} />}
        />
        <ReferenceLine
          y={budget}
          // stroke="#e099ff"
          stroke="#4c99d4"
          strokeDasharray="5 5"
          ifOverflow="extendDomain"
        >
          <Label
            value={`Budget: ${budget.toLocaleString('en-US', {
              style: 'currency',
              currency: 'USD',
            })}`}
            position="insideTopLeft"
            style={{ fill: '#4c99d4' }}
          />
        </ReferenceLine>
        <ReferenceLine
          x={`${todayMonth}/${todayYear}`}
          // stroke="#b37acc"
          stroke="#4c99d4"
          strokeWidth={2}
          ifOverflow="extendDomain"
        >
          <Label
            value={`As of today: ${getCashNeutralStatus().toLocaleString('en-US', {
              style: 'currency',
              currency: 'USD',
            })}`}
            position="insideTopRight"
            style={{ fill: '#4c99d4' }}
          />
        </ReferenceLine>
      </ComposedChart>
    </ResponsiveContainer>
  );
}

ProjectFinancialsChart.propTypes = {
  reportData: PropTypes.shape({
    mothlyData: PropTypes.arrayOf(
      PropTypes.shape({
        date: PropTypes.string,
        costs: PropTypes.number,
        payments: PropTypes.number,
      })
    ),
    budget: PropTypes.number.isRequired,
  }).isRequired,
};

export default ProjectFinancialsChart;
