const initialState = {
  report: {
    timestamp: '',
    elapsedTime: 0,
    data: {
      endDate: '',
      startDate: '',
      tableData: [],
    },
  },
  tableSort: {
    columnForSort: 'startDate',
    direction: 'descending',
  },
  filters: {
    endDate: new Date(),
    startDate: new Date(),
  },
  isLoading: {
    report: true,
  },
};

export default initialState;
