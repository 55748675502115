import initialState from './initialState';
import setReportData from './reducers/set-report-data';
import updateFilters from './reducers/update-filters';
import setReportAndFilters from './reducers/set-report-and-filters';
import changeTableSort from './reducers/change-table-sort';
import { setIsLoadingReport } from './reducers/loaders';

function reducer(state, action) {
  switch (action.type) {
    case 'SET_INITIAL_STATE':
      return { ...initialState };
    case 'SET_REPORT':
      return setReportData(state, action.report);
    case 'UPDATE_FILTERS':
      return updateFilters(state, action.filters);
    case 'SET_REPORT_AND_FILTERS':
      return setReportAndFilters(state, action.report, action.filters);
    case 'SET_IS_LOADING_REPORT':
      return setIsLoadingReport(state, action.isLoading);
    case 'CHANGE_TABLE_SORT':
      return changeTableSort(state, action.columnForSort);
    default:
      return state;
  }
}

export default reducer;
