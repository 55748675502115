import React, { useReducer, useEffect, useState } from 'react';
import { useToasts } from 'react-toast-notifications';
import { Button, Header, Icon, Dimmer, Loader, Form, Select, Message } from 'semantic-ui-react';
import { Formik } from 'formik';
import axios from 'axios';
import reducer from './state/reducer';
import initialState from './state/initialState';
import { getProjectRevenueProjects } from '../../services/api/project-revenue';
import { getCostAnalysisReport } from '../../services/api/reports/cost-analysis';
import {
  getProjectCodeTo10kftProjectIdsDictionary,
  getProjectCodeToProjectNameDictionary,
} from './utils/dict';
import CostAnalysisReportTable from './components/CostAnalysisReportTable';
import { openInSheets } from '../../services/googlesheets/open-in-sheets';
import { getOpenInSheetsFormatRequests } from './utils/google-sheets-requests';
import CalculationsHelpModal from '../../components/CalculationsHelpModal';
import calculationExplanations from './data/calculation-explanations';
import { useAuth, useAuthFunctions } from '../../context/AuthContext';

function CostAnalysisReport({ history }) {
  document.title = 'Instat Apps - Cost Analysis Report';
  const todayDate = new Date().toISOString().slice(0, 10);

  const { addToast } = useToasts();
  const auth = useAuth();
  const { token, email: userEmail } = auth.user;
  const { logout } = useAuthFunctions();

  const [state, dispatch] = useReducer(reducer, initialState);
  const [projectCodeToIdDict, setProjectCodeToIdDict] = useState({});
  const [projectCodeToNameDict, setProjectCodeToNameDict] = useState({});
  const [projectsAreLoading, setProjectsAreLoading] = useState(true);
  const [formIsLoading, setFormIsLoading] = useState(false);
  const [selectedProjectName, setSelectedProjectName] = useState('');
  const [openInSheetsIsLoading, setOpenInSheetsIsLoading] = useState(false);

  useEffect(() => {
    async function main() {
      // Get list of projects from Project Revenue spreadsheet
      const projectRevenueProjects = await getProjectRevenueProjects(token);
      const projects = projectRevenueProjects.filter(p => p['10kftProjectId'] !== '');
      // console.log('projects from getProjectRevenueProjects function:');
      // console.log(projects);

      setProjectCodeToIdDict(getProjectCodeTo10kftProjectIdsDictionary(projects));
      setProjectCodeToNameDict(getProjectCodeToProjectNameDictionary(projects));

      dispatch({
        type: 'SET_PROJECTS_OPTIONS',
        projects,
      });
    }

    main()
      .then(() => {
        setProjectsAreLoading(false);
      })
      .catch(err => {
        const errorMsg = err.message;

        if (errorMsg.includes('status code 401')) {
          addToast('Your session has expired. Please sign in again.', {
            appearance: 'error',
            autoDismiss: true,
          });
          logout();
          history.push('/login');
        } else {
          addToast(err.message, { appearance: 'error', autoDismiss: true });
        }
        console.error(err);
      });
  }, [history, logout, token]);

  const handleOpenInSheets = () => {
    setOpenInSheetsIsLoading(true);

    openInSheets(token, {
      title: `Cost Analysis Report: ${selectedProjectName} (${todayDate})`,
      values: state.report.googleSheets.values,
      userEmail,
    })
      .then(async res => {
        // Format Google Sheet spreadsheet
        const formatRequests = getOpenInSheetsFormatRequests(
          token,
          userEmail,
          { spreadsheetId: res.spreadsheetId, sheetId: res.sheetId },
          state.report.googleSheets
        );
        // console.log('formatRequests:');
        // console.log(formatRequests);
        const responseFormatSpreadsheet = await axios(formatRequests);
        // console.log(responseFormatSpreadsheet);

        setOpenInSheetsIsLoading(false);

        const newTab = window.open(
          `https://docs.google.com/spreadsheets/d/${res.spreadsheetId}`,
          '_blank',
          'noopener,noreferrer'
        );
        if (newTab) newTab.opener = null;
      })
      .then(() => {
        addToast('Data exported as a new spreadsheet to your Google Drive.', {
          appearance: 'success',
        });
      })
      .catch(err => {
        console.error(err);
        addToast(err.message, { appearance: 'error', autoDismiss: true });
      });
  };
  const handleDownloadCSV = () => {
    const csvDownloadElement = document.createElement('a');
    const file = new Blob([state.report.csv], { type: 'text/csv' });
    csvDownloadElement.href = URL.createObjectURL(file);
    csvDownloadElement.download = `${selectedProjectName} Cost Analysis Report ${todayDate}.csv`;
    csvDownloadElement.click();
  };

  return (
    <div>
      {/* Loader for Open In Sheets button */}
      <Dimmer active={openInSheetsIsLoading} inverted>
        <Loader size="large">Creating Google Sheets spreadsheet...</Loader>
      </Dimmer>

      {/* Project select form */}
      {/* Form submits on project select */}
      <div className="project-selection-form-container">
        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
          <Header as="h1">
            <Header.Content>Cost Analysis Report</Header.Content>
            <Header.Subheader>
              Select the project below, and the cost analysis report will run automatically.
            </Header.Subheader>
          </Header>
          <div>
            <CalculationsHelpModal calculationExplanations={calculationExplanations} />
          </div>
        </div>

        <div style={{ margin: '10px 0' }} />

        <Formik
          enableReinitialize
          initialValues={state.initialValues}
          onSubmit={async (values, actions) => {
            setFormIsLoading(true);
            dispatch({ type: 'RESET REPORT DATA' });

            const projectIds = projectCodeToIdDict[values.project];
            const projectName = projectCodeToNameDict[values.project];

            setSelectedProjectName(projectName);

            getCostAnalysisReport(token, projectIds[0])
              .then(res => {
                if (res.status === 500) throw new Error();
                if (res.status === 401) throw new Error('Please login again.');
                dispatch({ type: 'SET_REPORT', report: res.data });
              })
              .then(() => {
                setFormIsLoading(false);
              })
              .catch(err => {
                const genericErrorMessage =
                  'Oops! Failed to generate cost analysis report for selected project. Please try again.';
                let errorMessage = genericErrorMessage;
                if (err.message !== '' && err.message !== null) {
                  errorMessage = err.message;
                }

                addToast(errorMessage, { appearance: 'error', autoDismiss: true });
                console.error(err);
              });
          }}
        >
          {({ values, handleSubmit, setFieldValue, errors, touched }) => (
            <Form>
              <Form.Field>
                <label>Project ({state.options.projects.length})</label>
                <Select
                  name="project"
                  label={`Project (${state.options.projects.length})`}
                  options={state.options.projects}
                  placeholder={
                    projectsAreLoading ? (
                      <span>
                        Loading projects...{' '}
                        <Loader active={state.options.projects.length < 1} inline size="tiny" />
                      </span>
                    ) : (
                      'Select project...'
                    )
                  }
                  value={values.project}
                  onChange={(e, props) => {
                    setFieldValue(props.name, props.value);
                    setSelectedProjectName('');
                  }}
                  onClose={() => handleSubmit()}
                  search
                  disabled={projectsAreLoading || formIsLoading}
                />
              </Form.Field>
            </Form>
          )}
        </Formik>
        {/* Button bar */}
        <div
          style={{
            marginTop: '10px',
            display: 'flex',
            flexDirection: 'row',
          }}
        >
          <div />
          <div>
            <Button
              icon
              labelPosition="left"
              onClick={handleOpenInSheets}
              disabled={state.report.googleSheets.values.length <= 0}
            >
              <Icon name="google drive" /> Open in Google Sheets
            </Button>
            <Button
              icon
              labelPosition="left"
              onClick={handleDownloadCSV}
              disabled={state.report.csv === ''}
            >
              <Icon name="download" /> Download CSV
            </Button>
          </div>
        </div>
        {state.report.missingRates &&
          state.report.missingRates.values &&
          state.report.missingRates.values.length > 0 && (
            <Message warning>
              <Message.Header>
                <Icon name="warning sign" /> Unmatched Time Entries
              </Message.Header>
              <p>
                There are {state.report.missingRates.values.length} time entries that could not be
                matched and therefore were not included in the report. As a result, the report below
                is incomplete. There are a few possible reasons why this occurred:
              </p>
              <ol>
                <li>Team member missing from the Rates tab in the "Cost Analysis" spreadsheet.</li>
                <li>Could not match 10kft user ID to list of known 10kft users.</li>
              </ol>
              <p>
                The following are the users that could not be matched. Please add rates for these
                users to the "Cost Analysis" spreadsheet.
              </p>
              <ul>
                {Array.from(
                  state.report.missingRates.values.reduce(
                    (acc, missingRate) =>
                      acc.add(
                        missingRate['user_name'] === null ? 'Unknown' : missingRate['user_name']
                      ),
                    new Set()
                  )
                )
                  .filter(userName => userName !== '' && userName !== ' ')
                  .map((userName, index) => (
                    <li key={`${userName}-${index}`}>{userName}</li>
                  ))}
              </ul>
              <p>
                To download a full list of time entries ({state.report.missingRates.values.length})
                missing form the report,{' '}
                <a
                  href={URL.createObjectURL(state.report.missingRates.csvBlob)}
                  download={`Missing Values - ${selectedProjectName} Cost Analysis Report`}
                >
                  click here
                </a>
                .
              </p>
            </Message>
          )}
      </div>

      {/* Table */}
      <CostAnalysisReportTable state={state} formIsLoading={formIsLoading} />

      <br />
      <br />
    </div>
  );
}

export default CostAnalysisReport;
