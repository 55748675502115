import initialState from './initialState';
import setProjectOptions from './reducers/set-project-options';
import setReportData from './reducers/set-report-data';

function reducer(state, action) {
  switch (action.type) {
    case 'SET_INITIAL_STATE':
      return { ...initialState };
    case 'SET_PROJECTS_OPTIONS':
      return setProjectOptions(state, action.projects);
    case 'SET_REPORT':
      return setReportData(state, action.report);
    case 'RESET REPORT DATA':
      return {
        ...state,
        report: {
          timestamp: '',
          data: {},
          missingRates: {
            values: [],
            csvBlob: null,
          },
          tableData: [],
          csv: '',
          googleSheets: [],
        },
      };
    default:
      return state;
  }
}

export default reducer;
