import React from 'react';
import axios from 'axios';
import { Dropdown, Icon } from 'semantic-ui-react';
import columnOrdering from '../column-order';
import { openInSheets } from '../../../services/googlesheets/open-in-sheets';
import { getFormatRequests } from '../utils/google-sheets';
import { useAuth } from '../../../context/AuthContext';

function ExportButton({ reportRows, columnTypes, showPassThru, addToast, lastModified }) {
  const auth = useAuth();
  const { token, email: userEmail } = auth.user;

  const todayDate = new Date().toISOString().slice(0, 10);

  /**
   * Takes all project row data and creates a CSV formatted string for export.
   * @param {array} projects Array of project objects where each object is a row's values with headers for keys.
   * @returns CSV formatted string containing all project row values.
   */
  function generateCSV(projects) {
    const totalsRow = Array(projects.length).fill('');
    const headersRow = columnOrdering.map(c => `"${c}"`).join(',') + '\n';
    const rowsWithoutTotal = projects.reduce((acc, project) => {
      const rowStr = columnOrdering
        .map((header, hIndex) => {
          if (columnTypes[header] === 'number') {
            const currentRowTotal = totalsRow[hIndex];
            if (typeof currentRowTotal === 'string') {
              totalsRow[hIndex] = project[header];
            } else {
              totalsRow[hIndex] += project[header];
            }
          }
          return `"${project[header]}"`;
        })
        .join(',');
      return (acc += rowStr + '\n');
    }, headersRow);

    return rowsWithoutTotal + totalsRow.map(t => `"${t}"`);
  }

  /**
   * Handles creating a CSV file for local download.
   */
  function handleDownloadCSV() {
    const csv = generateCSV(reportRows);
    const fileName = showPassThru
      ? `active_projects_financial_status_with_pass_thru_${todayDate}.csv`
      : `active_projects_financial_status_${todayDate}.csv`;
    const csvDownloadElement = document.createElement('a');
    const file = new Blob([csv], { type: 'text/csv' });
    csvDownloadElement.href = URL.createObjectURL(file);
    csvDownloadElement.download = fileName;
    csvDownloadElement.click();
  }

  /**
   * Handles creating and formatting an exported version of the table in a
   * Google Sheets spreadsheet.
   */
  function handleOpenInSheets() {
    const csv = generateCSV(reportRows);
    const reportValues = csv
      .split('\n')
      .map(row => row.split('",').map(cell => cell.replace(/"/g, '')));
    openInSheets(token, {
      title: `Active Projects Financial Status ${lastModified.toLocaleDateString()}`,
      values: reportValues,
      userEmail,
    })
      .then(async res => {
        const formatRequestsList = getFormatRequests(
          { spreadsheetId: res.spreadsheetId, sheetId: res.sheetId },
          reportRows,
          columnOrdering
        );
        const formatRequests = {
          url: `/api/google-sheets/batch-update-spreadsheet`,
          method: 'post',
          headers: {
            Authorization: `Bearer ${token}`,
          },
          data: {
            spreadsheetId: res.spreadsheetId,
            userEmail,
            requests: formatRequestsList,
          },
        };
        await axios(formatRequests);

        const newTab = window.open(
          `https://docs.google.com/spreadsheets/d/${res.spreadsheetId}`,
          '_blank',
          'noopener,noreferrer'
        );
        if (newTab) newTab.opener = null;
      })
      .catch(err => {
        console.error(err);
        addToast(err.message, { appearance: 'error', autoDismiss: true });
      });
  }

  return (
    <div>
      <Dropdown
        text="Export"
        icon="download"
        floating
        labeled
        button
        secondary="true"
        className="button secondary icon"
      >
        <Dropdown.Menu>
          <Dropdown.Item onClick={handleDownloadCSV}>
            <Icon name="file excel outline" /> CSV
          </Dropdown.Item>
          <Dropdown.Item onClick={handleOpenInSheets}>
            <Icon name="google drive" />
            Google Sheets
          </Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>
    </div>
  );
}

export default ExportButton;
