const initialState = {
  tableData: {
    projects: [],
  },
  filters: {
    projects: [],
    al: [],
    pl: [],
    companies: [],
  },
  dropdownOptions: {
    projects: [],
  },
  tableSorting: {
    column: '',
    direction: '',
  },
  loading: {
    isActive: false,
    message: '',
  },
  dictionaries: {
    projectIdToName: {},
  },
};

export default initialState;
