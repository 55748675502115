import React, { useState } from 'react';
import { Accordion, Button, Header, Icon } from 'semantic-ui-react';
import TaskGroup from './TaskGroup';
import '../styles/report-table.scss';

function ReportTable({ projects }) {
  /**
   * Gets an array of indices for the length of the given array.
   * @param {Array} arr Array to be used to generate array of indices.
   * @returns Array of indices.
   */
  const getFullExpandedArray = arr => Array.from(Array(arr.length).keys());

  /**
   * Gets an array of active indices for each project. The array for each can either be completely
   * full or completely empty. This should be used when either expanding or collapsing completely.
   * @param {boolean} isEmpty Whether or not the array should be empty or full of indices.
   * @returns Object mapping project name to array of indices.
   */
  const getArrayOfActiveTaskIndices = isEmpty => {
    return projects
      .map(({ name, tasks }) => ({
        name,
        numTasks: tasks.length,
      }))
      .reduce(
        (obj, p) => ({ ...obj, [p.name]: isEmpty ? [] : Array.from(Array(p.numTasks).keys()) }),
        {}
      );
  };

  // STATE
  const [activeIndices, setActiveIndices] = useState([0]);
  const [activeTaskIndices, setActiveTaskIndices] = useState(getArrayOfActiveTaskIndices(true));

  /**
   * Handles the accordion title click. When the title is clicked, the section will either
   * expand or collapse (the opposite of what its current state is).
   * @param {*} e
   * @param {*} itemProps
   */
  const handleTitleClick = (e, itemProps) => {
    if (!itemProps.active) {
      setActiveIndices([...activeIndices, itemProps.index]);
    } else {
      setActiveIndices(activeIndices.filter(index => index !== itemProps.index));
    }
  };

  /**
   * Expands all projects and tasks for all projects.
   */
  const handleExpandAll = () => {
    setActiveIndices(getFullExpandedArray(projects));
    setActiveTaskIndices(getArrayOfActiveTaskIndices(false));
  };

  /**
   * Collapses both task and project sections for all projects.
   */
  const handleCollapseAll = () => {
    setActiveIndices([]);
    setActiveTaskIndices(getArrayOfActiveTaskIndices(true));
  };

  /**
   * Collapses all tasks only. Project sections are left as is.
   */
  const handleCollapseAllTasks = () => {
    setActiveTaskIndices(getArrayOfActiveTaskIndices(true));
  };

  /**
   * Allows the collapse and expansion of task accordion views.
   * @param {string} projectName Name of the project as it appears in the projects array.
   * @param {Array} newActiveTaskIndicesArray Array of indices that are currently active for the project.
   */
  const handleProjectTaskCollapse = (projectName, newActiveTaskIndicesArray) => {
    setActiveTaskIndices({ ...activeTaskIndices, [projectName]: newActiveTaskIndicesArray });
  };

  return (
    <React.Fragment>
      <Header as="h2">
        <Icon name="history" />
        Activity History
      </Header>
      <div style={{ display: 'flex', flexDirection: 'row', marginBottom: '10px' }}>
        <Button onClick={handleExpandAll}>Expand All</Button>
        <Button onClick={handleCollapseAll}>Collapse All</Button>
        <Button onClick={handleCollapseAllTasks}>Collapse All Tasks</Button>
      </div>
      <Accordion exclusive={false} styled fluid>
        {projects.map((project, pIndex) => {
          return (
            <React.Fragment key={`project-panel-${project.name}-${pIndex}`}>
              <Accordion.Title
                index={pIndex}
                active={activeIndices.includes(pIndex)}
                onClick={handleTitleClick}
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                }}
              >
                <div>
                  <Icon
                    name={activeIndices.includes(pIndex) ? 'triangle down' : 'triangle right'}
                  />
                  <span style={{ fontSize: '22px' }}>{project.name}</span>
                </div>
                <div>
                  <p>{project.tasks.length} tasks</p>
                </div>
              </Accordion.Title>
              <Accordion.Content active={activeIndices.includes(pIndex)}>
                <TaskGroup
                  activeTaskIndices={activeTaskIndices[project.name]}
                  projectName={project.name}
                  tasks={project.tasks}
                  handleProjectTaskCollapse={handleProjectTaskCollapse}
                />
              </Accordion.Content>
            </React.Fragment>
          );
        })}
      </Accordion>
    </React.Fragment>
  );
}

export default ReportTable;
