import React from 'react';
import PropTypes from 'prop-types';
import { ResponsiveContainer, BarChart, CartesianGrid, XAxis, YAxis, Tooltip, Bar } from 'recharts';
import RevenueBarChartTooltip from './RevenueBarChartTooltip';

function RevenueBarChart({ data, viewOptions, yearlyTotals }) {
  return (
    <ResponsiveContainer height={500} width="100%">
      <BarChart data={data}>
        {viewOptions.showCartesianGrid && <CartesianGrid strokeDasharray="1 5" />}
        <XAxis dataKey="date" />
        <YAxis
          axisLine={false}
          tickLine={false}
          tickFormatter={val => val.toLocaleString('en-US', { style: 'currency', currency: 'USD' })}
          width={100}
          dataKey="amount"
        />
        <Tooltip
          content={
            <RevenueBarChartTooltip
              reportType={viewOptions.reportType}
              yearlyTotals={yearlyTotals}
            />
          }
        />
        <Bar dataKey="amount" fill="#4d9ad5" />
      </BarChart>
    </ResponsiveContainer>
  );
}

RevenueBarChart.propTypes = {
  data: PropTypes.arrayOf(PropTypes.object).isRequired,
  viewOptions: PropTypes.object.isRequired,
  yearlyTotals: PropTypes.object.isRequired,
};

export default RevenueBarChart;
