function reducer(state, action) {
  switch (action.type) {
    case 'SET_LOADING_STATUS_AND_MESSAGE':
      return {
        ...state,
        loading: {
          ...state.loading,
          isActive: action.loadingStatus,
          message: action.loadingMessage ?? '',
        },
      };
    case 'SET_TABLE_DATA':
      const { tableData } = action;
      return {
        ...state,
        tableData: tableData,
      };
    case 'SET_DROPDOWN_OPTIONS':
      const { dropdownOptions } = action;
      return {
        ...state,
        dropdownOptions,
      };
    case 'SET_DICTIONARY_VALUES':
      const { dict, dictType } = action;
      return {
        ...state,
        dictionaries: {
          ...state.dictionaries,
          [dictType]: dict,
        },
      };
    default:
      return state;
  }
}

export default reducer;
