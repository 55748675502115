import React from 'react';

const NoMatch = () => {
  return (
    <div id={'no-match'}>
      <h1>Oops...</h1>
      <p>Nothing to see here. Try another page.</p>
    </div>
  );
};

export default NoMatch;
