import React from 'react';
import PropTypes from 'prop-types';
import { Container, Table, Message } from 'semantic-ui-react';
import { getTableHeaderData } from '../utils/table-mapping';

function ReportTableView({ tableRows, tableSorting, handleTableSorting }) {
  const tableHeaders = getTableHeaderData();

  return (
    <div style={{ overflowX: 'auto', padding: '10px 0 2px 0' }}>
      {Object.keys(tableRows).length === 0 ? (
        <Container>
          <Message
            icon="info circle"
            content='Select any filters and then click the "Generate Report" button to create your report.'
          />
        </Container>
      ) : (
        <Table sortable celled singleLine unstackable selectable>
          <Table.Header>
            <Table.Row>
              {tableHeaders.map(th => {
                return (
                  <Table.HeaderCell
                    key={th}
                    sorted={tableSorting.column === th ? tableSorting.direction : null}
                    onClick={() => handleTableSorting(th)}
                  >
                    {th}
                  </Table.HeaderCell>
                );
              })}
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {tableRows.map((row, rIndex) => {
              return (
                <Table.Row key={`report row ${rIndex}`}>
                  {tableHeaders.map((headerName, hIndex) => {
                    return <Table.Cell key={`report cell ${hIndex}`}>{row[headerName]}</Table.Cell>;
                  })}
                </Table.Row>
              );
            })}
          </Table.Body>
        </Table>
      )}
    </div>
  );
}

ReportTableView.propTypes = {
  tableSorting: PropTypes.shape({
    column: PropTypes.string,
    direction: PropTypes.string,
  }).isRequired,
  handleTableSorting: PropTypes.func.isRequired,
};

export default ReportTableView;
