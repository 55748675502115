import React from 'react';

function columnDefinitions() {
  return [
    {
      Header: 'Contract / CO',
      accessor: 'content',
    },
    {
      Header: 'Board Column',
      accessor: 'boardColumn',
    },
    {
      Header: 'Start Date',
      accessor: 'startDate',
    },
    {
      Header: 'Days from Start to Fully Signed',
      Cell: ({ row, value }) =>
        value !== '' ? <div style={{ textAlign: 'right' }}>{value}</div> : '',
      accessor: 'startToSigned',
    },
    {
      Header: 'Days from Start to Process Completed',
      Cell: ({ row, value }) =>
        value !== '' ? <div style={{ textAlign: 'right' }}>{value}</div> : '',
      accessor: 'startToComplete',
    },
  ];
}

export default columnDefinitions;
