import React from 'react';
import { Modal, Header, Button } from 'semantic-ui-react';
import './styles/index.scss';

const Help = ({ show, toggleHelp }) => {
  return (
    <Modal
      trigger={
        <Button className={'link'} onClick={toggleHelp}>
          Need Help?
        </Button>
      }
      open={show}
      onClose={toggleHelp}
      size={'tiny'}
    >
      <Header icon={'help circle'} content={'Help'} />
      <Modal.Content>
        <p>
          If you need help, send an email to{' '}
          <a href="mailto:jameson.mcbride@instat.com?subject=Help - Instat Apps">
            jameson.mcbride@instat.com
          </a>
        </p>
        <br />
        <br />
        <br />
      </Modal.Content>
      <Modal.Actions>
        <Button onClick={toggleHelp}>Close</Button>
      </Modal.Actions>
    </Modal>
  );
};

export default Help;
