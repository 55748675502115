import React from 'react';
import { Bar, BarChart, Legend, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';
import { getGraphColors } from '../../../../utils/colors';
import { currencyString } from '../../../../utils/numbers';
import MonthlyInvoiceTotalChartTooltip from './MonthlyInvoiceTotalChartTooltip';

function MonthlyInvoiceTotalBarChart({ data, projects }) {
  const fillColors = getGraphColors(projects.length);

  const amountTickFormatter = tick => {
    return currencyString(tick);
  };

  const monthTickFormatter = tick => {
    const month = parseInt(tick.split('/')[0]);
    const monthAbbreviations = [
      '',
      'Jan',
      'Feb',
      'Mar',
      'Apr',
      'May',
      'Jun',
      'Jul',
      'Aug',
      'Sep',
      'Oct',
      'Nov',
      'Dec',
    ];
    return monthAbbreviations[month];
  };

  const renderYearTick = tickProps => {
    const { x, y, payload } = tickProps;
    const { value, offset } = payload;
    const month = parseInt(value.split('/')[0]);
    const year = parseInt(value.split('/')[1]);

    if (month === 6) {
      return (
        <text x={x} y={y - 4} textAnchor="middle" style={{ fontWeight: 700 }}>
          {year}
        </text>
      );
    }

    const isLast = month === 12;

    if (isLast) {
      const pathX = Math.floor(isLast ? x + offset : x - offset) + 0.5;
      return <path d={`M${pathX},${y - 4}v${-35}`} stroke="#666666" />;
    }
    return null;
  };

  return (
    <ResponsiveContainer height={400} width="100%">
      <BarChart
        width={500}
        height={300}
        data={data}
        margin={{
          top: 5,
          right: 30,
          left: 20,
          bottom: 5,
        }}
      >
        <XAxis dataKey="date" tickFormatter={monthTickFormatter} />
        <XAxis
          dataKey="date"
          tick={renderYearTick}
          axisLine={false}
          tickLine={false}
          interval={0}
          height={1}
          scale="band"
          xAxisId="year"
        />
        <YAxis tickFormatter={amountTickFormatter} />
        <Tooltip content={<MonthlyInvoiceTotalChartTooltip />} />
        <Legend />
        {/* <Bar dataKey="amount" fill="#82ca9d" /> */}
        {projects.map((project, pIndex) => (
          <Bar
            key={`project-bar-${pIndex}`}
            dataKey={project.name}
            stackId="stack-1"
            fill={fillColors[pIndex]}
          />
        ))}
      </BarChart>
    </ResponsiveContainer>
  );
}

export default MonthlyInvoiceTotalBarChart;
