import React from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import { ToastProvider } from 'react-toast-notifications';
import './App.scss';
import Navbar from './components/Navbar';
import Main from './views/Main';
import { SocketProvider } from './context/SocketContext';
import { AuthProvider } from './context/AuthContext';
import './styles/main.scss';

const App = () => {
  return (
    <AuthProvider>
      <ToastProvider>
        <SocketProvider>
          <Router>
            {/* NAVBAR */}
            <Navbar />

            <Main />
          </Router>
        </SocketProvider>
      </ToastProvider>
    </AuthProvider>
  );
};

export default App;
