import React from 'react';
import PropTypes from 'prop-types';
import { Form, Icon, Checkbox, Button } from 'semantic-ui-react';
import '../styles/view-options.scss';
import Slider from '../../../components/Slider';

function ViewOptions({ viewOptions, handleToggleViewOption }) {
  return (
    <div>
      <div>
        <div className="view-options-box">
          {/* View Options */}
          <div>
            <div className="view-options-section-header">
              <Icon name="options" />
              <strong>View Options</strong>
            </div>
            <div className="view-options-checkbox-list">
              <Checkbox
                label="Show Grid"
                onChange={() => handleToggleViewOption('showCartesianGrid')}
                checked={viewOptions.showCartesianGrid}
                toggle
              />
            </div>
          </div>

          {/* Data Options */}
          <div>
            <div className="view-options-section-header">
              <Icon name="database" />
              <strong>Data Options</strong>
            </div>
            <div style={{ marginTop: '10px' }}>
              <Button.Group>
                <Button
                  onClick={() => handleToggleViewOption('reportType/monthly')}
                  active={viewOptions.reportType === 'monthly'}
                >
                  Monthly
                </Button>
                <Button.Or />
                <Button
                  onClick={() => handleToggleViewOption('reportType/yearly')}
                  active={viewOptions.reportType === 'yearly'}
                >
                  Yearly
                </Button>
              </Button.Group>
              <Form.Group style={{ marginTop: '8px' }}>
                <Form.Field disabled={viewOptions.reportType === 'yearly'}>
                  <label style={{ fontWeight: '500' }}>Year Range</label>
                  <Slider
                    type="range"
                    min="1"
                    max="4"
                    value={viewOptions.reportNumYears}
                    onChange={e =>
                      handleToggleViewOption('reportNumYears', parseInt(e.target.value))
                    }
                    disabled={viewOptions.reportType === 'yearly'}
                    leftLabel="1"
                    rightLabel="4"
                  />
                </Form.Field>
              </Form.Group>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ViewOptions;
