import React, { useEffect, useReducer } from 'react';
import { useToasts } from 'react-toast-notifications';
import { format } from 'date-fns';
import { Header, Dimmer, Loader } from 'semantic-ui-react';
import reducer from './state/reducer';
import initialState from './state/initialState';
import {
  getContractProcessingReport,
  refreshContractProcessingReport,
} from '../../services/api/reports/contract-processing';
import ContractProcessingReportTable from './components/ContractProcessingReportTable';
import ContractProcessingFilterForm from './components/ContractProcessingFilterForm';
import CalculationsHelpModal from '../../components/CalculationsHelpModal';
import calculationExplanations from './data/calculation-explanations';
import { useAuth, useAuthFunctions } from '../../context/AuthContext';

function ContractProcessingReport({ history }) {
  document.title = 'Instat Apps - Contract Processing Report';
  const { addToast } = useToasts();
  const auth = useAuth();
  const { token } = auth.user;
  const { logout } = useAuthFunctions();

  const [state, dispatch] = useReducer(reducer, initialState);

  const setIsLoading = (type, isLoading) => {
    switch (type) {
      case 'report':
        dispatch({ type: 'SET_IS_LOADING_REPORT', isLoading });
        break;
      default:
        break;
    }
  };

  const checkForRequestErrors = res => {
    // Check for errors
    if (res.status === 400) {
      addToast('Request failed. Please try again', {
        appearance: 'error',
        autoDismiss: true,
      });
      throw new Error(res.message);
    }
    if (res.status === 401) {
      addToast('Your session has expired. Please log in again.', {
        appearance: 'error',
        autoDismiss: true,
      });
      logout();
      history.push('/login');
      throw new Error(res.message);
    }
    if (res.status === 500) {
      addToast(
        'Internal server error. Most likely the cached data cannot be found. Please rerun report to generate a new cache.',
        { appearance: 'error' }
      );
      throw new Error(res.message);
    }
  };

  useEffect(() => {
    async function main() {
      setIsLoading('report', true);

      try {
        const res = await getContractProcessingReport(token, {
          startDate: format(state.filters.startDate, 'yyyyMMdd'),
          endDate: format(state.filters.endDate, 'yyyyMMdd'),
        });

        checkForRequestErrors(res);

        dispatch({
          type: 'SET_REPORT_AND_FILTERS',
          report: res.data,
          filters: {
            startDate: new Date(`${res.data.data.startDate.split('T')[0]}T00:00:00`),
            endDate: new Date(`${res.data.data.endDate.split('T')[0]}T00:00:00`),
          },
        });
      } catch (error) {
        console.error(error);
      }
    }

    main()
      .then(() => {
        setIsLoading('report', false);
      })
      .catch(err => {
        const errorMsg = err.message;

        if (errorMsg.includes('status code 401')) {
          addToast('Your session has expired. Please sign in again.', {
            appearance: 'error',
            autoDismiss: true,
          });
          logout();
          history.push('/login');
        } else {
          addToast(err.message, { appearance: 'error', autoDismiss: true });
        }
        console.error(err);
      });
  }, [history, logout, token]);

  async function updateReport(filters) {
    const { startDate, endDate } = filters;

    try {
      setIsLoading('report', true);

      const res = await refreshContractProcessingReport(
        token,
        format(startDate, 'yyyyMMdd'),
        format(endDate, 'yyyyMMdd')
      );

      checkForRequestErrors(res);

      dispatch({
        type: 'SET_REPORT_AND_FILTERS',
        report: res.data,
        filters,
      });

      setIsLoading('report', false);
    } catch (error) {
      if (error.message.includes('status code 401')) {
        addToast('Your session has expired. Please sign in again.', {
          appearance: 'error',
          autoDismiss: true,
        });
        logout();
        history.push('/login');
      } else {
        addToast(error.message, { appearance: 'error', autoDismiss: true });
      }
      console.error(error);
    }
  }

  /**
   * Sorts table data based on table header that is clicked.
   */
  function handleTableHeaderClick(columnForSort) {
    console.log(`column.id = ${columnForSort}`);
    dispatch({ type: 'CHANGE_TABLE_SORT', columnForSort });
  }

  return (
    <div>
      <Dimmer active={state.isLoading.report} inverted>
        <Loader inverted>Loading report data...</Loader>
      </Dimmer>

      <div className="project-selection-form-container">
        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
          <Header as="h1">
            Contract Processing Report
            {state.report.data.tableData.length > 0 && (
              <Header.Subheader>
                Last updated: {new Date(state.report.timestamp).toLocaleString()}
              </Header.Subheader>
            )}
          </Header>
          <div>
            <CalculationsHelpModal calculationExplanations={calculationExplanations} />
          </div>
        </div>
        <Header.Subheader />
        <ContractProcessingFilterForm
          filters={state.filters}
          isLoading={state.isLoading.report}
          updateReport={updateReport}
        />
      </div>

      <ContractProcessingReportTable
        tableData={state.report.data.tableData}
        isLoading={state.isLoading.report}
        tableSort={state.tableSort}
        handleTableHeaderClick={handleTableHeaderClick}
      />
    </div>
  );
}

export default ContractProcessingReport;
