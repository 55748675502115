/**
 * This ordering is used when the table is rendered.
 */
const columnOrdering = [
  'Client',
  'Project',
  'Account Lead',
  'Project Lead',
  'Start Date',
  'End Date',
  'Total Payments',
  'Total Billables',
  'Total Expenses',
  'Net Cash',
  'Total Breakeven',
  'Net Cash (Breakeven)',
  'Accrued Total Revenue',
  'Diff from Planned Margin',
  'Actual Margin (Breakeven)',
  'Profit Margin',
];

module.exports = columnOrdering;
