/**
 * Initial state containing all clients and projects.
 */
const initialState = {
  clients: [], // array of client rows from Project Revenue spreadsheet
  projects: [], // array of project rows from Project Revenue spreadsheet including Teamwork data
  filters: {
    client: [],
    al: [],
    pl: [],
  },
  isLoading: {
    form: true,
  },
  selectedProjects: [], // array of selected project codes
  reportData: {},
  viewOptions: {
    showPassThru: false,
    showCartesianGrid: false,
    costType: 'incurred',
  },
};

export default initialState;
