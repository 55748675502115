import React, { useMemo } from 'react';
import { useTable } from 'react-table';
import { Table } from 'semantic-ui-react';
import { currencyFormatter } from '../../../utils/string-transformations';
import Big from 'big.js';
import { setBigForCurrency } from '../../../utils/numbers';

/**
 * Generates column definitions that are used by the react-table package to render
 * the table content. Dynamically creates columns for any years that have values.
 * @param {object[]} deals Array of deals and their yearly totals
 * @returns Column definitions for react-table along with the min and max year.
 */
function getColumnDefinitions(deals) {
  const definitions = [
    {
      Header: 'Client',
      accessor: 'client',
    },
    {
      Header: 'Deal',
      accessor: 'deal',
    },
  ];

  // Get min and max year range from full list of deals.
  let minYear = new Date().getFullYear();
  let maxYear = new Date().getFullYear();
  for (let deal of deals) {
    for (let key in deal) {
      const isYear = !isNaN(key);

      if (isYear) {
        const year = parseInt(key);
        if (year < minYear) minYear = year;
        if (year > maxYear) maxYear = year;
      }
    }
  }

  // Iterate through min to max years and add column definitions.
  for (let year = minYear; year < maxYear + 1; year++) {
    definitions.push({
      Header: year,
      accessor: year + '',
      Cell: ({ value }) => (
        <div style={{ textAlign: 'right' }}>
          {isNaN(value) ? currencyFormatter.format(0) : currencyFormatter.format(value)}
        </div>
      ),
    });
  }

  return { columns: definitions, minYear, maxYear };
}

/**
 * Computes column totals for each year column.
 * @param {object[]} deals List of deals and their yearly sums.
 * @param {number} minYear First year in range for deal sum values.
 * @param {number} maxYear Last year in range for deal sum values.
 * @returns An ordered array of summed totals for each year column.
 */
function sumColumns(deals, minYear, maxYear) {
  const sums = {};
  const orderedSums = [];
  for (const deal of deals) {
    for (const year in deal) {
      const isYear = !isNaN(year);
      const yearInSums = year in sums;

      if (isYear && !yearInSums) {
        sums[year] = new Big(deal[year]);
      }
      if (isYear && yearInSums) {
        sums[year] = sums[year].add(deal[year]);
      }
    }
  }

  for (let year = minYear; year < maxYear + 1; year++) {
    orderedSums.push(sums[year]);
  }

  return orderedSums;
}

function DealAmountYearlyBreakdownTable({ dealAmountsByYear }) {
  setBigForCurrency(Big);

  // const columnDefinitions = getColumnDefinitions(dealAmountsByYear);
  const { columns, minYear, maxYear } = useMemo(() => getColumnDefinitions(dealAmountsByYear), [
    dealAmountsByYear,
  ]);
  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = useTable({
    columns: columns,
    data: dealAmountsByYear,
  });
  const yearlySums = sumColumns(dealAmountsByYear, minYear, maxYear);

  return (
    <React.Fragment>
      {/* <pre>{JSON.stringify(columns, null, 2)}</pre> */}
      {dealAmountsByYear.length === 0 ? (
        <div />
      ) : (
        <div style={{ overflowX: 'auto', padding: '10px 0 2px 0' }}>
          <Table celled compact unstackable selectable {...getTableProps()}>
            <Table.Header>
              {headerGroups.map(headerGroup => (
                <Table.Row {...headerGroup.getHeaderGroupProps()}>
                  {headerGroup.headers.map(column => {
                    return (
                      <Table.HeaderCell {...column.getHeaderProps()}>
                        {column.render('Header')}
                      </Table.HeaderCell>
                    );
                  })}
                </Table.Row>
              ))}
            </Table.Header>
            <Table.Body {...getTableBodyProps()}>
              {rows.map((row, i) => {
                prepareRow(row);

                return (
                  <Table.Row {...row.getRowProps()}>
                    {row.cells.map(cell => (
                      <Table.Cell {...cell.getCellProps()}>{cell.render('Cell')}</Table.Cell>
                    ))}
                  </Table.Row>
                );
              })}
            </Table.Body>
            <Table.Footer>
              <Table.Row>
                <Table.HeaderCell></Table.HeaderCell>
                <Table.HeaderCell></Table.HeaderCell>
                {yearlySums.map((ys, ysIndex) => (
                  <Table.HeaderCell key={`yearly-sum-${ysIndex}`}>
                    {currencyFormatter.format(ys)}
                  </Table.HeaderCell>
                ))}
              </Table.Row>
            </Table.Footer>
          </Table>
        </div>
      )}
    </React.Fragment>
  );
}

export default DealAmountYearlyBreakdownTable;
