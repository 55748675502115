import React from 'react';
import { GoogleLogin, GoogleOAuthProvider } from '@react-oauth/google';

export const GoogleLoginButton = ({ responseGoogle }) => {
  const clientId = '818332660287-fuvmsl36f13gn4n0t5bctq4fl7giu3cn.apps.googleusercontent.com';

  return (
    <React.Fragment>
      <GoogleOAuthProvider clientId={clientId}>
        <GoogleLogin
          onSuccess={responseGoogle}
          onError={() => console.log('login failed')}
          useOneTap
        />
      </GoogleOAuthProvider>
    </React.Fragment>
  );
};
