import React from 'react';

const calculationExplanations = [
  {
    Column: 'Tasks Remaining',
    'Calculation Explanation': (
      <div>
        <p>
          Any tasks not checked off completely. If a task has anything other than 100% progress it
          will be included in this number.
        </p>
      </div>
    ),
  },
  {
    Column: 'Progress of Remaining Tasks',
    'Calculation Explanation': (
      <div>
        <p>
          For any tasks not marked as complete, the average remaining progress is calculated. For
          example, if there are three tasks remaining that have 0%, 90%, and 90% progress, the
          average will be (0 + 90 + 90) / 3 = 60%.
        </p>
      </div>
    ),
  },
];

export default calculationExplanations;
