import React from 'react';
import { Button, Form, Select } from 'semantic-ui-react';

function FilterOptionsForm({ formProps, handleReset, dropdownOptions, isLoading }) {
  const handleFilterOnChange = (e, { name, value }) => {
    formProps.setFieldValue(name, value);
  };

  return (
    <React.Fragment>
      {/* <Button
        onClick={handleReset}
        type="button"
        size="tiny"
        compact
        style={{ marginBottom: '10px' }}
        secondary
      >
        Clear Filters
      </Button> */}

      <Form.Group widths="equal">
        <Form.Field
          control={Select}
          name="projects"
          label="Projects"
          options={dropdownOptions.projects}
          placeholder={isLoading ? '...' : 'Select projects...'}
          value={formProps.values.projects}
          onChange={handleFilterOnChange}
          search
          multiple
          disabled={isLoading}
        />
        {formProps.errors.projects && formProps.touched.projects ? (
          <div style={{ fontSize: '10px', color: 'red' }}>{formProps.errors.projects}</div>
        ) : null}

        <Form.Field />
      </Form.Group>
    </React.Fragment>
  );
}

export default FilterOptionsForm;
