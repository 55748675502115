import React, { useEffect, useState } from 'react';
import { isBefore, isAfter } from 'date-fns';
import MonthlyInvoiceTotalBarChart from './MonthlyInvoiceTotalBarChart';
import { Header, Icon, Label } from 'semantic-ui-react';
import DateSlider from '../../../../components/DateSlider';

function MonthlyInvoiceTotalChart({ projects }) {
  const chartDataInitialState = {
    data: [],
    minDate: null,
    maxDate: null,
    minChangeDate: null,
    maxChangeDate: null,
  };

  const [historyReferenceDate, setHistoryReferenceDate] = useState(
    new Date(Date.now()).toDateString()
  );
  const [chartData, setChartData] = useState(chartDataInitialState);
  const [sliderDates, setSliderDates] = useState({
    min: '',
    max: '',
  });

  /**
   * Sets the minimum and maximum due dates for monthly sum.
   * @param {object} chartData Object containing data for chart being built.
   * @param {array} projects List of all projects in report. Should be the filtered list.
   */
  function getMinMaxDates(chartData, projects) {
    for (const project of projects) {
      for (const task of project.tasks) {
        /**
         * Check for new min/max date
         */
        const dueDateYear = task.dueDate.slice(0, 4);
        const dueDateMonth = task.dueDate.slice(4, 6);
        const dueDateDay = task.dueDate.slice(6);
        const dueDateString = `${dueDateMonth}/${dueDateDay}/${dueDateYear}`;
        const dueDate = new Date(dueDateString);

        // Set initial min/max dates
        if (!chartData.minDate && !chartData.maxDate) {
          chartData.minDate = dueDateString;
          chartData.maxDate = dueDateString;
          continue;
        }

        const minDate = new Date(chartData.minDate);
        const maxDate = new Date(chartData.maxDate);

        // Compare to existing min/max dates
        if (isBefore(dueDate, minDate)) {
          chartData.minDate = dueDateString;
        }
        if (isAfter(dueDate, maxDate)) {
          chartData.maxDate = dueDateString;
        }
      }
    }
  }

  /**
   * Sets the minimum and maximum task update dates. It's necessary to use these for the
   * slider's date range because task update dates could be before any due dates (most likely are).
   * @param {object} chartData Object containing data for chart being built.
   */
  function getMinMaxChangeDates(chartData) {
    for (const project of projects) {
      for (const task of project.tasks) {
        for (const update of task.updates) {
          /**
           * Check for min/max dates.
           */
          const { modificationDate } = update;

          // Set initial min/max values
          if (!chartData.minChangeDate && !chartData.maxChangeDate) {
            chartData.minChangeDate = modificationDate;
            chartData.maxChangeDate = modificationDate;
            continue;
          }

          const changeDate = new Date(modificationDate);
          const minDate = new Date(chartData.minChangeDate);
          const maxDate = new Date(chartData.maxChangeDate);

          // Compare to existing min/max values
          if (isBefore(changeDate, minDate)) {
            chartData.minChangeDate = modificationDate;
          }
          if (isAfter(changeDate, maxDate)) {
            chartData.maxChangeDate = modificationDate;
          }
        }
      }
    }
  }

  /**
   * Creates the full date range for the chart. Amount values are blank, to be filled in by another function.
   * Note that it is important to rerun this function when the selected date in the slider is changed. That allows
   * the range to be reset and properly charted.
   * @param {object} chartData Object containing data for chart being built.
   * @param {array} projects List of all projects in report. Should be the filtered list.
   */
  function getDateRange(chartData, projects) {
    const { minDate, maxDate } = chartData;
    if (!minDate || !maxDate) return;

    const minDateYear = minDate.split('/')[2];
    const minDateMonth = minDate.split('/')[0];
    const maxDateYear = maxDate.split('/')[2];
    const maxDateMonth = maxDate.split('/')[0];

    const numberMonthsDifference =
      (maxDateYear - minDateYear) * 12 + (maxDateMonth - minDateMonth) + 1;
    let currentMonth = parseInt(minDateMonth);
    let currentYear = parseInt(minDateYear);
    for (let i = 0; i < numberMonthsDifference; i++) {
      const monthObj = {
        date: `${currentMonth}/${currentYear}`,
        amount: 0,
      };

      for (const project of projects) {
        monthObj[project.name] = 0;
      }

      chartData.data.push(monthObj);

      currentMonth++;
      if (currentMonth > 12) {
        currentMonth = 1;
        currentYear++;
      }
    }
  }

  /**
   * Gets the correct due date based on a selected basedOnDate in the date slider.
   * Updates are iterated in reverse order to find the closest updated due date value.
   * All projects and tasks are returned. Updates arrays for each task are removed.
   * @param {array} projects List of all projects in report. Should be the filtered list.
   * @param {string} basedOnDate New maximum date selected from date slider.
   * @returns Array of projects with the correct due date.
   */
  function filterTaskDueDates(projects, basedOnDate) {
    const filteredProjects = [];
    for (const project of projects) {
      if (project.tasks.length === 0) {
        // skip if no tasks for project
        continue;
      }

      const projectObj = {
        name: project.name,
        tasks: [],
      };
      for (const task of project.tasks) {
        const { amount } = task;
        let dueDate = task.dueDate;
        if (!amount || amount === '' || amount === 0) continue;
        if (!dueDate || dueDate === '') continue;

        if (basedOnDate) {
          const updates = [...task.updates];
          updates.reverse();
          for (const update of updates) {
            const modificationDate = new Date(update.modificationDate);
            const maxModificationDate = new Date(basedOnDate);
            if (isAfter(modificationDate, maxModificationDate)) {
              break;
            }
            dueDate =
              update.dueDate === ''
                ? update.dueDate
                : update.dueDate.split('T')[0].replace(/-/g, '');
          }
        }
        if (!dueDate || dueDate === '') continue; // need to check again in case due date is removed

        projectObj.tasks.push({ name: task.name, dueDate, amount });
      }
      filteredProjects.push(projectObj);
    }

    return filteredProjects;
  }

  /**
   * Computes monthly totals for chart.
   * @param {object} chartData Object containing data for chart being built.
   * @param {array} projects Prefiltered and formatted projects array.
   * @returns Chart data with minimum and maximum dates.
   */
  function computeChartData(chartData, projects) {
    for (const project of projects) {
      if (project.tasks.length === 0) {
        // skip if no tasks for project
        continue;
      }

      for (const task of project.tasks) {
        const { amount, dueDate } = task;
        const amountValue = Number(amount.replace(/[^0-9.-]+/g, ''));
        const dueDateMonth = parseInt(dueDate.slice(4, 6));
        const dueDateYear = parseInt(dueDate.slice(0, 4));
        const indexOfMonthAndYear = chartData.data.findIndex(
          e => e.date === `${dueDateMonth}/${dueDateYear}`
        );

        chartData.data[indexOfMonthAndYear][project.name] += amountValue;
        chartData.data[indexOfMonthAndYear].amount += amountValue;
      }
    }
  }

  /**
   * Essentially two things need to happen when the date is changed in the slider:
   * 1. The UI needs to change to show the selected date.
   * 2. The chart data needs to be refreshed to show new data.
   * @param {SyntheticEvent} e Event from input.
   */
  const handleDateSliderChange = e => {
    // Set history reference date value (currently selected date).
    const date = new Date(parseInt(e.target.value));
    setHistoryReferenceDate(new Date(date).toDateString());

    // Update data for chart.
    const updatedChartData = { ...chartDataInitialState };
    const projectsWithTaskDueDatesFiltered = filterTaskDueDates(projects, date.toISOString());

    getMinMaxDates(updatedChartData, projectsWithTaskDueDatesFiltered);
    getDateRange(updatedChartData, projectsWithTaskDueDatesFiltered);
    computeChartData(chartDataInitialState, projectsWithTaskDueDatesFiltered, date.toISOString());

    setChartData(updatedChartData);
  };

  useEffect(() => {
    const newChartData = { ...chartDataInitialState };
    const projectsWithTaskDueDatesFiltered = filterTaskDueDates(projects);

    // Compute chart data.
    getMinMaxDates(newChartData, projectsWithTaskDueDatesFiltered);
    getDateRange(newChartData, projectsWithTaskDueDatesFiltered);
    computeChartData(chartDataInitialState, projectsWithTaskDueDatesFiltered);

    // Set slider min/max date values.
    getMinMaxChangeDates(newChartData);

    // Set chart data to state.
    setChartData(newChartData);

    if (newChartData.minChangeDate && newChartData.maxChangeDate) {
      setSliderDates({
        min: new Date(newChartData.minChangeDate.split('T')[0]).getTime(),
        max: new Date(newChartData.maxChangeDate.split('T')[0]).getTime(),
      });
    }
  }, [projects]);

  return (
    <React.Fragment>
      {chartData.data.length === 0 ? null : (
        <React.Fragment>
          <Header as="h2">
            <Icon name="chart bar" /> Monthly Invoice Total Chart
          </Header>
          <div>
            <div style={{ paddingRight: '5px', marginBottom: '10px' }}>
              <Label style={{ padding: '5px', cursor: 'default' }} size="large">
                <Icon name="calendar alternate" /> Due dates as of:
                <Label.Detail>{historyReferenceDate}</Label.Detail>
              </Label>
            </div>
            <DateSlider
              min={sliderDates.min}
              max={sliderDates.max}
              step={86400000}
              onChange={handleDateSliderChange}
            />
          </div>
          <MonthlyInvoiceTotalBarChart data={chartData.data} projects={projects} />
        </React.Fragment>
      )}
    </React.Fragment>
  );
}

export default MonthlyInvoiceTotalChart;
