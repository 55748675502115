import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Button, Modal, Form, Icon, Select, Message } from 'semantic-ui-react';
import { Formik, Field } from 'formik';
import * as Yup from 'yup';
import { addNewClient } from '../../services/api/clients';
import CheckboxField from '../../components/CheckboxField';
import './index.scss';

const clientTypeOptions = [
  { key: '0', text: 'Partner', value: 'Partner' },
  { key: '1', text: 'Sponsor', value: 'Sponsor' },
  { key: '2', text: 'Other', value: 'Other' },
];

const accountLeadOptions = [
  { key: '0', text: 'Dave', value: 'Dave' },
  { key: '1', text: 'Kyle', value: 'Kyle' },
  { key: '2', text: 'Mark', value: 'Mark' },
];

const AddClientModal = ({
  isOpen,
  toggleOpen,
  token,
  notifyClientCreated,
  clients,
  clientCodes,
  refreshClientsDropdown,
}) => {
  const [isLoading, setIsLoading] = useState(false);

  const AddClientModalSchema = Yup.object().shape({
    clientName: Yup.string()
      .notOneOf(clients, 'The client name you entered already exists.')
      .required('Client Name field is required.'),
    clientCode: Yup.string()
      .notOneOf(clientCodes, 'The client code you enetered already exists.')
      .required('Client Code field is required.'),
    avgDaysToPayment: Yup.number().notRequired(),
    clientType: Yup.string().required('Client Type field is required.'),
    accountLead: Yup.string().required('Account Lead field is required'),
    clientGroup: Yup.string().required('Client Group field is required.'),
    folderName: Yup.string().notRequired(),
    identicalFolderName: Yup.bool().notRequired(),
  });

  return (
    <Modal
      open={isOpen}
      trigger={
        <Button
          style={{
            maxHeight: '38px',
            marginTop: '23px',
          }}
          icon
          type="button"
          onClick={toggleOpen}
        >
          <Icon name="add" />
        </Button>
      }
    >
      <Modal.Header>Add New Client/Company</Modal.Header>
      <Modal.Content>
        <Message
          info
          content={
            <div>
              Fill out all required fields below and click "Submit". The new client/company will be
              added to the following locations:
              <ul>
                <li>Project Revenue spreadsheet (new row)</li>
                <li>Teamwork</li>
              </ul>
            </div>
          }
        />
        <Formik
          initialValues={{
            clientName: '',
            clientCode: '',
            avgDaysToPayment: 30,
            clientType: '',
            accountLead: '',
            clientGroup: '',
            folderName: '',
            identicalFolderName: true,
          }}
          onSubmit={async values => {
            setIsLoading(true);

            const newClientValues = {
              ...values,
              folderName: values.identicalFolderName ? values.clientName : values.folderName,
            };

            try {
              const clientAdded = await addNewClient(newClientValues, token);
              if (clientAdded) notifyClientCreated('New Client/Company created successfully.');
              else
                notifyClientCreated(
                  'Error creating new Client/Company. Please try again.',
                  'error'
                );
            } catch (error) {
              notifyClientCreated('Error creating new Client/Company. Please try again.', 'error');
            }

            refreshClientsDropdown();
            setIsLoading(false);
            toggleOpen();
          }}
          validationSchema={AddClientModalSchema}
        >
          {({ values, handleSubmit, setFieldValue, errors, touched, isValid, dirty }) => (
            <Form onSubmit={handleSubmit} loading={isLoading}>
              {/* Client/Company Name */}
              <Form.Field required className="form-field">
                <label htmlFor="clientName">Client Name</label>
                <Field name="clientName" type="text" autoComplete="off" />
              </Form.Field>
              {errors.clientName && touched.clientName ? (
                <div style={{ fontSize: '10px', color: 'red', marginBottom: '14px' }}>
                  {errors.clientName}
                </div>
              ) : (
                <div style={{ marginBottom: '14px' }} />
              )}

              {/* Client Code */}
              <Form.Field required className="form-field">
                <label htmlFor="clientCode">Client Code</label>
                <Field
                  name="clientCode"
                  type="text"
                  autoComplete="off"
                  style={{ marginBottom: '-0.75em' }}
                  onChange={e => setFieldValue(e.target.name, e.target.value.toUpperCase())}
                />
                {errors.clientCode && touched.clientCode ? (
                  <div style={{ fontSize: '10px', color: 'red', marginBottom: '14px' }}>
                    {errors.clientCode}
                  </div>
                ) : (
                  <div style={{ marginBottom: '14px' }} />
                )}
              </Form.Field>
              <div style={{ marginBottom: '1em' }}>
                <small style={{ color: '#6c757d!important' }}>
                  Client Code should be a unique set of capital letters, typically between 3-4
                  letters total.
                </small>
              </div>

              {/* Avg Days to Payment */}
              <Form.Field className="form-field">
                <label htmlFor="avgDaysToPayment">Payment Terms (NET)</label>
                <Field name="avgDaysToPayment" type="number" autoComplete="off" />
                {errors.avgDaysToPayment && touched.avgDaysToPayment ? (
                  <div style={{ fontSize: '10px', color: 'red', marginBottom: '14px' }}>
                    {errors.avgDaysToPayment}
                  </div>
                ) : (
                  <div style={{ marginBottom: '14px' }} />
                )}
              </Form.Field>

              {/* Type */}
              <Form.Field
                control={Select}
                name="clientType"
                label="Client Type"
                options={clientTypeOptions}
                placeholder="Select type..."
                value={values.clientType}
                required
                onChange={(e, { name, value }) => setFieldValue(name, value)}
                className="form-field"
              />
              {errors.clientType && touched.clientType ? (
                <div style={{ fontSize: '10px', color: 'red', marginBottom: '14px' }}>
                  {errors.clientType}
                </div>
              ) : (
                <div style={{ marginBottom: '14px' }} />
              )}

              {/* Account Lead */}
              <Form.Field
                control={Select}
                name="accountLead"
                label="Account Lead"
                options={accountLeadOptions}
                placeholder="Select lead..."
                value={values.accountLead}
                required
                onChange={(e, { name, value }) => setFieldValue(name, value)}
                className="form-field"
              />
              {errors.accountLead && touched.accountLead ? (
                <div style={{ fontSize: '10px', color: 'red', marginBottom: '14px' }}>
                  {errors.accountLead}
                </div>
              ) : (
                <div style={{ marginBottom: '14px' }} />
              )}

              {/* ClientGroup */}
              <Form.Field required className="form-field">
                <label htmlFor="clientGroup">Client Group</label>
                <Field
                  name="clientGroup"
                  type="text"
                  autoComplete="off"
                  placeholder="Ex: Clene Nanomedicine"
                  style={{ marginBottom: '-0.75em' }}
                />
                {errors.clientGroup && touched.clientGroup ? (
                  <div style={{ fontSize: '10px', color: 'red', marginBottom: '14px' }}>
                    {errors.clientGroup}
                  </div>
                ) : (
                  <div style={{ marginBottom: '14px' }} />
                )}
              </Form.Field>
              <div style={{ marginBottom: '1em' }}>
                <small style={{ color: '#6c757d!important' }}>
                  Client Group is a unique name given to a group of clients. For example, both Clene
                  Australia and Clene Nanomedicine are in the same Clene Nanomedicine client group.
                  If no grouping needed, just use the client name.
                </small>
              </div>

              {/* Folder Name */}

              <Form.Field className="form-field">
                <label htmlFor="folderName">Folder Name</label>
                <Field
                  name="folderName"
                  type="text"
                  autoComplete="off"
                  value={values.identicalFolderName ? values.clientName : values.folderName}
                  disabled={values.identicalFolderName}
                />
                {errors.folderName && touched.folderName ? (
                  <div style={{ fontSize: '10px', color: 'red', marginBottom: '6px' }}>
                    {errors.folderName}
                  </div>
                ) : (
                  <div style={{ marginBottom: '6px' }} />
                )}
              </Form.Field>

              <CheckboxField
                labelText="Identical to Client Name field"
                name="identicalFolderName"
              />

              {/* Client App Names ??? */}

              {/* Buttons to submit and cancel */}
              <div
                style={{
                  width: '100%',
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'flex-end',
                  marginTop: '20px',
                }}
              >
                <Button type="button" onClick={toggleOpen}>
                  Cancel
                </Button>
                <Button
                  primary
                  type="submit"
                  disabled={isLoading || !isValid || !dirty}
                  style={{
                    cursor: `${isLoading || !isValid || !dirty ? 'not-allowed' : 'pointer'}`,
                  }}
                  loading={isLoading}
                >
                  Submit
                </Button>
              </div>
            </Form>
          )}
        </Formik>
      </Modal.Content>
    </Modal>
  );
};

AddClientModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  toggleOpen: PropTypes.func.isRequired,
  token: PropTypes.string.isRequired,
  notifyClientCreated: PropTypes.func.isRequired,
  clients: PropTypes.arrayOf(PropTypes.string).isRequired,
  clientCodes: PropTypes.arrayOf(PropTypes.string).isRequired,
  refreshClientsDropdown: PropTypes.func.isRequired,
};

export default AddClientModal;
