import generateReportState from './reducers/generate-report';
import generateReportRows from './reducers/generate-report-rows';
import { sortTable } from './reducers/table-sort';

function reducer(state, action) {
  switch (action.type) {
    case 'SET_REPORT_DATA_CACHE':
      const { dateModified, data } = action.cache;
      const { companies, projects, tags, tasks, tasklists, milestones } = data;
      const newCache = {
        dateModified,
        accountLeads: tags.al,
        projectLeads: tags.pl,
        companies,
        projects,
        tasks,
        tasklists,
        milestones,
      };
      return { ...state, cache: newCache };
    case 'SET_LOADING_STATUS_AND_MESSAGE':
      return {
        ...state,
        loading: {
          ...state.loading,
          isActive: action.loadingStatus,
          message: action.loadingMessage ?? '',
        },
      };
    case 'GENERATE_REPORT':
      const tableDataState = generateReportState(state, action.filters);
      return generateReportRows(tableDataState, action.filters);
    case 'CHANGE_TABLE_SORT':
      return sortTable(state, action.column);
    default:
      return state;
  }
}

export default reducer;
