import {
  getProjectRevenueClients,
  getProjectRevenueProjects,
} from '../../../services/api/project-revenue';
import { getAllProjects } from '../../../services/api/teamwork';

export async function getClientsAndProjects(token) {
  /**
   * Get all clients (project revenue spreadsheet)
   */
  const clients = await getProjectRevenueClients(token);

  /**
   * Get all projects:
   *   - Project Revenue Spreadsheet
   *   - Teamwork
   */
  const revenueProjects = await getProjectRevenueProjects(token);
  const teamworkProjects = await getAllProjects(token);
  const projects = revenueProjects.reduce((acc, revProject) => {
    const projectObj = { ...revProject };
    const foundTWProject = teamworkProjects.find(p => p.id === revProject['TWProjectId']);

    if (foundTWProject) {
      projectObj.teamworkData = foundTWProject;
    } else {
      projectObj.teamworkData = null;
    }

    return [ ...acc, projectObj ];
  }, []);

  return {
    clients,
    projects,
  };
}
