import React from 'react';
import { convertMonthYearNumbersToDate } from '../../../utils/dates';
import '../styles/tooltip.scss';

function ProjectFinancialsChartTooltip({ active, payload, label, showBreakevenCosts }) {
  function wrapCurrency(val) {
    return val.toLocaleString('en-US', { style: 'currency', currency: 'USD' });
  }

  if (active && payload && payload.length) {
    const namesAndValues = payload
      .map(({ name, value, stroke, fill, payload }, index) => ({
        name,
        value,
        index,
        stroke,
        fill,
        date: payload.date,
      }))
      .reduce(
        (acc, item) => ({
          ...acc,
          [item.name]: {
            index: item.index,
            value: item.value,
            stroke: item.stroke,
            fill: item.fill,
            date: item.date,
          },
        }),
        {}
      );

    // ((payments - breakeven costs) / payments) * 100
    const payments = namesAndValues['Payments'].value;
    const breakevenCosts = namesAndValues['Breakeven Costs'].value;
    const revenueMargin = (((payments - breakevenCosts) / payments) * 100).toFixed(2);

    return (
      <div className="pfc-tooltip">
        <div className="pfc-tooltip-header">
          <span className="pfc-tooltip-header-text">
            <strong>{convertMonthYearNumbersToDate(namesAndValues['Payments'].date)}</strong>
          </span>
        </div>
        <div>
          <div className="tooltip-label">
            <strong style={{ color: namesAndValues['Payments'].stroke, paddingRight: '5px' }}>
              Payments:
            </strong>
            <span className="pfc-tooltip-amount">
              {wrapCurrency(namesAndValues['Payments'].value)}
            </span>
          </div>
        </div>
        <div>
          <div className="tooltip-label">
            <strong style={{ color: namesAndValues['Costs'].stroke, paddingRight: '5px' }}>
              Total Billable:
            </strong>
            <span className="pfc-tooltip-amount">
              {wrapCurrency(namesAndValues['Costs'].value + namesAndValues['Expenses'].value)}
            </span>
          </div>
          <div style={{ marginLeft: '15px' }}>
            <div className="tooltip-label">
              <span style={{ color: namesAndValues['Costs'].stroke, paddingRight: '5px' }}>
                Billable:
              </span>
              <span className="pfc-tooltip-amount">
                {wrapCurrency(namesAndValues['Costs'].value)}
              </span>
            </div>
            <div className="tooltip-label">
              <span style={{ color: namesAndValues['Expenses'].stroke, paddingRight: '5px' }}>
                Expenses:
              </span>
              <span className="pfc-tooltip-amount">
                {wrapCurrency(namesAndValues['Expenses'].value)}
              </span>
            </div>
          </div>
        </div>
        {showBreakevenCosts && (
          <React.Fragment>
            <div>
              <div className="tooltip-label">
                <strong
                  style={{ color: namesAndValues['Breakeven Costs'].stroke, paddingRight: '5px' }}
                >
                  Breakeven Costs:
                </strong>
                <span className="pfc-tooltip-amount">
                  {wrapCurrency(namesAndValues['Breakeven Costs'].value)}
                </span>
              </div>
            </div>
            <div>
              <div className="tooltip-label">
                <strong style={{ color: '#000', paddingRight: '5px' }}>Revenue Margin:</strong>
                <span className="pfc-tooltip-amount">{revenueMargin}%</span>
              </div>
            </div>
          </React.Fragment>
        )}
      </div>
    );
  }

  return null;
}

export default ProjectFinancialsChartTooltip;
