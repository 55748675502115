import React, { useEffect, useReducer } from 'react';
import { Formik } from 'formik';
import {
  Button,
  Container,
  Form,
  Header,
  Icon,
  Message,
  Segment,
  Dimmer,
  Loader,
} from 'semantic-ui-react';
import FilterOptionsForm from './components/FilterOptionsForm';
import reducer from './state/reducer';
import initialState from './state/initial-state';
import ReportTable from './components/ReportTable';
import { useSocket } from '../../context/SocketContext';
import { getAllProjects } from '../../services/api/teamwork';
import { useAuth } from '../../context/AuthContext';
import MonthlyInvoiceTotalChart from './components/MonthlyInvoiceTotalChart';

function InvoiceTriggerActivityReport() {
  document.title = 'Instat Apps - Invoice Trigger Activity Report';
  const [state, dispatch] = useReducer(reducer, initialState);
  const socket = useSocket();
  const { user } = useAuth();
  const { token } = user;

  /**
   * Alters the loader state. Both the status and message can be edited.
   * @param {boolean} loadingStatus Whether or not loading is happening.
   * @param {string} loadingMessage Message to present to the user in the loader.
   */
  async function changeLoadingStatus(loadingStatus, loadingMessage) {
    dispatch({
      type: 'SET_LOADING_STATUS_AND_MESSAGE',
      loadingStatus,
      loadingMessage,
    });
  }

  /**
   * Sends request to get table data.
   * @param {Array} values List of projects in format {id: String, name: String}
   */
  function getTableData(values) {
    socket.emit('invoice-trigger-activity-report:get', values);
  }

  /**
   * Loads data necessary for report generation form.
   */
  async function loadInitialFormData() {
    // Get projects from Teamwork for dropdown
    try {
      const projects = await getAllProjects(token);
      const projectOptions = projects.map(p => ({ key: p.id, value: p.id, text: p.name }));

      // Build project id -> name dictionary
      const projectIdToNameDict = {};
      for (const project of projects) {
        projectIdToNameDict[project.id] = project.name;
      }

      dispatch({ type: 'SET_DROPDOWN_OPTIONS', dropdownOptions: { projects: projectOptions } });
      dispatch({
        type: 'SET_DICTIONARY_VALUES',
        dictType: 'projectIdToName',
        dict: projectIdToNameDict,
      });
    } catch (error) {}
  }

  /**
   * Checks all projects and makes sure at least one project has data to show.
   * If no projects have tasks, then false is returned.
   * @returns Boolean indicating if any task data.
   */
  function hasTaskDataToShow() {
    const { tableData } = state;
    for (const project of tableData.projects) {
      if (project.tasks.length > 0) return true;
    }
    return false;
  }

  /**
   * Determines if there is no task activity data for all of the selected projets.
   * @returns Message component with message.
   */
  function renderWhenNoData() {
    const { tableData } = state;
    const { projects } = tableData;
    if (projects.length === 0) {
      return null;
    }
    return (
      <Message
        icon="info circle"
        header="No Invoice Triggers"
        content="No invoice trigger data found for any selected projects."
      />
    );
  }

  useEffect(() => {
    socket.on('invoice-trigger-activity-report:response', response => {
      // Receive response from server with report data.
      changeLoadingStatus(false);
      dispatch({
        type: 'SET_TABLE_DATA',
        tableData: response.tableData,
      });
    });
    socket.on('invoice-trigger-activity-report:get-update', updateMessage => {
      // Update the UI with the message passed back. These are used to tell the user what's going on here.
      changeLoadingStatus(true, updateMessage);
    });
  }, []);

  useEffect(() => {
    changeLoadingStatus(true, 'Loading form data');
    loadInitialFormData()
      .then(() => {
        changeLoadingStatus(false);
      })
      .catch(err => console.error(err));
  }, []);

  return (
    <Container style={{ margin: '30px 0', display: 'flex', flexDirection: 'column' }}>
      <Dimmer active={state.loading.isActive} inverted>
        <Loader inverted>{state.loading.message}</Loader>
      </Dimmer>

      <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
        <Header as="h1">
          <Header.Content>Invoice Trigger Activity Report</Header.Content>
        </Header>
      </div>
      <Segment>
        <Formik
          enableReinitialize
          initialValues={{
            projects: [],
          }}
          onSubmit={values => {
            changeLoadingStatus(true, 'Generating report...');
            getTableData({
              projects: values.projects.map(pId => ({
                id: pId,
                name: state.dictionaries.projectIdToName[pId],
              })),
            });
          }}
        >
          {({ values, handleSubmit, handleReset, setFieldValue, errors, touched }) => (
            <Form onSubmit={handleSubmit} className="attached">
              <FilterOptionsForm
                formProps={{ values, setFieldValue, errors, touched }}
                handleReset={handleReset}
                dropdownOptions={state.dropdownOptions}
                isLoading={false}
              />
              <div
                style={{
                  marginTop: '30px',
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                }}
              >
                <div>
                  <Button
                    type="submit"
                    primary
                    icon
                    labelPosition="right"
                    disabled={values.projects.length === 0}
                  >
                    Generate Report
                    <Icon name="line graph" />
                  </Button>
                </div>
              </div>
            </Form>
          )}
        </Formik>
      </Segment>

      {state.tableData.projects.length > 0 && hasTaskDataToShow() ? (
        <React.Fragment>
          <MonthlyInvoiceTotalChart projects={state.tableData.projects} />
          <ReportTable projects={state.tableData.projects} />
        </React.Fragment>
      ) : (
        renderWhenNoData()
      )}

      <br />
      <br />
      <br />
    </Container>
  );
}

export default InvoiceTriggerActivityReport;
