import React from 'react';
import { Table } from 'semantic-ui-react';

function CostAnalysisReportTableFooter({ rows, prepareRow }) {
  const totalRow = rows[rows.length - 1];
  prepareRow(totalRow);

  return (
    <Table.Footer>
      <Table.Row {...totalRow.getRowProps()}>
        {totalRow.cells.map(cell => {
          return (
            <Table.HeaderCell
              {...cell.getCellProps()}
              style={{ fontWeight: '700' }}
              className="total-footer-cell"
            >
              {cell.render('Cell')}
            </Table.HeaderCell>
          );
        })}
      </Table.Row>
    </Table.Footer>
  );
}

export default CostAnalysisReportTableFooter;
