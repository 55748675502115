import React from 'react';
import PropTypes from 'prop-types';
import {
  ResponsiveContainer,
  LineChart,
  Line,
  CartesianGrid,
  XAxis,
  YAxis,
  Tooltip,
  ReferenceLine,
  Legend,
  Label,
} from 'recharts';
import { Message } from 'semantic-ui-react';
import { getGraphColors } from '../utils/colors';
import MultiProjectFinancialsChartTooltip from './MultiProjectFinancialsChartTooltip';

function MultiProjectFinancialsChart({ reportData, getProjectNameFrom10kftId, viewOptions }) {
  const today = new Date();
  const todayMonth = today.getMonth();
  const todayYear = today.getFullYear();

  function getCostType() {
    return viewOptions.costType === 'incurred' ? 'Costs' : 'Breakeven Costs';
  }

  // Filter report data for empty projects -- stops error when monthlyData is empty
  const validReportData = {};
  const emptyReportData = {};
  for (const project in reportData) {
    const data = reportData[project];
    if (data.monthlyData.length > 0) {
      validReportData[project] = data;
    } else {
      emptyReportData[project] = data;
    }
  }

  const selectedProjectIds = Object.keys(validReportData);

  /**
   * Combine project report data
   *  - Find min and max dates
   *  - For each date in range, object entry for difference (payments - costs)
   *
   * [ { date: '', 'Project Name': xxxx, 'Project Name 2': xxx, ... } ]
   */

  // Create array of all dates in project selection
  const dates = Array.from(
    new Set(
      selectedProjectIds.reduce(
        (pIdAcc, pId) => [
          ...pIdAcc,
          ...validReportData[pId].monthlyData.reduce((mAcc, m) => [...mAcc, m.date], []),
        ],
        []
      )
    )
  );

  const projectTotals = {};
  for (const projectId of selectedProjectIds) {
    const projectMonthlyData = validReportData[projectId].monthlyData;
    const projectName = getProjectNameFrom10kftId(projectId);

    const costType = getCostType();

    if (projectMonthlyData[0].date)
      projectTotals[projectName] = projectMonthlyData.reduce(
        (acc, point) => ({
          ...acc,
          [point.date]: point['Payments'] - point[costType],
        }),
        {}
      );
  }

  const projectNameKeys = Object.keys(projectTotals);
  const monthlyTotals = dates.reduce((acc, date) => {
    const projectDateTotals = projectNameKeys.reduce((pAcc, projectNameKey) => {
      if (projectTotals[projectNameKey][date]) {
        return { ...pAcc, [projectNameKey]: projectTotals[projectNameKey][date] };
      } else {
        return pAcc;
      }
    }, {});
    const dateTotal = {
      date,
      ...projectDateTotals,
    };

    return [...acc, dateTotal];
  }, []);

  /**
   * Renders graph lines for each project. Colors are generated programmatically.
   * @returns Array of Line components
   */
  function renderGraphLines() {
    const projects = Object.keys(projectTotals);
    const colors = getGraphColors(projects.length);

    return projects.map((contractProject, i) => (
      <Line
        key={contractProject}
        type="monotone"
        dataKey={contractProject}
        stroke={colors[i]}
        dot={false}
      />
    ));
  }

  function getCashNeutralStatus() {
    const costType = getCostType();
    let sum = 0;
    for (const project in validReportData) {
      const date = `${todayMonth + 1}/${todayYear}`;
      const monthlyData = validReportData[project].monthlyData;
      const data = monthlyData.find(item => item.date === date);
      if (data) {
        sum += data['Payments'];
        sum -= data[costType];
      }
    }
    return sum;
  }

  return (
    <React.Fragment>
      {/* List of projects not included in chart */}
      {Object.keys(emptyReportData).length > 0 && (
        <Message warning>
          <Message.Header>These empty projects were not included:</Message.Header>
          <Message.List>
            {Object.keys(emptyReportData).map(projectId => (
              <Message.Item key={`empty-${projectId}`}>
                {getProjectNameFrom10kftId(projectId)}
              </Message.Item>
            ))}
          </Message.List>
        </Message>
      )}

      {/* Chart */}
      <ResponsiveContainer height={500} width="100%">
        <LineChart data={monthlyTotals} stackOffset="sign">
          {/* Line for each project */}
          {renderGraphLines()}

          {viewOptions.showCartesianGrid && <CartesianGrid strokeDasharray="1 5" />}
          <XAxis dataKey="date" />
          <YAxis
            axisLine={false}
            tickLine={false}
            tickFormatter={val =>
              val.toLocaleString('en-US', { style: 'currency', currency: 'USD' })
            }
            width={100}
            domain={['auto', 'auto']}
          />
          <Tooltip content={<MultiProjectFinancialsChartTooltip />} />
          <ReferenceLine y={0} stroke="#b3b3b3" strokeWidth={2} ifOverflow="extendDomain" />
          <ReferenceLine
            x={`${todayMonth + 1}/${todayYear}`}
            stroke="#aaaaaa"
            strokeWidth={2}
            ifOverflow="extendDomain"
          >
            <Label
              value={`As of today: ${getCashNeutralStatus().toLocaleString('en-US', {
                style: 'currency',
                currency: 'USD',
              })}`}
              position="insideTopRight"
              style={{ fill: '#aaaaaa' }}
            />
          </ReferenceLine>

          <Legend verticalAlign="bottom" height={36} />
        </LineChart>
      </ResponsiveContainer>
    </React.Fragment>
  );
}

MultiProjectFinancialsChart.propTypes = {
  reportData: PropTypes.shape({
    [PropTypes.string]: PropTypes.arrayOf(
      PropTypes.shape({
        date: PropTypes.string,
        costs: PropTypes.number,
        payments: PropTypes.number,
      })
    ),
  }).isRequired,
  getProjectNameFrom10kftId: PropTypes.func.isRequired,
  viewOptions: PropTypes.shape({
    showPassThru: PropTypes.bool.isRequired,
    showCartesianGrid: PropTypes.bool.isRequired,
  }).isRequired,
};

export default MultiProjectFinancialsChart;
