import React from 'react';
import { camelToTitleCase } from '../../../utils/string-transformations';
import { convertMonthYearNumbersToDate } from '../../../utils/dates';
import '../styles/tooltip.scss';
import Big from 'big.js';
import { setBigForCurrency } from '../../../utils/numbers';

function RevenueBarChartTooltip({ active, payload, reportType, yearlyTotals }) {
  setBigForCurrency(Big);

  function wrapCurrency(val) {
    return val.toLocaleString('en-US', { style: 'currency', currency: 'USD' });
  }

  if (active && payload && payload.length) {
    const namesAndValues = payload
      .map(({ name, value, fill, payload }, index) => ({
        name: camelToTitleCase(name),
        value,
        index,
        fill,
        date: payload.date,
      }))
      .reduce(
        (acc, item) => ({
          ...acc,
          [item.name]: {
            index: item.index,
            value: item.value,
            fill: item.fill,
            date: item.date,
          },
        }),
        {}
      );

    const currentYear =
      reportType === 'monthly' ? namesAndValues['Amount'].date.split('/')[1] : null;

    return (
      <div className="pfc-tooltip">
        <div className="pfc-tooltip-header">
          <span className="pfc-tooltip-header-text">
            {reportType === 'monthly' ? (
              <strong>{convertMonthYearNumbersToDate(namesAndValues['Amount'].date)}</strong>
            ) : (
              <strong>{namesAndValues['Amount'].date}</strong>
            )}
          </span>
        </div>
        <div>
          <div className="tooltip-label">
            <strong style={{ color: namesAndValues['Amount'].fill, paddingRight: '5px' }}>
              Amount:
            </strong>
            <span className="pfc-tooltip-amount">
              {wrapCurrency(namesAndValues['Amount'].value)}
            </span>
          </div>
        </div>
        {reportType === 'monthly' && (
          <React.Fragment>
            <hr />
            <div>
              <div className="tooltip-label">
                <strong style={{ paddingRight: '5px' }}>{currentYear} Total:</strong>
                <span className="pfc-tooltip-amount">
                  {wrapCurrency(yearlyTotals[currentYear])}
                </span>
              </div>
            </div>
          </React.Fragment>
        )}
      </div>
    );
  }

  return null;
}

export default RevenueBarChartTooltip;
