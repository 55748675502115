import React, { useState, useEffect, useReducer } from 'react';
import { useToasts } from 'react-toast-notifications';
import {
  Table,
  Button,
  Header,
  Icon,
  Dimmer,
  Loader,
  Checkbox,
  Divider,
  Popup,
} from 'semantic-ui-react';
import {
  getActiveProjectsFinancialsReport,
  refreshActiveProjectsFinancialsReport,
} from './api/active-projects-financials';
import { isNumeric } from '../../utils/numbers';
import columnOrdering from './column-order';
import ExportButton from './components/ExportButton';
import CalculationsHelpModal from '../../components/CalculationsHelpModal';
import calculationExplanations from './data/calculation-explanations';
import { useAuth, useAuthFunctions } from '../../context/AuthContext';

function ActiveProjectsFinancialStatus({ history }) {
  const auth = useAuth();
  const { token } = auth.user;
  const { logout } = useAuthFunctions();

  /**
   * Function used for sorting rows based on a given column. Takes into account dates, numbers, and strings.
   * @param {string} key Name of column used for sorting
   * @returns Function to sort rows based on given column. To be passed into [].sort()
   */
  const sortBy = key => (a, b) => {
    if (isNumeric(a[key]) && isNumeric(b[key])) {
      // For currency values (stored as numbers in state and rendered as currency in component)
      return a[key] > b[key] ? 1 : b[key] > a[key] ? -1 : 0;
    } else if (isNaN(Date.parse(a[key])) || isNaN(Date.parse(b[key]))) {
      let valA = a[key].toLowerCase();
      let valB = b[key].toLowerCase();
      return valA > valB ? 1 : valB > valA ? -1 : 0;
    } else {
      let valA = new Date(a[key]);
      let valB = new Date(b[key]);
      return valA > valB ? 1 : valB > valA ? -1 : 0;
    }
  };

  /**
   * Finds the data types for each column in the table.
   * @param {array} reportRows Array of row objects with headers as keys.
   * @returns Dictionary containing the data type for each column.
   */
  const getColumnDataTypes = reportRows => {
    const typesDict = {};
    for (const row of reportRows) {
      for (const headerName of columnOrdering) {
        const value = row[headerName];
        typesDict[headerName] = typeof value;
      }
      if (!Object.values(typesDict).some(t => t === 0)) return typesDict; // if all column types determined
    }
    return typesDict;
  };

  /**
   * TODO: Move this out into its own file like other view components.
   */
  function reducer(state, action) {
    switch (action.type) {
      case 'CHANGE_TABLE_SORT':
        if (state.tableSort.column === action.column) {
          return {
            ...state,
            reportRows: [...state.reportRows].reverse(),
            tableSort: {
              ...state.tableSort,
              direction: state.tableSort.direction === 'ascending' ? 'descending' : 'ascending',
            },
          };
        }
        return {
          ...state,
          // reportRows: _.sortBy(state.reportRows, [ action.column ]),
          reportRows: state.reportRows.sort(sortBy(action.column)),
          tableSort: {
            ...state.tableSort,
            column: action.column,
            direction: 'ascending',
          },
        };
      case 'UPDATE_REPORT_ROWS':
        return {
          ...state,
          reportRows: action.reportRows,
          tableHeaders: Object.keys(action.reportRows[0]),
          columnTypes: getColumnDataTypes(action.reportRows),
        };
      case 'CACHE_REPORT_DATA':
        return {
          ...state,
          reportDataCache: action.data,
        };
      default:
        return state;
    }
  }

  const { addToast } = useToasts();

  const [state, dispatch] = useReducer(reducer, {
    reportRows: [],
    tableHeaders: [],
    columnTypes: [],
    tableSort: { column: null, direction: null },
    reportDataCache: {},
  });
  const [lastModified, setLastModified] = useState(new Date());
  const [isLoadingData, setIsLoadingData] = useState(false);
  const [showPassThru, setShowPassThru] = useState(false);
  const [removeLessThan20k, setRemoveLessThan20k] = useState(true);

  const { reportRows, columnTypes, tableSort, reportDataCache } = state;

  useEffect(() => {
    /**
     * Gets active project financials report data from server.
     */
    setIsLoadingData(true);
    getActiveProjectsFinancialsReport(token)
      .then(res => {
        const { data, status } = res;

        // cache all report data received
        dispatch({ type: 'CACHE_REPORT_DATA', data });

        if (status === 200) {
          setLastModified(new Date(data.json.dateModified));
          dispatch({ type: 'UPDATE_REPORT_ROWS', reportRows: data.json.projects });
          setIsLoadingData(false);
        } else if (status === 401) {
          throw new Error('Your session has expired. Please sign in again.');
        } else if (status === 500) {
          throw new Error(
            'Internal server error. Please reach out to Jamie or Kyle and let them know.'
          );
        } else {
          throw new Error(
            `An error has occured (status code ${status}). Please try refreshing page.`
          );
        }
      })
      .catch(err => {
        addToast(err.message, { appearance: 'error', autoDismiss: true });

        if (err.message === 'Your session has expired. Please sign in again.') {
          logout();
          history.push('/login');
        }
      });
  }, [history, logout, token]);

  function handleDataRefresh() {
    setIsLoadingData(true);
    refreshActiveProjectsFinancialsReport(token)
      .then(data => {
        // setCSV(data.csv);
        setLastModified(new Date(data.json.dateModified));
        dispatch({ type: 'UPDATE_REPORT_ROWS', reportRows: data.json.projects });
        setIsLoadingData(false);
      })
      .catch(err => console.error(err));
  }

  /**
   * Formats a number as a currency string. Currency format is USD.
   * @param {number} num Number to be formatted as currency.
   * @returns String representing a currency formatted number.
   */
  function formatCurrency(num) {
    const formatter = new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
      minimumFractionDigits: 2,
    });

    return formatter.format(num);
  }

  /**
   * Toggles table and CSV data between showing and not showing pass-thru items.
   */
  function handleTogglePassThru() {
    const { reportDataCache } = state;
    const isNowShowingPassThru = !showPassThru;
    const newCSVData = isNowShowingPassThru ? reportDataCache.csvWithPassThru : reportDataCache.csv;
    const newLastModifiedDate = isNowShowingPassThru
      ? reportDataCache.jsonWithPassThru.dateModified
      : reportDataCache.json.dateModified;
    const reportRowsData = isNowShowingPassThru
      ? reportDataCache.jsonWithPassThru.projects
      : reportDataCache.json.projects;

    setShowPassThru(isNowShowingPassThru);
    // setCSV(newCSVData);
    setLastModified(new Date(newLastModifiedDate));
    dispatch({ type: 'UPDATE_REPORT_ROWS', reportRows: reportRowsData });
  }

  /**
   * Toggles table displaying or not displaying projects with a "Accrued Total Revenue"
   * value of <$20,000.
   */
  function handleToggle20k() {
    // Get cached report data and change the reportRows value in state.
    const allProjectRows = reportDataCache.json.projects;
    const newReportRows = removeLessThan20k
      ? allProjectRows.filter(p => p['Accrued Total Revenue'] > 20000)
      : allProjectRows;
    dispatch({ type: 'UPDATE_REPORT_ROWS', reportRows: newReportRows });
    setRemoveLessThan20k(!removeLessThan20k);
  }

  return (
    <div>
      <Dimmer active={isLoadingData} inverted>
        <Loader inverted>Loading report data...</Loader>
      </Dimmer>

      <div className="active-projects-report-menu">
        <div style={{ marginBottom: '30px' }}>
          <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
            <Header as="h1">
              <Header.Content>Active Projects Financial Summary Report</Header.Content>
              <Header.Subheader>Last Updated: {lastModified.toLocaleString()} </Header.Subheader>
            </Header>
            <CalculationsHelpModal calculationExplanations={calculationExplanations} />
          </div>
        </div>
        <div
          style={{
            margin: '20px 0',
          }}
        >
          <Header>
            <Icon name={'options'} />
            <Header.Content>Options</Header.Content>
          </Header>
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <div style={{ marginTop: '5px', marginBottom: '5px' }}>
              <Checkbox
                toggle
                label="Show Pass-thru"
                onChange={handleTogglePassThru}
                checked={showPassThru}
              />
            </div>
            <div style={{ marginTop: '5px', marginBottom: '5px' }}>
              <Checkbox
                toggle
                label="Show <$20,000"
                onChange={handleToggle20k}
                checked={removeLessThan20k}
              />{' '}
              <Popup
                trigger={<Icon name="info circle" />}
                content="Toggle on/off projects with less than $20,000 in Accrued Total Revenue."
              />
            </div>
          </div>
        </div>
        <Divider />
        <div
          style={{
            margin: '10px 0',
            marginTop: '20px',
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
          }}
        >
          <div>
            <Button icon labelPosition="left" onClick={handleDataRefresh}>
              <Icon name="refresh" />
              Refresh Data
            </Button>
          </div>
          <ExportButton
            reportRows={reportRows}
            columnTypes={columnTypes}
            showPassThru={showPassThru}
            addToast={addToast}
            lastModified={lastModified}
          />
        </div>
      </div>

      <div style={{ overflowX: 'auto', padding: '10px 0 2px 0' }}>
        {reportRows.length > 0 && (
          <Table sortable celled singleLine unstackable selectable>
            <Table.Header>
              <Table.Row>
                {columnOrdering.map((headerName, hIndex) => (
                  <Table.HeaderCell
                    key={`column-header-${hIndex}`}
                    sorted={tableSort.column === headerName ? tableSort.direction : null}
                    onClick={() => dispatch({ type: 'CHANGE_TABLE_SORT', column: headerName })}
                  >
                    {headerName}
                  </Table.HeaderCell>
                ))}
              </Table.Row>
            </Table.Header>

            <Table.Body>
              {reportRows.map((row, rIndex) => (
                <Table.Row key={`report row ${rIndex}`}>
                  {columnOrdering.map((headerName, vIndex) => {
                    const val = row[headerName];
                    return (
                      <Table.Cell
                        key={`report-cell-${rIndex}-${vIndex}`}
                        positive={
                          headerName.includes('Net') && typeof val === 'number' && val >= 0
                            ? true
                            : false
                        }
                        negative={
                          headerName.includes('Net') && typeof val === 'number' && val < 0
                            ? true
                            : false
                        }
                        textAlign={typeof val === 'number' || val.includes('%') ? 'right' : 'left'}
                      >
                        {typeof val === 'number' ? formatCurrency(val) : val}
                      </Table.Cell>
                    );
                  })}
                </Table.Row>
              ))}
            </Table.Body>

            <Table.Footer style={{ border: '10px solid black' }}>
              <Table.Row>
                {columnOrdering.map((headerName, hIndex) => {
                  if (columnTypes[headerName] === 'number') {
                    const columnTotal = reportRows.reduce((sum, r) => (sum += r[headerName]), 0);
                    return (
                      <Table.HeaderCell key={`column-total-cell-${hIndex}`} textAlign="right">
                        <strong>{formatCurrency(columnTotal)}</strong>
                      </Table.HeaderCell>
                    );
                  } else {
                    return <Table.HeaderCell key={`column-total-cell-${hIndex}`} />;
                  }
                })}
              </Table.Row>
              <Table.Row>
                {columnOrdering.map((headerName, hIndex) => {
                  if (columnTypes[headerName] === 'number') {
                    return (
                      <Table.HeaderCell
                        key={`bottom-header-cell-${hIndex}`}
                        sorted={tableSort.column === headerName ? tableSort.direction : null}
                        onClick={() => dispatch({ type: 'CHANGE_TABLE_SORT', column: headerName })}
                      >
                        {headerName}
                      </Table.HeaderCell>
                    );
                  } else {
                    return <Table.HeaderCell key={`bottom-header-cell-${hIndex}`} />;
                  }
                })}
              </Table.Row>
            </Table.Footer>
          </Table>
        )}
      </div>
      <br />
      <br />
    </div>
  );
}

export default ActiveProjectsFinancialStatus;
