export function getOpenInSheetsFormatRequests(token, userEmail, responseData, googleSheetsState) {
  const { spreadsheetId, sheetId } = responseData;
  const { values, columns } = googleSheetsState;

  function convertLetterToNumber(columnLetter) {
    let out = 0;
    let len = columnLetter.length;
    for (let pos = 0; pos < len; pos++) {
      out += (columnLetter.charCodeAt(pos) - 64) * Math.pow(26, len - pos - 1);
    }
    return out;
  }

  const categoryIndices = values.reduce(
    (acc, val, index) =>
      val[0] !== '' && val[0].toLowerCase() !== 'total' && val[0].toLowerCase() !== 'category'
        ? [ ...acc, index ]
        : acc,
    []
  );
  console.log('categoryIndices');
  console.log(categoryIndices);

  const formatRequest = {
    url: `/api/google-sheets/batch-update-spreadsheet`,
    method: 'post',
    headers: {
      Authorization: `Bearer ${token}`,
    },
    data: {
      spreadsheetId,
      userEmail,
      requests: [
        // Format currency columns
        {
          // 10kft Rate -> Currency format
          repeatCell: {
            range: {
              sheetId,
              startRowIndex: 1,
              endRowIndex: values.length,
              startColumnIndex: convertLetterToNumber(columns['10kftRate'].letter) - 1,
              endColumnIndex: convertLetterToNumber(columns['10kftRate'].letter),
            },
            cell: {
              userEnteredFormat: {
                numberFormat: {
                  type: 'CURRENCY',
                },
              },
            },
            fields: 'userEnteredFormat.numberFormat',
          },
        },
        {
          // Breakeven Rate -> Currency format
          repeatCell: {
            range: {
              sheetId,
              startRowIndex: 1,
              endRowIndex: values.length,
              startColumnIndex: convertLetterToNumber(columns.breakevenRate.letter) - 1,
              endColumnIndex: convertLetterToNumber(columns.breakevenRate.letter),
            },
            cell: {
              userEnteredFormat: {
                numberFormat: {
                  type: 'CURRENCY',
                },
              },
            },
            fields: 'userEnteredFormat.numberFormat',
          },
        },
        {
          // Budget -> Currency format
          repeatCell: {
            range: {
              sheetId,
              startRowIndex: 1,
              endRowIndex: values.length,
              startColumnIndex: convertLetterToNumber(columns.budget.letter) - 1,
              endColumnIndex: convertLetterToNumber(columns.budget.letter),
            },
            cell: {
              userEnteredFormat: {
                numberFormat: {
                  type: 'CURRENCY',
                },
              },
            },
            fields: 'userEnteredFormat.numberFormat',
          },
        },
        {
          // Incurred Cost -> Currency format
          repeatCell: {
            range: {
              sheetId,
              startRowIndex: 1,
              endRowIndex: values.length,
              startColumnIndex: convertLetterToNumber(columns.incurredCost.letter) - 1,
              endColumnIndex: convertLetterToNumber(columns.incurredCost.letter),
            },
            cell: {
              userEnteredFormat: {
                numberFormat: {
                  type: 'CURRENCY',
                },
              },
            },
            fields: 'userEnteredFormat.numberFormat',
          },
        },
        {
          // Scheduled Cost -> Currency format
          repeatCell: {
            range: {
              sheetId,
              startRowIndex: 1,
              endRowIndex: values.length,
              startColumnIndex: convertLetterToNumber(columns.scheduledCost.letter) - 1,
              endColumnIndex: convertLetterToNumber(columns.scheduledCost.letter),
            },
            cell: {
              userEnteredFormat: {
                numberFormat: {
                  type: 'CURRENCY',
                },
              },
            },
            fields: 'userEnteredFormat.numberFormat',
          },
        },
        {
          // Breakeven Cost -> Currency format
          repeatCell: {
            range: {
              sheetId,
              startRowIndex: 1,
              endRowIndex: values.length,
              startColumnIndex: convertLetterToNumber(columns.breakevenCost.letter) - 1,
              endColumnIndex: convertLetterToNumber(columns.breakevenCost.letter),
            },
            cell: {
              userEnteredFormat: {
                numberFormat: {
                  type: 'CURRENCY',
                },
              },
            },
            fields: 'userEnteredFormat.numberFormat',
          },
        },
        {
          // Scheduled Breakeven Cost -> Currency format
          repeatCell: {
            range: {
              sheetId,
              startRowIndex: 1,
              endRowIndex: values.length,
              startColumnIndex: convertLetterToNumber(columns.scheduledBreakevenCost.letter) - 1,
              endColumnIndex: convertLetterToNumber(columns.scheduledBreakevenCost.letter),
            },
            cell: {
              userEnteredFormat: {
                numberFormat: {
                  type: 'CURRENCY',
                },
              },
            },
            fields: 'userEnteredFormat.numberFormat',
          },
        },
        // Format header row
        {
          repeatCell: {
            range: {
              sheetId,
              startRowIndex: 0,
              endRowIndex: 1,
            },
            cell: {
              userEnteredFormat: {
                backgroundColor: {
                  red: 42 / 255,
                  green: 78 / 255,
                  blue: 117 / 255,
                },
                textFormat: {
                  foregroundColor: {
                    red: 255 / 255,
                    green: 255 / 255,
                    blue: 255 / 255,
                  },
                  bold: true,
                },
              },
            },
            fields: 'userEnteredFormat(backgroundColor,textFormat)',
          },
        },
        {
          updateSheetProperties: {
            properties: {
              sheetId,
              gridProperties: {
                frozenRowCount: 1,
              },
            },
            fields: 'gridProperties.frozenRowCount',
          },
        },
        // Format category rows color
        ...categoryIndices.map(index => ({
          repeatCell: {
            range: {
              sheetId,
              startRowIndex: index,
              endRowIndex: index + 1,
            },
            cell: {
              userEnteredFormat: {
                backgroundColor: {
                  red: 210 / 255,
                  green: 226 / 255,
                  blue: 241 / 255,
                },
              },
            },
            fields: 'userEnteredFormat(backgroundColor,)',
          },
        })),
        // Total row color
        {
          repeatCell: {
            range: {
              sheetId,
              startRowIndex: values.length - 1,
              endRowIndex: values.length,
            },
            cell: {
              userEnteredFormat: {
                backgroundColor: {
                  red: 42 / 255,
                  green: 78 / 255,
                  blue: 117 / 255,
                },
                textFormat: {
                  foregroundColor: {
                    red: 255 / 255,
                    green: 255 / 255,
                    blue: 255 / 255,
                  },
                  bold: true,
                },
              },
            },
            fields: 'userEnteredFormat(backgroundColor,textFormat)',
          },
        },
        // Resize to fit currency formatted cells
        {
          autoResizeDimensions: {
            dimensions: {
              sheetId,
              dimension: 'COLUMNS',
              startIndex: 0,
            },
          },
        },
      ],
    },
  };

  return formatRequest;
}
