import React, { useMemo } from 'react';
import { useTable, useExpanded } from 'react-table';
import { Table, Icon, Header, Segment, Loader } from 'semantic-ui-react';
import columnDefinitions from '../data/column-definitions';
import '../styles/table.scss';
import CostAnalysisReportTableFooter from './CostAnalysisReportTableFooter';

function getAllExpanded(tableData, prevExpanded = '', expanded = {}) {
  if (tableData.length === 0) return expanded;
  for (const [ index, value ] of tableData.entries()) {
    const key = `${prevExpanded}${prevExpanded === '' ? '' : '.'}${index}`;
    expanded[key] = true;

    if (value.subRows && value.subRows.length > 0) {
      getAllExpanded(value.subRows, key, expanded);
    }
  }
}

function CostAnalysisReportTable({ state, formIsLoading }) {
  const initialExpanded = useMemo(() => getAllExpanded(state.report.tableData), [
    state.report.tableData,
  ]);
  const columns = useMemo(columnDefinitions, [ columnDefinitions ]);
  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = useTable(
    {
      columns: columns,
      data: state.report.tableData,
      initialState: {
        expanded: initialExpanded,
      },
    },
    useExpanded
  );

  return (
    <React.Fragment>
      <div style={{ overflowX: 'auto', padding: '10px 0 2px 0' }}>
        {state.report.tableData.length === 0 || formIsLoading ? (
          <Segment placeholder className="cost-analysis-report-table-placeholder">
            <Header icon>
              <div>
                <Loader active={formIsLoading} inline="centered" style={{ marginBottom: '20px' }} />
                {formIsLoading ? (
                  'Loading report data...'
                ) : (
                  'Select project above to generate report.'
                )}
              </div>
            </Header>
          </Segment>
        ) : (
          <Table celled singleLine unstackable compact selectable {...getTableProps()}>
            <Table.Header>
              {headerGroups.map(headerGroup => (
                <Table.Row {...headerGroup.getHeaderGroupProps()}>
                  {headerGroup.headers.map(column => (
                    <Table.HeaderCell
                      {...column.getHeaderProps()}
                      className="inverted-table-header">
                      {column.render('Header')}
                    </Table.HeaderCell>
                  ))}
                </Table.Row>
              ))}
            </Table.Header>
            <Table.Body {...getTableBodyProps()}>
              {rows.map((row, i) => {
                prepareRow(row);
                const categoryValue = row.cells[0].value;
                if (categoryValue.toLowerCase() === 'total') return null;

                return (
                  <Table.Row
                    {...row.getRowProps()}
                    style={{ backgroundColor: `${categoryValue !== '' && '#f9fafb'}` }}>
                    {row.cells.map(cell => {
                      if (cell.column.id === 'category' && cell.value === '') {
                        return (
                          <Table.Cell
                            style={{
                              backgroundColor: '#f9fafb',
                              borderTop: '1px solid transparent',
                            }}
                            {...cell.getCellProps()}>
                            {cell.render('Cell')}
                          </Table.Cell>
                        );
                      }

                      if (cell.column.id === 'budget') {
                        return (
                          <Table.Cell
                            className="light-blue-highlight-cell"
                            {...cell.getCellProps()}>
                            {cell.render('Cell')}
                          </Table.Cell>
                        );
                      }
                      if (
                        cell.column.id === 'incurredCost' ||
                        cell.column.id === 'budgetRemaining' ||
                        cell.column.id === 'scheduledCost'
                      ) {
                        return (
                          <Table.Cell
                            className="light-orange-highlight-cell"
                            {...cell.getCellProps()}>
                            {cell.render('Cell')}
                          </Table.Cell>
                        );
                      }
                      if (
                        cell.column.id === 'breakevenCost' ||
                        cell.column.id === 'profit' ||
                        cell.column.id === 'scheduledBreakevenCost'
                      ) {
                        return (
                          <Table.Cell
                            className="light-green-highlight-cell"
                            {...cell.getCellProps()}>
                            {cell.render('Cell')}
                          </Table.Cell>
                        );
                      }
                      return (
                        <Table.Cell {...cell.getCellProps()}>{cell.render('Cell')}</Table.Cell>
                      );
                    })}
                  </Table.Row>
                );
              })}
            </Table.Body>
            <CostAnalysisReportTableFooter rows={rows} prepareRow={prepareRow} />
          </Table>
        )}
      </div>
    </React.Fragment>
  );
}

export default CostAnalysisReportTable;
