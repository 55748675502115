import React from 'react';
import PropTypes from 'prop-types';
import { Icon, Checkbox, Button } from 'semantic-ui-react';
import '../styles/view-options.scss';
import { useAuth } from '../../../context/AuthContext';

function ProjectFinancialsChartViewOptions({ handleToggleViewOption, viewOptions, isMultiReport }) {
  const auth = useAuth();
  const { roles } = auth.user;

  const showBreakevenCosts = roles.some(
    r => r === 'leadership' || r === 'admin' || r === 'partners'
  );

  return (
    <div>
      <div className="view-options-box">
        {/* View Options */}
        <div>
          <div className="view-options-section-header">
            <Icon name="options" />
            <strong>View Options</strong>
          </div>
          <div className="view-options-checkbox-list">
            <Checkbox
              label="Show Grid"
              onChange={() => handleToggleViewOption('showCartesianGrid')}
              checked={viewOptions.showCartesianGrid}
              toggle
            />
          </div>
        </div>
        {showBreakevenCosts && isMultiReport && (
          <div>
            {/* Data Options */}
            <div className="view-options-section-header">
              <Icon name="database" />
              <strong>Data Options</strong>
            </div>
            <div>
              {/* TODO: incurred/breakeven toggle for multi project reports only */}
              <div style={{ marginTop: '5px', marginBottom: '3px' }}>
                <strong>Costs Type</strong>
              </div>
              <Button.Group>
                <Button
                  onClick={() => handleToggleViewOption('costType/incurred')}
                  active={viewOptions.costType === 'incurred'}
                >
                  Incurred
                </Button>
                <Button.Or />
                <Button
                  onClick={() => handleToggleViewOption('costType/breakeven')}
                  active={viewOptions.costType === 'breakeven'}
                >
                  Breakeven
                </Button>
              </Button.Group>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

ProjectFinancialsChartViewOptions.propTypes = {
  handleToggleViewOption: PropTypes.func.isRequired,
  viewOptions: PropTypes.shape({
    showPassThru: PropTypes.bool.isRequired,
    showCartesianGrid: PropTypes.bool.isRequired,
  }).isRequired,
  isMultiReport: PropTypes.bool.isRequired,
};

ProjectFinancialsChartViewOptions.defaultProps = {
  isMultiReport: true,
};

export default ProjectFinancialsChartViewOptions;
