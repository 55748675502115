import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Icon, Modal } from 'semantic-ui-react';
import useInterval from '../hooks/useInterval';

function RedirectPopup({ delay, content, callback }) {
  const { header, headerIcon, description, redirectLocation } = content;
  const [ seconds, setSeconds ] = useState(delay);

  useInterval(() => {
    setSeconds(seconds - 1);
  }, seconds === 0 ? null : 1000);

  useEffect(
    () => {
      if (seconds === 0) {
        callback();
      }
    },
    [ seconds ]
  );

  return (
    <Modal open={seconds > 0} closeOnEscape={false} closeOnDimmerClick={false}>
      <Modal.Header>
        {headerIcon && headerIcon !== '' ? <Icon name={headerIcon} /> : null}
        {header}
      </Modal.Header>
      <Modal.Content>
        <Modal.Description>{description}</Modal.Description>
        <Modal.Actions
          style={{
            marginTop: '20px',
          }}>
          <div>
            <strong>
              Redirecting{redirectLocation ? ` to ${redirectLocation}` : null} in {seconds}{' '}
              seconds...
            </strong>{' '}
            <button
              style={{
                backgroundColor: 'transparent',
                border: 'none',
                padding: '0',
                color: '#069',
                textDecoration: 'underline',
                cursor: 'pointer',
              }}
              onClick={callback}>
              Skip{redirectLocation ? ` to ${redirectLocation}` : null}
            </button>
          </div>
        </Modal.Actions>
      </Modal.Content>
    </Modal>
  );
}

RedirectPopup.propTypes = {
  delay: PropTypes.number,
  content: PropTypes.shape({
    header: PropTypes.string.isRequired,
    headerIcon: PropTypes.string,
    description: PropTypes.string.isRequired,
    redirectLocation: PropTypes.string,
  }),
  callback: PropTypes.func.isRequired,
};

RedirectPopup.defaultProps = {
  delay: 5,
  redirectLocation: null,
};

export default RedirectPopup;
