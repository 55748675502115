import React from 'react';
import BetterRechartsTooltip from '../../../../components/BetterRechartsTooltip';
import { convertMonthYearNumbersToDate } from '../../../../utils/dates';

function MonthlyInvoiceTotalChartTooltip({ active, payload }) {
  if (active && payload && payload.length > 0) {
    const headerText = convertMonthYearNumbersToDate(payload[0].payload.date);
    const items = payload.map(p => {
      return {
        label: p.name,
        labelColor: p.fill,
        value: p.value,
        valueType: 'currency',
      };
    });
    const totals = [
      {
        name: 'Invoice',
        value: payload.reduce((sum, p) => sum + p.value, 0),
        valueType: 'currency',
      },
    ];

    return <BetterRechartsTooltip headerText={headerText} items={items} totals={totals} />;
  }

  return null;
}

export default MonthlyInvoiceTotalChartTooltip;
