import React from 'react';
import { convertMonthYearNumbersToDate } from '../../../utils/dates';
import '../styles/tooltip.scss';

function MultiProjectFinancialsChartTooltip({ active, payload, label }) {
  function wrapCurrency(val) {
    return val.toLocaleString('en-US', { style: 'currency', currency: 'USD' });
  }

  if (active && payload && payload.length) {
    return (
      <div className="pfc-tooltip">
        <div className="pfc-tooltip-header">
          <span className="pfc-tooltip-header-text">
            <strong>{convertMonthYearNumbersToDate(payload[0].payload.date)}</strong>
          </span>
        </div>
        <div>
          {payload.map(item => (
            <div className="tooltip-label">
              <strong style={{ color: item.color, paddingRight: '5px' }}>{item.name}:</strong>
              <span className="pfc-tooltip-amount">{wrapCurrency(item.value)}</span>
            </div>
          ))}
        </div>
      </div>
    );
  }

  return null;
}

export default MultiProjectFinancialsChartTooltip;
