/**
 * Generates shading details for each column in the exported Active
 * Projects Financial Summary report.
 * @param {array} columnHeaders Array of column headers for the Active Projects Financial Summary report table.
 * @returns Array of objects with specifics on how a column should be shaded.
 */
function getColumnsToShade(columnHeaders) {
  const lightGray = {
    red: 241 / 255,
    green: 241 / 255,
    blue: 241 / 255,
  };
  const darkGray = {
    red: 212 / 255,
    green: 212 / 255,
    blue: 212 / 255,
  };
  const yellow = {
    red: 255 / 255,
    green: 240 / 255,
    blue: 197 / 255,
  };

  return [
    { name: 'Total Payments', index: columnHeaders.indexOf('Total Payments'), colors: lightGray },
    {
      name: 'Total Billables',
      index: columnHeaders.indexOf('Total Billables'),
      colors: lightGray,
    },
    { name: 'Total Expenses', index: columnHeaders.indexOf('Total Expenses'), colors: lightGray },
    { name: 'Net Cash', index: columnHeaders.indexOf('Net Cash'), colors: darkGray },
    {
      name: 'Total Breakeven',
      index: columnHeaders.indexOf('Total Breakeven'),
      colors: lightGray,
    },
    {
      name: 'Net Cash (Breakeven)',
      index: columnHeaders.indexOf('Net Cash (Breakeven)'),
      colors: darkGray,
    },
    {
      name: 'Accrued Total Revenue',
      index: columnHeaders.indexOf('Accrued Total Revenue'),
      colors: lightGray,
    },
    {
      name: 'Diff from Planned Margin',
      index: columnHeaders.indexOf('Diff from Planned Margin'),
      colors: darkGray,
    },
    {
      name: 'Actual Margin (Breakeven)',
      index: columnHeaders.indexOf('Actual Margin (Breakeven)'),
      colors: darkGray,
    },
    { name: 'Profit Margin', index: columnHeaders.indexOf('Profit Margin'), colors: yellow },
  ];
}

/**
 * Creates an array of formatting requests for a Google Sheets spreadsheet.
 * This array can then be passed into an API call to format an already
 * existing spreadsheet.
 * @param {object} responseData Response data from the create spreadsheet API call. Needs to include spreadsheetId and sheetId.
 * @param {array} projectRows Array of project row objects.
 * @param {array} columnOrdering Array of headers in column order.
 * @returns Array of format requests.
 */
export function getFormatRequests(responseData, projectRows, columnOrdering) {
  const { sheetId } = responseData;
  const startColumnForCurrency = columnOrdering.indexOf('Total Payments');
  const endColumnForCurrency = columnOrdering.indexOf('Actual Margin (Breakeven)');
  const columnsToShade = getColumnsToShade(columnOrdering);

  return [
    /**
     * Format header row.
     */
    {
      repeatCell: {
        range: {
          sheetId,
          startRowIndex: 0,
          endRowIndex: 1,
        },
        cell: {
          userEnteredFormat: {
            textFormat: {
              foregroundColor: {
                red: 0 / 255,
                green: 0 / 255,
                blue: 0 / 255,
              },
              bold: true,
            },
          },
        },
        fields: 'userEnteredFormat(textFormat)',
      },
    },
    /**
     * Freeze header row.
     */
    {
      updateSheetProperties: {
        properties: {
          sheetId,
          gridProperties: {
            frozenRowCount: 1,
          },
        },
        fields: 'gridProperties.frozenRowCount',
      },
    },
    /**
     * Format currency rows.
     * Note that this does all the currency rows at once since they are all grouped together.
     * If any additional rows are added such that the order changes, you might need to make
     * more than one format request for currency formatting.
     */
    {
      repeatCell: {
        range: {
          sheetId,
          startRowIndex: 1,
          endRowIndex: projectRows.length + 2, // +2 to include totals row
          startColumnIndex: startColumnForCurrency,
          endColumnIndex: endColumnForCurrency + 1,
        },
        cell: {
          userEnteredFormat: {
            numberFormat: {
              type: 'CURRENCY',
            },
          },
        },
        fields: 'userEnteredFormat.numberFormat',
      },
    },
    /**
     * Format column colors.
     */
    ...columnsToShade.map(c => ({
      repeatCell: {
        range: {
          sheetId,
          startRowIndex: 0,
          endRowIndex: projectRows.length + 2,
          startColumnIndex: c.index,
          endColumnIndex: c.index + 1,
        },
        cell: {
          userEnteredFormat: {
            backgroundColor: c.colors,
          },
        },
        fields: 'userEnteredFormat(backgroundColor,)',
      },
    })),
    /**
     * Format totals row at end of table.
     */
    {
      // Bold text
      repeatCell: {
        range: {
          sheetId,
          startRowIndex: 0,
          endRowIndex: 1,
        },
        cell: {
          userEnteredFormat: {
            textFormat: {
              foregroundColor: {
                red: 0 / 255,
                green: 0 / 255,
                blue: 0 / 255,
              },
              bold: true,
            },
          },
        },
        fields: 'userEnteredFormat(textFormat)',
      },
    },
    {
      // Top border
      updateBorders: {
        range: {
          sheetId,
          startRowIndex: projectRows.length + 1,
          endRowIndex: projectRows.length + 2,
          startColumnIndex: 0,
          endColumnIndex: columnOrdering.length,
        },
        top: {
          style: 'SOLID',
          width: 2,
          color: {
            red: 0 / 255,
            green: 0 / 255,
            blue: 0 / 255,
          },
        },
      },
    },
    /**
     * Resize to fit currency formatted cells.
     */
    {
      autoResizeDimensions: {
        dimensions: {
          sheetId,
          dimension: 'COLUMNS',
          startIndex: 0,
        },
      },
    },
  ];
}
