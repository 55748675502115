import React from 'react';
import { Switch, Route, withRouter } from 'react-router-dom';
import Home from '../Home';
import Login from '../Login';
import Help from '../Help';
import NoMatch from '../../components/NoMatch';
import PrivateRoute from '../../components/PrivateRoute';
import PermissionsLockedRoute from '../../components/PermissionsLockedRoute';
import { appLinks } from '../../data/app-links';

export const Main = () => {
  return (
    <React.Fragment>
      {/* MAIN CONTENT */}
      <Switch>
        <Route exact path={'/login'} component={Login} />
        <Route exact path={'/help'} component={Help} />
        <PrivateRoute exact path={'/'} component={Home} />
        {appLinks.map(item => (
          <PermissionsLockedRoute
            key={item.name}
            exact
            path={item.link}
            component={item.component}
            roles={item.roles}
          />
        ))}
        <PrivateRoute exact component={NoMatch} />
      </Switch>
    </React.Fragment>
  );
};

export default withRouter(Main);
