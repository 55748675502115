/**
 * Generates an array of colors as hexcodes that are easily distinguished from one another.
 * @param {number} numColors Number of total colors needed in graph
 * @returns An array of color hexcodes
 */
export function getGraphColors(numColors) {
  let colors = [];
  const goldenAngle = 180 * (3 - Math.sqrt(5));

  function hslToHex(h, s, l) {
    l /= 100;
    const a = (s * Math.min(l, 1 - l)) / 100;
    const f = n => {
      const k = (n + h / 30) % 12;
      const color = l - a * Math.max(Math.min(k - 3, 9 - k, 1), -1);
      return Math.round(255 * color)
        .toString(16)
        .padStart(2, '0'); // convert to Hex and prefix "0" if needed
    };
    return `#${f(0)}${f(8)}${f(4)}`;
  }

  for (let i of Array(numColors).keys()) {
    const colorHex = hslToHex(i * goldenAngle + 60, 100, 40);
    colors.push(colorHex);
  }

  return colors;
}
