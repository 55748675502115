import React from 'react';
import UpdatesTable from './UpdatesTable';
import { Accordion, Message, Icon, Label } from 'semantic-ui-react';

function TaskGroup({ activeTaskIndices, projectName, tasks, handleProjectTaskCollapse }) {
  /**
   * Handles the accordion title click. When the title is clicked, the section will either
   * expand or collapse (the opposite of what its current state is).
   *
   * Note that this calls a prop function to set the state on the parent component.
   * @param {*} e
   * @param {*} itemProps
   */
  const handleTitleClick = (e, itemProps) => {
    const newActiveIndices = !itemProps.active
      ? [...activeTaskIndices, itemProps.index]
      : activeTaskIndices.filter(index => index !== itemProps.index);
    handleProjectTaskCollapse(projectName, newActiveIndices);
  };

  /**
   * Takes the Teamwork yyyymmdd formatted date string and returns a more human
   * readable mm/dd/yyyy formatted date string.
   * @param {string} dateStr Date string in yyyymmdd format
   * @returns Date string as mm/dd/yyyy
   */
  function formatTeamworkDate(dateStr) {
    if (dateStr === '') return 'None';

    return `${dateStr.slice(4, 6)}/${dateStr.slice(6)}/${dateStr.slice(0, 4)}`;
  }

  /**
   * Returns the correct task label based on the task's priority as marked in Teamwork.
   *
   * low = Ready, no issues
   * medium = Ready, needs review
   * high = Ready, has issues
   *
   * @param {string} priority low, medium, high (from Teamwork)
   * @returns Label component
   */
  function getTaskLabel(priority) {
    switch (priority) {
      case 'low':
        return (
          <Label horizontal>
            <Icon name="circle" color="green" />
            Ready, no issues
          </Label>
        );
      case 'medium':
        return (
          <Label horizontal>
            <Icon name="circle" color="yellow" />
            Ready, needs review
          </Label>
        );
      case 'high':
        return (
          <Label horizontal>
            <Icon name="circle" color="red" />
            Ready, has issues
          </Label>
        );
      default:
        return (
          <Label horizontal>
            <Icon name="square" color="grey" />
            Not ready
          </Label>
        );
    }
  }

  return (
    <Accordion exclusive={false} styled fluid>
      {tasks.map((task, tIndex) => {
        return (
          <React.Fragment key={`task-group-${tIndex}`}>
            <Accordion.Title
              index={tIndex}
              active={activeTaskIndices.includes(tIndex)}
              onClick={handleTitleClick}
              style={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                justifyContent: 'space-between',
              }}
            >
              <div style={{ display: 'flex', flexDirection: 'column' }}>
                <div style={{ display: 'flex', flexDirection: 'row', marginBottom: '5px' }}>
                  <div>
                    <Icon
                      name={activeTaskIndices.includes(tIndex) ? 'triangle down' : 'triangle right'}
                    />
                    {task.name}
                  </div>
                </div>
                <div>
                  {!task.completed ? getTaskLabel(task.priority) : <span />}
                  {task.completed ? (
                    <Label color="green" horizontal>
                      <Icon name="checkmark" /> Sent
                    </Label>
                  ) : (
                    <span />
                  )}
                </div>
              </div>
              <div>
                <p style={{ margin: 0 }}>{task.amount}</p>
                <p style={{ margin: 0 }}>{formatTeamworkDate(task.dueDate)}</p>
                <p style={{ margin: 0 }}>
                  {task.updates.length} update{task.updates.length === 1 ? '' : 's'}
                </p>
              </div>
            </Accordion.Title>
            <Accordion.Content active={activeTaskIndices.includes(tIndex)}>
              {task.updates.length > 0 ? (
                <div>
                  <UpdatesTable taskUpdates={task.updates} />
                </div>
              ) : (
                <Message>
                  <Message.Header>No Changes</Message.Header>
                  <p>Invoice trigger has no due date changes in the activity history.</p>
                </Message>
              )}
            </Accordion.Content>
          </React.Fragment>
        );
      })}
    </Accordion>
  );
}

export default TaskGroup;
