import axios from 'axios';

/**
 * Refreshes the cached data for the programming tasks report and returns the newly
 * refreshed data. This data is not filtered at all.
 *
 * @param {string} token Instat Apps API token.
 * @returns Object containing status code, message, and data received (if any).
 */
export async function refreshProgrammingTasksReportData(token) {
  const request = {
    url: `api/reports/programming-tasks`,
    method: 'post',
    headers: {
      Authorization: `Bearer ${token}`,
    },
    data: {},
  };

  try {
    const response = await axios(request);

    return {
      status: 200,
      message: 'Programming task report data refreshed and new data received.',
      data: response.data,
    };
  } catch (error) {
    const statusCode = Number(error.message.slice(error.message.length - 3));
    return {
      status: statusCode,
      message: error.message,
      data: {},
    };
  }
}

/**
 * Fetches cached programming tasks report data. Data is not filtered.
 * @param {string} token Instat Apps API token.
 * @returns Object containing status code, message, and data received (if any).
 */
export async function fetchProgrammingTasksReportData(token) {
  const request = {
    url: `api/reports/programming-tasks`,
    method: 'get',
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  try {
    const response = await axios(request);

    return {
      status: 200,
      message: 'Cached programming task report data received.',
      data: response.data,
    };
  } catch (error) {
    const statusCode = Number(error.message.slice(error.message.length - 3));
    return {
      status: statusCode,
      message: error.message,
      data: {},
    };
  }
}
