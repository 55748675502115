import React from 'react';
import { Icon, Popup } from 'semantic-ui-react';

function formatCurrency(num) {
  const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: 2,
  });

  return formatter.format(num);
}

/**
 * These column definitions are used for react-table package.
 * Note that each column definition must have an accessor attribute.
 * These are also used to determine column ordering in CSV and Google Sheets exports.
 * @returns Array of column definitions for Cost Analysis Report
 */
function columnDefinitions() {
  return [
    {
      // Expanding category column
      id: 'category',
      Header: ({ getToggleAllRowsExpandedProps, isAllRowsExpanded }) => (
        <span {...getToggleAllRowsExpandedProps()}>
          {isAllRowsExpanded ? <Icon name="caret down" /> : <Icon name="caret right" />} Category
        </span>
      ),
      Cell: ({ row, value }) =>
        // Use the row.canExpand and row.getToggleRowExpandedProps props getters to build toggle for expanding row
        row.canExpand && row.values.category !== '' ? (
          <span
            {...row.getToggleRowExpandedProps({
              style: {
                paddingLeft: `${row.depth * 2}rem`,
              },
            })}>
            {row.isExpanded ? (
              <span>
                <Icon name="caret down" /> {value}
              </span>
            ) : (
              <span>
                <Icon name="caret right" /> {value}
              </span>
            )}
          </span>
        ) : (
          <span style={{ paddingLeft: '24px' }}>{value}</span>
        ),
      accessor: 'category',
    },
    {
      // Expanding category column
      id: 'resource',
      Header: ({ getToggleAllRowsExpandedProps, isAllRowsExpanded }) => <span>Resource</span>,
      Cell: ({ row, value }) =>
        // Use the row.canExpand and row.getToggleRowExpandedProps props getters to build toggle for expanding row
        row.canExpand && row.values.resource !== '' ? (
          <span
            {...row.getToggleRowExpandedProps({
              style: {
                paddingLeft: `${row.depth * 2}rem`,
              },
            })}>
            {row.isExpanded ? (
              <span>
                <Icon name="caret down" /> {value}
              </span>
            ) : (
              <span>
                <Icon name="caret right" /> {value}
              </span>
            )}
          </span>
        ) : (
          <span style={{ paddingLeft: `${row.depth * 2}rem` }}>{value}</span>
        ),
      accessor: 'resource',
    },
    {
      Header: 'Team Member',
      accessor: 'teamMember',
    },
    {
      Header: <span>10kft Rate</span>,
      Cell: ({ row, value }) =>
        value !== '' ? <div style={{ textAlign: 'right' }}>{formatCurrency(value)}</div> : '',
      accessor: '10kftRate',
    },
    {
      Header: <span>Breakeven Rate</span>,
      Cell: ({ row, value }) =>
        value !== '' ? <div style={{ textAlign: 'right' }}>{formatCurrency(value)}</div> : '',
      accessor: 'breakevenRate',
    },
    {
      Header: <span>Budget Hours</span>,
      Cell: ({ row, value }) =>
        value !== '' ? <div style={{ textAlign: 'right' }}>{value.toFixed(2)}</div> : '',
      accessor: 'budgetHours',
    },
    {
      Header: <span>Incurred Hours</span>,
      Cell: ({ row, value }) =>
        value !== '' ? <div style={{ textAlign: 'right' }}>{value.toFixed(2)}</div> : '',
      accessor: 'incurredHours',
    },
    {
      Header: <span>Remaining Hours</span>,
      Cell: ({ row, value }) =>
        value !== '' ? <div style={{ textAlign: 'right' }}>{value.toFixed(2)}</div> : '',
      accessor: 'hoursRemaining',
    },
    {
      Header: <span>Scheduled Hours</span>,
      Cell: ({ row, value }) =>
        value !== '' ? <div style={{ textAlign: 'right' }}>{value.toFixed(2)}</div> : '',
      accessor: 'scheduledHours',
    },
    {
      Header: <span>Budget</span>,
      Cell: ({ row, value }) =>
        value !== '' ? <div style={{ textAlign: 'right' }}>{formatCurrency(value)}</div> : '',
      accessor: 'budget',
    },
    {
      Header: (
        <span>
          Incurred Cost{' '}
          <Popup
            content="Incurred Hours × 10kft Rate"
            trigger={<Icon name="info circle" />}
            position="top right"
          />
        </span>
      ),
      Cell: ({ row, value }) =>
        value !== '' ? <div style={{ textAlign: 'right' }}>{formatCurrency(value)}</div> : '',
      accessor: 'incurredCost',
    },
    {
      Header: (
        <span>
          Budget Remaining{' '}
          <Popup
            content="Budget - Incurred Cost"
            trigger={<Icon name="info circle" />}
            position="top right"
          />
        </span>
      ),
      Cell: ({ row, value }) =>
        value !== '' ? <div style={{ textAlign: 'right' }}>{formatCurrency(value)}</div> : '',
      accessor: 'budgetRemaining',
    },
    {
      Header: (
        <span>
          Scheduled Cost{' '}
          <Popup
            content="Scheduled Hours × 10kft Rate"
            trigger={<Icon name="info circle" />}
            position="top right"
          />
        </span>
      ),
      Cell: ({ row, value }) =>
        value !== '' ? <div style={{ textAlign: 'right' }}>{formatCurrency(value)}</div> : '',
      accessor: 'scheduledCost',
    },
    {
      Header: (
        <span>
          Breakeven Cost{' '}
          <Popup
            content="Incurred Hours × Breakeven Rate"
            trigger={<Icon name="info circle" />}
            position="top right"
          />
        </span>
      ),
      Cell: ({ row, value }) =>
        value !== '' ? <div style={{ textAlign: 'right' }}>{formatCurrency(value)}</div> : '',
      accessor: 'breakevenCost',
    },
    {
      Header: (
        <span>
          Profit{' '}
          <Popup
            content="Budget - Breakeven Cost"
            trigger={<Icon name="info circle" />}
            position="top right"
          />
        </span>
      ),
      Cell: ({ row, value }) =>
        value !== '' ? <span style={{ textAlign: 'right' }}>{formatCurrency(value)}</span> : '',
      accessor: 'profit',
    },
    {
      Header: (
        <span>
          Scheduled Breakeven Cost{' '}
          <Popup
            content="Scheduled Hours × Breakeven Rate"
            trigger={<Icon name="info circle" />}
            position="top right"
          />
        </span>
      ),
      Cell: ({ row, value }) =>
        value !== '' ? <div style={{ textAlign: 'right' }}>{formatCurrency(value)}</div> : '',
      accessor: 'scheduledBreakevenCost',
    },
  ];
}

export default columnDefinitions;
