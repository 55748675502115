import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import { Label } from 'semantic-ui-react';
import './index.scss';

function Slider(props) {
  const { leftLabel, rightLabel } = props;
  const sliderRef = useRef(null);

  // Remove non-slider props
  const sliderProps = { ...props };
  if ('leftLabel' in sliderProps) delete sliderProps.leftLabel;
  if ('rightLabel' in sliderProps) delete sliderProps.rightLabel;
  if ('onChange' in sliderProps) delete sliderProps.onChange;

  return (
    <div className="slider-container">
      {leftLabel && (
        <span style={{ paddingRight: '5px' }}>
          <Label style={{ padding: '5px', cursor: 'default' }}>{leftLabel}</Label>
        </span>
      )}
      <input
        type="range"
        ref={sliderRef}
        className="slider"
        {...sliderProps}
        onChange={e => {
          props.onChange(e);

          // Adjust slider color to fill up to thumb
          const value = parseInt(e.target.value);
          const percentage = ((value - props.min) / (props.max - props.min)) * 100;
          sliderRef.current.style.background = `linear-gradient(to right, rgb(27, 28, 29), rgb(27, 28, 29) ${percentage}%, rgb(242, 242, 242) ${percentage}%, rgb(242, 242, 242) 100%)`;
        }}
      />
      {rightLabel && (
        <span style={{ paddingLeft: '5px' }}>
          <Label style={{ padding: '5px', cursor: 'default' }}>{rightLabel}</Label>
        </span>
      )}
    </div>
  );
}

Slider.propTypes = {
  min: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  max: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  value: PropTypes.number,
  onChange: PropTypes.func,
  disabled: PropTypes.bool,
};

Slider.defaultProps = {
  min: 0,
  max: 100,
  onChange: () => {},
};

export default Slider;
